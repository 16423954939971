import React, { Component } from 'react'
// import './style.css'
import Logo from 'assets/image/sne-logo.png'
import {connect} from 'react-redux'
import {modalToggle} from 'redux/actions/general'
// import Logo from 'assets/image/ACS.png'
import {Menu,Close} from '@material-ui/icons'
class navbar extends Component {
    
    render() {

        return (
            <nav>
               
               <div className='logo' >
                    
                    <div onClick={()=>this.props.expandToggle()} className={`hamburger ${!this.props.expand?"is-active":""}`} id="hamburger-1">
                        <span className="line"></span>
                        <span className="line"></span>
                        <span className="line"></span>
                    </div>
                    &nbsp;&nbsp;&nbsp;
                    <img src={Logo} style={{width:110}}/>
               </div>
               
              
           </nav>
        )
    }
}
export default connect(null,{modalToggle})(navbar)