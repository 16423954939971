import React,{useState,useEffect} from 'react'
import Welcome from './welcome'
import Demografi from './demografi'
import Survey from './survey'
import Result from './result'
import Tutorial from './tutorial'
import '../style.css'
import { useSelector,useDispatch } from 'react-redux'
import { setOas } from 'redux/actions/oas'
import Modal from 'components/Modal'

export default function Index() {
    const dispatch = useDispatch()
    const oas = useSelector(state => state.oas)
    const [active_tab, setactive_tab] = useState(0)
    const [status, setstatus] = useState('process')
    let {detail_survey,result_survey,jawaban}=oas
    useEffect(() => {
        let newp=[
            {
                id:0,
                itemType:'',
            },
            {
                id:0,
                itemType:'tutorial',
            },
        ]
        dispatch(setOas({detail_survey:{...detail_survey,pages:[...newp,...detail_survey.pages]}}))
        dispatch(setOas({jawaban:[...newp,...jawaban]}))
    }, [])
    const tabToggle=(num)=>{
        setactive_tab(num)
    }
    const statusToggle=(stat)=>{
        setstatus(stat)
    }
    
    let total= detail_survey?.pages.filter((d)=>{
        return d.itemType==='single'
    }).length
    // console.log(`total`,total, active_tab)
    return (
        <div>
            <Modal/>
            {status!=='result'?
                <>
                    {detail_survey.pages[active_tab].itemType===""&&
                        <Welcome 
                            active_tab={active_tab} 
                            tabToggle={tabToggle}
                            content={detail_survey.pages[active_tab]}
                        />
                    }
                    {detail_survey.pages[active_tab].itemType==="tutorial"&&
                        <Tutorial
                            active_tab={active_tab} 
                            tabToggle={tabToggle}
                        />
                    }
                    {detail_survey.pages[active_tab].itemType==="single"&&
                        <Survey 
                            active_tab={active_tab} 
                            tabToggle={tabToggle}
                            content={detail_survey.pages[active_tab]}
                            totalQuestion={total}
                            nameSurvey="Digital Transformation Readiness Assessment"
                            statusToggle={statusToggle}
                        />
                    }
                    {detail_survey.pages[active_tab].itemType==="varied"&&
                        <Demografi 
                            active_tab={active_tab} 
                            tabToggle={tabToggle}
                            content={detail_survey.pages[active_tab]}
                            totalQuestion={total}
                            nameSurvey="Digital Transformation Readiness Assessment"
                            statusToggle={statusToggle}
                        />
                    }
                    
                </>
            :
                <Result 
                    result_survey={result_survey}
                />
            }
        </div>
    )
}
