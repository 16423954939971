import * as actionType from "../constants/peserta";
import {apiCall} from 'service/apiCall'
import {setLoading,setError,modalToggle, setLoadingTable} from './general'
import Cookie from 'universal-cookie'
import {get} from 'lodash'
import {setUpdateBio} from './assestment'
import { URL, USER,PASS } from 'service/base_url'
import moment from 'moment'
const cookie=new Cookie
const token=cookie.get('login_cookie')
const profile=cookie.get('profile_cookie')
export const setPeserta=(payload)=>dispatch=>{
    dispatch({
        type:actionType.SET_PESERTA,
        payload:payload
    })
}

export const getListUser=(slug)=>async dispatch=>{
    dispatch(setLoadingTable(true))
        let dataReq={
            url:`/disc/admin/listuser/${profile.id}${slug}`,
            method:'GET',
            data:{headers:{ 'Authorization': `Bearer ${token}`},}
        }
        let res=await dispatch(apiCall(dataReq))
        if(get(res,'status')===200){
            
            dispatch(setLoadingTable(false))
            dispatch({
                type:actionType.GET_PESERTA,
                payload:{
                    peserta_list:res.data.items,
                    peserta_pagination:res.data.info
                }
            })
            return res
        }else{
            dispatch(setLoadingTable(false))
        }
}
export const getDetailUser=(slug)=>async dispatch=>{
    dispatch(setLoading(true))
        let dataReq={
            url:`/disc/admin/userinfo${slug}`,
            method:'GET',
            data:{headers:{ 'Authorization': `Bearer ${token}`},}
        }
        let res=await dispatch(apiCall(dataReq))
        if(get(res,'status')===200){
            
            dispatch(setLoading(false))
            let profiles={
                userName:res.data.name,
                email:res.data.email,
                profile_pic:res.data.profileURL,
                id:res.data.userId,
                roleId:profile.roleId,
                expired:res.data.expired,
                completed:res.data.completed
            }
            
            cookie.remove('profile_cookie')

            cookie.set('profile_cookie',profiles,{path:'/'})  

            dispatch(setPeserta({userId:res.data.userId}))
            dispatch(setPeserta({last_log:res.data.lastLog}))
            dispatch(setPeserta({nama:res.data.name}))
            dispatch(setPeserta({email:res.data.email}))
            dispatch(setPeserta({telepon:res.data.phone}))
            dispatch(setPeserta({no_ktp:res.data.idNumber}))
            dispatch(setPeserta({tgl_lahir:res.data.birthDate}))
            dispatch(setPeserta({kota_asal:res.data.city}))
            dispatch(setPeserta({jk:res.data.gender}))
            dispatch(setPeserta({institusi:res.data.education}))
            dispatch(setPeserta({jenjang:res.data.eduLevel}))
            dispatch(setPeserta({tgl_terdaftar:res.data.createdDate}))
            dispatch(setPeserta({file_url:res.data.profileURL}))
            dispatch(setPeserta({file_name:res.data.profileURL}))
            dispatch(setPeserta({start_date:res.data.startDate}))
            dispatch(setPeserta({end_date:res.data.endDate}))
            if(res.data.name!==''&&res.data.email!==''&&res.data.phone!==""&&res.data.idNumber!==""&&res.data.birthDate!==""&&res.data.city!==""&&res.data.gender!==""&&res.data.education!==""&&res.data.eduLevel!==""){
                dispatch(setUpdateBio(true))
            }else{
                dispatch(setUpdateBio(false))

            }
            if(res.data.expired){
                window.location.assign(`/expired/${res.data.projectName}`)
            }
            
            return res
        }else{
            dispatch(setLoading(false))
        }
}
export const getDetailUserForDashboard=(slug)=>async dispatch=>{
    dispatch(setLoading(true))
        let dataReq={
            url:`/disc/admin/userinfo${slug}`,
            method:'GET',
            data:{headers:{ 'Authorization': `Bearer ${token}`},}
        }
        let res=await dispatch(apiCall(dataReq))
        if(get(res,'status')===200){
            
            dispatch(setLoading(false))
            let profiles={
                userName:res.data.name,
                email:res.data.email,
                profile_pic:res.data.profileURL,
                id:res.data.userId,
                roleId:profile.roleId,
                expired:res.data.expired,
                completed:res.data.completed
            }
            cookie.remove('profile_cookie')

            cookie.set('profile_cookie',profiles,{path:'/'})  

            dispatch(setPeserta({userId:res.data.userId}))
            dispatch(setPeserta({last_log:res.data.lastLog}))
            dispatch(setPeserta({nama:res.data.name}))
            dispatch(setPeserta({email:res.data.email}))
            dispatch(setPeserta({telepon:res.data.phone}))
            dispatch(setPeserta({no_ktp:res.data.idNumber}))
            dispatch(setPeserta({tgl_lahir:res.data.birthDate}))
            dispatch(setPeserta({kota_asal:res.data.city}))
            dispatch(setPeserta({jk:res.data.gender}))
            dispatch(setPeserta({institusi:res.data.education}))
            dispatch(setPeserta({jenjang:res.data.eduLevel}))
            dispatch(setPeserta({tgl_terdaftar:res.data.createdDate}))
            dispatch(setPeserta({file_url:res.data.profileURL}))
            dispatch(setPeserta({file_name:res.data.profileURL}))
            dispatch(setPeserta({start_date:res.data.startDate}))
            dispatch(setPeserta({end_date:res.data.endDate}))
            // if(res.data.expired){
            //     window.location.assign('/expired')
            // }
            
            return res
        }else{
            dispatch(setLoading(false))
        }
}

export const getRiwayat=(slug)=>async dispatch=>{
    dispatch(setLoading(true))
        let dataReq={
            url:`/disc/admin/user${slug}`,
            method:'GET',
            data:{headers:{ 'Authorization': `Bearer ${token}`},}
        }
        let res=await dispatch(apiCall(dataReq))
        if(get(res,'status')===200){
            
            dispatch(setLoading(false))
            dispatch({
                type:actionType.GET_RIWAYAT,
                payload:{
                    riwayat_list:res.data.projects,
                    riwayat_pagination:{
                        page:res.data.info.page,
                        perPage:res.data.info.perPage,
                        total:res.data.info.total
                    }
                }
            })
            return res
        }else{
            dispatch(setLoading(false))
        }
}
export const getLog=(slug)=>async dispatch=>{
    dispatch(setLoading(true))
        let dataReq={
            url:`/disc/admin/userlog${slug}`,
            method:'GET',
            data:{headers:{ 'Authorization': `Bearer ${token}`},}
        }
        let res=await dispatch(apiCall(dataReq))
        if(get(res,'status')===200){
            
            dispatch(setLoading(false))
            dispatch({
                type:actionType.GET_DETAIL_LOG,
                payload:{
                    detail_log_list:res.data.logs,
                    detail_log_pagination:{
                        page:res.data.info.page,
                        perPage:res.data.info.perPage,
                        total:res.data.info.total
                    }
                }
            })
            return res
        }else{
            dispatch(setLoading(false))
        }
}

export const putPeserta=(data)=>async dispatch=>{
    dispatch(setLoading(true))
        let dataReq={
            url:'/disc/admin/userinfo',
            method:'PUT',
            data:{data:data,headers:{ 'Authorization': `Bearer ${token}`},}
        }
        let res=await dispatch(apiCall(dataReq))
        if(get(res,'status')===200){
            
            dispatch(setLoading(false))
            dispatch(modalToggle({ 
                modal_open: true,
                modal_title: "Peserta berhasil diedit",
                modal_component: "peserta",
                modal_size:400,
                modal_type:'alert',
                modal_data:{
                    msg:`<p>Peserta ${data.name} berhasil diedit</p> `,
                    ...res.data
                },
                modal_action:'success',
            }))
            return res
        }else{
            dispatch(setLoading(false))
        }
}
export const deletePeserta=(id)=>async dispatch=>{
    dispatch(setLoading(true))
        let dataReq={
            url:`/disc/admin/user/${id}`,
            method:'DELETE',
            data:{headers:{ 'Authorization': `Bearer ${token}`},}
        }
        let res=await dispatch(apiCall(dataReq))
        if(get(res,'status')===200){
            
            dispatch(setLoading(false))
            dispatch(modalToggle({ 
                modal_open: true,
                modal_title: "Peserta berhasil dihapus",
                modal_component: "delete_peserta",
                modal_size:400,
                modal_type:'alert',
                modal_data:{
                    msg:`<p>Peserta berhasil dihapus</p> `,
                    ...res.data
                },
                modal_action:'success',
            }))
            return res
        }else{
            dispatch(setLoading(false))
        }
}

export const uploadPeserta=(data,userId)=>async dispatch=>{
    dispatch(setLoading(true))
        let dataReq={
            url:'/disc/admin/profile',
            method:'POST',
            data:{data:data,headers:{ 'Authorization': `Bearer ${token}`,'Content-Type': 'multipart/form-data'},}
        }
        let res=await dispatch(apiCall(dataReq))
        if(get(res,'status')===200){
            dispatch(setLoading(false))
            dispatch(modalToggle({ 
                modal_open: true,
                modal_title: "Upload berhasil",
                modal_component: "peserta",
                modal_size:400,
                modal_type:'alert',
                modal_data:{
                    msg:`<p>foto profile berhasil di tambahkan</p> `,
                    ...res.data
                },
                modal_action:'success',
            }))
            return res
        }else{
            dispatch(setLoading(false))
        }
}
export const uploadPeserta2=(data,userId)=>async dispatch=>{
    dispatch(setLoading(true))
        let dataReq={
            url:'/disc/admin/profile',
            method:'POST',
            data:{data:data,headers:{ 'Authorization': `Bearer ${token}`,'Content-Type': 'multipart/form-data'},}
        }
        let res=await dispatch(apiCall(dataReq))
        if(get(res,'status')===200){
            dispatch(setLoading(false))
            
            return res
        }else{
            dispatch(setLoading(false))
        }
}