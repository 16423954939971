import axios from "axios";
// import { get } from "lodash";
import { URL, USER, PASS } from "./base_url";
import { logout, refreshToken } from "../redux/actions/auth";
import Cookie from "universal-cookie";
import { setError, modalToggle } from "redux/actions/general";
const cookie = new Cookie();
let contentType = {
  "Content-Type": "application/json",
  "X-Requested-With": "XMLHttpRequest",
};

export const apiCall =
  ({ method, url, data = "" }) =>
  async (dispatch) => {
    // let head = !isEmpty(data.headers) ? defaultHeader({ Authorization: `Bearer ${data.headers}` }) : contentType
    // let login_url=url==='/auth/login'?{username:USER,password:PASS}:''
    try {
      const response = await axios({
        method: method,
        url: URL + url,
        data: data.data || "",
        auth: data.auth,
        headers: data.headers,
        timeout: 1200000,
      });
      return response;
    } catch (error) {
      let response = error.response;
      // console.log('response', response)
      if (response !== undefined) {
        if (error.response.status === 401) {
          let refresh = cookie.get("refresh_cookie");
          let token = cookie.get("login_cookie");
          if (response.data !== "") {
            // alert('hello')
            // console.log('hello',response)
            dispatch(setError(response.data));
          } else {
            let oas = window.location.pathname.includes("/oas");
            dispatch(refreshToken(token, refresh, oas ? "?act=oas" : ""));
          }
          // if()
        } else if (error.response.status === 400) {
          if (url === "/disc/admin/upload") {
            dispatch(
              modalToggle({
                modal_open: true,
                modal_title: "",
                modal_component: "",
                modal_size: 400,
                modal_type: "alert",
                modal_data: {
                  msg: `<p>${error.response.data.error}</p>`,
                },
                modal_action: "error_upload",
              })
            );
          } else {
            // alert('asdf')
            dispatch(
              modalToggle({
                modal_open: true,
                modal_title: "",
                modal_component: "",
                modal_size: 400,
                modal_type: "alert",
                modal_data: {
                  msg: "<p>Opps, An error occurred,input field correctly or please contact Support team 🙏</p>",
                },
                modal_action: "error",
              })
            );
          }

          return error;
          // alert('else')
          // dispatch(logout())
        } else {
          // dispatch(logout())
          dispatch(
            modalToggle({
              modal_open: true,
              modal_title: "",
              modal_component: "",
              modal_size: 400,
              modal_type: "alert",
              modal_data: {
                msg: "<p>Opps, An error occurred,check your connection or please contact Support team 🙏</p>",
              },
              modal_action: "error",
            })
          );
        }
      } else {
        // alert('asdf')
        dispatch(
          modalToggle({
            modal_open: true,
            modal_title: "",
            modal_component: "",
            modal_size: 400,
            modal_type: "alert",
            modal_data: {
              msg: "<p>Opps, An error occurred,check your connection or please contact Support team 🙏</p>",
            },
            modal_action: "error",
          })
        );
        // dispatch(setError([{description:'Opps, An error occurred,check your connection or please contact Support team 🙏'}]))
      }
      console.log("asdf", error.response.status);
      return error;
    }
  };
