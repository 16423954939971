import React from 'react'
import WelcomeContainer from 'components/oascomponent/WelcomeContainer'

export default function Welcome(props) {
    return (
        <WelcomeContainer ln="id" btnText="Ikuti Survey" clickAgree={()=>props.tabToggle(props.active_tab+1)} welcomeMessage="Anda sedang menjalani proses Digital Transformation Readiness Assessment ">
                <h3>Digital Transformation Readiness Assessment </h3>
                
                <p >Dalam era industri 4.0, digitalisasi atau transformasi digital merupakan keniscayaan terlepas bentuk organisasi (perusahaan swasta, BUMN, nirlaba ataupun organisasi pemerintahan) ataupun industri. Asesmen singkat berikut memberi gambaran tentang kesiapan organisasi Anda melakukan transformasi digital, baik dari segi strategi maupun elemen organisasi pendukung. Pelanggan merupakan fokus dari pada keseluruhan perilaku transformasi digital. Jika Anda mewakili organisasi pemerintahan atau nirlaba, pikirkan stakeholder utama eksternal yang menjadi bagian dari misi organisasi sebagai pelanggan Anda.</p>
                <br/>
        </WelcomeContainer>
    )
}
