import React,{useEffect,useState} from 'react'
import '../style.css'
import Logo1 from 'assets/image/logo.png'
import Map from 'assets/image/map.svg'

import report_1 from 'assets/image/report_1.jpg'
import report_2 from 'assets/image/report_2.jpg'
import report_3 from 'assets/image/report_3.jpg'
import report_4 from 'assets/image/report_4.jpg'
import moment from 'moment'
import {useDispatch, useSelector} from 'react-redux'
import { withRouter } from 'react-router-dom'
import {getSurveyReport} from 'redux/actions/oas'
import Loading from 'components/Loading'
import { Bar,Scatter } from "react-chartjs-2";
import { Chart, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

Chart.register(...registerables);

function Report(props) {
    const dispatch = useDispatch()
    const oas = useSelector(state => state.oas)
    const [chart1Color,setChart1Color]=useState(['#ea5757','#5f8aeb','#26B600','#ebb262','#00ede4'])
    const [chartQuadrant, setchartQuadrant] = useState({
        responsive:false,
        borderColor:'red',
        datasets: [{
            label: 'Scatter Dataset',
            showLine: false,
            pointRadius: [],
            pointBorderColor:chart1Color,
            pointBackgroundColor:chart1Color,
            data: [],
        }]
      })
    const [bar1, setbar1] = useState({
        labels: [['Digital Strategy','Differentiated']],
        datasets: [
            {
                label: '',
                backgroundColor: [],
                borderWidth: 0,
                data: [],
                barPercentage: 0.8,
              }
        ]
      })
    const [bar2, setbar2] = useState({
        labels: [['Execution-biased','Systems'], ['Empowered','Structure'],['Entrepreneurial','People'],['Adhocracy','Culture'],['Ambidextrous','Leadership']],
        datasets: [
          {
            label: '',
            backgroundColor: [],
            borderWidth: 0,
            data: [],
            barPercentage: 1.1,
          }
        ]
      })
    let new_datas2=[]
    const [bar3, setbar3] = useState({
        labels: [['Formulasi','Strategi'], ['Pemetaan',' Strategi','Level','Organisasi'], ['Penyelarasan ','Organisasi'], ['Eksekusi ','Operasional'],['Pemantauan dan','Penyelarasan','Kembali']],
        datasets: []
    })
    let {report_survey}=oas
    useEffect(() => {
        if(report_survey!==null){
            settingReport()
        }else{
            dispatch(getSurveyReport(`/7/${props.match.params.uuid}`))
        }
    }, [report_survey])
    let renderRadius=(value)=>{
        if(value===0){
            return 10
        }
        if(value==='NaN'){
            return 0
        }
        if(value>70){
            return value/2
        }else{
            return value
        }
        
    }
    
    const settingReport=async ()=>{
        setchartQuadrant({
            responsive:false,
            borderColor:'red',
            datasets: [{
                label: 'Scatter Dataset',
                showLine: false,
                pointRadius: [renderRadius(report_survey.chart1.quadrant4),renderRadius(report_survey.chart1.quadrant1),renderRadius(report_survey.chart1.quadrant2),renderRadius(report_survey.chart1.quadrant3)],
                pointBorderColor:chart1Color,
                pointBackgroundColor:chart1Color,
                data:[
                    {x:-50,y:50,value:report_survey.chart1.quadrant4},
                    {x:50,y:50,value:report_survey.chart1.quadrant1},
                    {x:50,y:-50,value:report_survey.chart1.quadrant2},
                    {x:-50,y:-50,value:report_survey.chart1.quadrant3},
                ]
            }]
          })
          report_survey.chart3[0].values.map((data)=>{
            if(data!==0){
                bar1.datasets[0].data.push(data)
                bar1.datasets[0].backgroundColor.push(data>=2.5?'#26B600':'#FF4560')
            }
            
        })
        setbar1(bar1)
        report_survey.chart4.map((data)=>{
            data.values.map((d)=>{
                if(d!==0){
                    bar2.datasets[0].data.push(d)
                    bar2.datasets[0].backgroundColor.push(d>=2.5?'#26B600':'#FF4560')

                }
            })
        })
        setbar2(bar2)

        await report_survey.chart2.legends.map((dat,i)=>{
            if(report_survey.chart2.legends.length-1==i){
                //for last
                let new_dat=[]
                report_survey.chart2.items.map((item,i2)=>{
                    new_dat.push(item.values[i])
                })
                new_datas2.push({
                    label: dat,
                    data: new_dat,
                    fill:false,
                    type: 'line',
                    lineTension:0,
                    "borderColor":"#ed4900",
                    borderWidth:2,
                    pointBorderWidth:5,
                    pointBorderColor:'#ed4900',
                    pointBackgroundColor:'#ed4900',
                })
            }else if(report_survey.chart2.legends.length-2==i){
                //for before last
                let new_dat=[]
                report_survey.chart2.items.map((item,i2)=>{
                    new_dat.push(item.values[i])
                })
                new_datas2.push({
                    label: dat,
                    data: new_dat,
                    fill:false,
                    type: 'line',
                    lineTension:0,
                    "borderColor":"#c919ed",
                    borderWidth:2,
                    pointBorderWidth:5,
                    pointBorderColor:'#c919ed',
                    pointBackgroundColor:'#c919ed',
                    // pointStyle: 'line'
                })
            }else{
                let new_dat=[]
                report_survey.chart2.items.map((item,i2)=>{
                    new_dat.push(item.values[i])
                })
                let renderColor=()=>{
                    if(dat==='Nilai rata-rata'){
                        return '#0089ed'
                    }else{
                        // let findColor=industry_list.filter((idn)=>{
                        //     return idn.text.id===dat
                        // })
                        return 'rgb(0, 137, 237)'
                    }
                    
                }
                new_datas2.push({
                    label: dat,
                    data: new_dat,
                    backgroundColor:renderColor(),
                    pointStyle: 'rect'
                })
            }

        })
        let filter_line=new_datas2.filter((dat)=>{
            return dat.type==='line'
        })
        let filter_without_line=new_datas2.filter((dat)=>{
            return dat.type!=='line'
        })
        let new_dataset=[]
        filter_line.map((d)=>{
            new_dataset.push(d)
        })
        filter_without_line.map(d=>{
            new_dataset.push(d)
        })
        setbar3({
            labels: [['Formulasi','Strategi'], ['Pemetaan',' Strategi','Level','Organisasi'], ['Penyelarasan ','Organisasi'], ['Eksekusi ','Operasional'],['Pemantauan dan','Penyelarasan','Kembali']],
            datasets: new_dataset
        })
    }
    let oo=[]
    const setonLoaded=(index)=>{
      if(oo.length<4){
        oo.push(index)
      }else{
          oo=[]
          setTimeout(()=>{
              
              window.print()
              
          },3000)
      }
    }
    
    return (
        <div>
            <Loading/>
            {report_survey&&
                <div className='report-wrapper' >
                <div className='report-header'>
                    <div className='report-shape'></div>
                    <div className='div-margin'>
                        <img src={Logo1} onLoad={()=>setonLoaded(0)} style={{width:100}}/>
                    </div>
                </div>
                <div className='report-footer'>
                    <div className='div-margin'>
                        <p>Report date :  {moment().format('DD MMM YYYY')}</p>
                    </div>
                </div>
                <table style={{width:'100%'}}>
                <thead><tr><td>
                    <div className="header-space">&nbsp;</div>
                </td></tr>
                </thead>
                <tbody><tr><td>
                    <div className="content">

                        <div className='div-flex div-margin'>
                            {/* {renderImage().map((data,index)=>( */}
                                <div style={{width:140,height:140}}>
                                <img src={report_1} onLoad={()=>setonLoaded(1)}  style={{width:'100%'}}/>
                                &nbsp;&nbsp;
                                </div>
                                <div style={{width:140,height:140}}>
                                <img src={report_2} onLoad={()=>setonLoaded(2)}  style={{width:'100%'}}/>
                                &nbsp;&nbsp;
                                </div>
                                <div style={{width:140,height:140}}>
                                <img src={report_3} onLoad={()=>setonLoaded(3)}  style={{width:'100%'}}/>
                                &nbsp;&nbsp;
                                </div>
                                <div style={{width:140,height:140}}>
                                <img src={report_4} onLoad={()=>setonLoaded(4)}  style={{width:'100%'}}/>
                                &nbsp;&nbsp;
                                </div>
                            {/* ))} */}
                        </div>
                        <br/>
                        <div className='report-title div-flex div-align-center div-margin'>
                            <h1 className='report-h1-title'>{report_survey.title}</h1>
                            <h1 className='vertical-title'>Confidential</h1>
                        </div>
                        <div className='report-information div-margin'>
                            <p className='text-bold'>Respondent Information</p>
                            {report_survey.cover.map((data,i)=>(
                                <div className='div-flex'>
                                    <p style={{width:250}}>{data.indicator}</p>
                                    <p>:&nbsp;{data.text}</p>
                                </div>
                           ))}  
                        </div>
                        <div className='report-map'>
                            <img src={Map}/>
                        </div>
                        
                        <div className='pagebreak'></div>
                        
                        <br/>
                        <div className='report-shape3'>
                            <h1>Pemetaan Digital Transformation Readiness</h1>
                        </div>
                        <br/>
                        <div className='pemetaan div-margin strategic-text'>
                            <p>Dalam era industri 4.0, digitalisasi atau transformasi digital merupakan keniscayaan terlepas bentuk organisasi (perusahaan swasta, BUMN, nirlaba ataupun organisasi pemerintahan) ataupun industri. Asesmen yang telah Anda lakukan memberi gambaran tentang kesiapan organisasi Anda baik dari kesiapan agilitas strategi untuk melakukan diferensiasi memberi nilai kepada pelanggan <i>(differentiating digital capability)</i> maupun agilitas organisasi seperti sistem/proses, struktur, manusia dan budaya <i>(organization integrating capability)</i> untuk mendukung keberhasilan transformasi digital. 
                                <br/><br/>
                                Pelanggan merupakan fokus dari pada keseluruhan perilaku transformasi digital. Survei ini relevan bagi semua jenis organisasi. Partisipan yang mewakili organisasi pemerintahan atau nirlaba, diminta memikirkan stakeholder utama eksternal yang menjadi bagian dari misi organisasi sebagai pelanggan Anda.
                                <br/><br/>
                                Hasil dari asesmen ini mengkategorikan organisasi Anda ke dalam salah satu dari empat kuadran yang ada: <i>Fully Ready, Not Ready, Weak Organization Agility</i>, atau <i>Poor Strategy Differentiation</i>. Ke empat kuadran ini dihasilkan dari pemetaan 2 dimensi: <i>differentiating digital capability</i> dan <i>organization integrating capability</i>. Dengan <i>differentiating digital capability</i>, organisasi melakukan pemantauan atas perubahan tren eksternal dan cepat memformulasi serta lincah mengeksekusi strategi digital yang tepat untuk memberi nilai tambah yang baru dan unik kepada pelanggan. Sedang organization integrating capability menjaga keselarasan dimensi organisasi tanwujud seperti sistem/proses, struktur, SDM dan budaya kerja telah dirancang untuk <i>agile</i> mendukung transformasi digital. </p>
                            <br/>
                            <div className='pemetaan' style={{width: '65%',boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',backgroundColor: 'white',padding: 20}}>
                                <p>DIFFERENTIATING DIGITAL CAPABILITY</p>
                                <p style={{fontSize: 14,fontWeight: 'normal'}}><b>Definisi:</b> Kemampuan perusahaan untuk memberikan nilai tambah <i>(value proposition)</i> berbasis digital yang unik dalam akuisisi dan memenangkan loyalitas pelanggan</p>
                                <div style={{width: '100%',height: 1,backgroundColor: '#cccccc'}}></div>
                                <p style={{fontSize: 14,fontWeight: 'normal'}}><b>Contoh:</b> Kemampuan inovasi, pelayanan, produksi dan distribusi, pengembangan ekosistem dengan menggunakan teknologi dan digitalisasi</p>

                            </div>
                            
                            <div className='pemetaan' style={{marginTop:5,width: '65%',boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',backgroundColor: 'white',padding: 20}}>
                                <p>ORGANIZATION INTEGRATING CAPABILITY</p>
                                <p style={{fontSize: 14,fontWeight: 'normal',}}><b>Definisi:</b> Kemampuan tanwujud <i>(intangible)</i> perusahaan untuk mendorong terciptanya kemampuan yang <i>agile</i> dan selaras dengan <i>differentiating capability</i></p>
                                <div style={{width: '100%',height: 1,backgroundColor: '#cccccc',}}></div>
                                <p style={{fontSize: 14,fontWeight: 'normal',}}><b>Contoh:</b> Sistem/Proses, struktur, sumber daya manusia, serta budaya kerja dan kepemimpinan.</p>

                            </div>

                        </div>
                        <div className='pagebreak'></div>
                        <br/>
                        <div className='report-shape3'>
                            <h1>Memahami Kuadran Kesiapan Transformasi Organisasi Anda</h1>
                        </div>
                        <br/>
                        <div className='pemetaan div-margin strategic-text'>
                            <p><b>Penempatan organisasi Anda dalam salah satu dari ke empat kuadran dapat dimaknai sebagai berikut:</b></p>
                            <div style={{width: '100%',textAlign: 'center',}}>
                            <p style={{fontSize: 14,fontWeight: 'normal',fontWeight:'bold'}}>Gambar 5. Pemetaan Digital Transformation Readiness {report_survey.groupName} </p>
                            {/* <p style={{fontSize: 14,fontWeight: 'normal'}}>N = {res.data.total}</p> */}

                            </div>
                            <br/><br/>
                        <div className="div-flex div-justify-center" style={{width: '100%'}}>
                            <div style={{width:500,height: 350,position:'relative'}}>
                                <div style={{textAlign:'center'}}>
                                    <p style={{position:'absolute',top:0,left:20}}><b>WEAK<br/>ORGANIZATION AGILITY</b></p>
                                </div>
                                <div style={{textAlign:'center'}}>
                                    <p style={{position:'absolute',top:0,right:60}}><b>FULLY READY</b></p>
                                </div>
                                <div style={{textAlign:'center'}}>
                                    <p style={{position:'absolute',bottom:0,left:70}}><b>NOT-READY</b></p>
                                </div>
                                <div style={{textAlign:'center'}}>
                                    <p style={{position:'absolute',bottom:0,right:30}}><b>POOR STRATEGY<br/> DIFFERENTIATION</b></p>
                                </div>
                                <p style={{position:'absolute',top:-40,left:150}}>Digital Strategy Differentiated</p>
                                <p style={{position:'absolute',bottom:-40,left:100}}>Not Digital Strategy Differentiated</p>
                                {/* <p className='vertical-text' style={{position:'absolute',top:160,left:-70}}>Low Organization Agility</p>
                                <p className='vertical-text' style={{position:'absolute',top:160,right:-70}}>High Organization Agility</p> */}
                                <div style={{textAlign:'center'}}>
                                    <p  style={{position:'absolute',top:130,left:-20}}>Low<br/> Organization<br/> Agility</p>
                                </div>
                                <div style={{textAlign:'center'}}>
                                    <p  style={{position:'absolute',top:130,right:-20}}>High<br/> Organization<br/> Agility</p>
                                </div>
                                <p className='vertical-text' style={{position:'absolute',top:160,left:100}}>Differentiating Capability</p>
                                <p  style={{position:'absolute',top:160,left:200}}>Integrating Capability</p>
                                <Scatter
                                // height={230}
                                data={chartQuadrant}
                                plugins={[ChartDataLabels]}
                                options={{
                                        
                                    animation: {
                                        duration: 0
                                    },
                                    
                                    maintainAspectRatio: false,
                                    scales: {
                                        x: {
                                                display: true,
                                                ticks: {
                                                    beginAtZero: true,
                                                    steps: 10,
                                                    stepValue: 50,
                                                    
                                                    display:false,
                                                },
                                                max: 100,
                                                    min:-100,
                                                grid: {
                                                    drawBorder: false,
                                                    zeroLineColor: '#00ede4',
                                                    zeroLineWidth:2,
                                                    drawBorder: false,
                                                    lineWidth:ctx=>ctx.tick.value==0?2:1,
                                                    color: context => context.tick.value == 0 ? "#00ede4" :'#E0E0E0'
                                                }
                                            },
                                        y: {
                                                display: true,
                                                ticks: {
                                                    beginAtZero: true,
                                                    steps: 10,
                                                    stepValue: 50,
                                                    
                                                    display:false,
                
                                                },
                                                max: 100,
                                                    min:-100,
                                                grid: {
                                                    drawBorder: false,
                                                    zeroLineColor: '#00ede4',
                                                    zeroLineWidth:2,
                                                    lineWidth:ctx=>ctx.tick.value==0?2:1,
                                                    color: context => context.tick.value == 0 ? "#00ede4" :'#E0E0E0	'
                                                }
                                            }
                                        },
                                        plugins: {
                                            tooltips: {enabled: false},
                                            hover: {mode: null},
                                            legend: {
                                                display: false
                                            },
                                            datalabels: {
                                            anchor: function(context) {
                                                var value = context.dataset.data[context.dataIndex];
                                                return value.v < 50 ? 'end' : 'center';
                                            },
                                            align: function(context) {
                                                var value = context.dataset.data[context.dataIndex];
                                                return value.v < 50 ? 'end' : 'center';
                                            },
                                            color:'black',
                                            font: {
                                                weight: 'bold',
                                            },
                                            formatter: function(value) {
                                                // console.log('value', value)
                                                return `${Math.round(value.value)}%`;
                                            },
                                            offset: 2,
                                            padding: 0
                                            }
                                        },
                                }}
                                />
                            </div>
                            
                        </div>
                        </div>
                        <br/><br/>
                        <div className='pagebreak'></div>
                        <br/>
                        <div className='div-margin'>
                            <p style={{fontSize: 14,fontWeight: 'normal',margin:0}}><b style={{display:'flex',alignItems:'center',fontSize:17}}><span><div style={{width:17,height:17,backgroundColor:chart1Color[1],borderRadius:'100%'}}></div></span>&nbsp;Quadrant 1 FULLY READY :</b>  Organisasi dalam kuadran ini siap (atau telah) melakukan transformasi digital. Organisasi ini memiliki kemampuan melakukan diferensiasi strategi berbasis digital yang merubah pengalaman pelanggan secara unik. Dimensi <i>agile</i> organisasi lainnya seperti sistem/proses kerja ala <i>rapid-decision making</i>, struktur dan SDM yang <i>agile</i>, serta budaya yang menghargai inovasi juga telah dibangun dan selaras dengan kebutuhan transformasi digital. </p>
                            <br/>
                            <p style={{fontSize: 14,fontWeight: 'normal',margin:0}}><b style={{display:'flex',alignItems:'center',fontSize:17}}><span><div style={{width:17,height:17,backgroundColor:chart1Color[2],borderRadius:'100%'}}></div></span>&nbsp;Quadrant 2 Poor Strategy Differentiation :</b>  Organisasi dalam kuadran ini telah berinvestasi dalam mengembangkan <i>kapabilitas integrating</i> untuk membangun organisasi yang <i>agile</i>. Namun mereka belum menjalankan sepenuhnya strategi diferensiasi berbasis transformasi digital untuk merubah atau menambah nilai kepada pelanggan. Dengan adanya organisasi yang siap seperti sistem/proses kerja ala <i>rapid-decision making</i>, struktur & SDM yang <i>agile</i> , serta budaya yang menghargai inovasi, organisasi ini perlu segera memformulasi dan menjalankan strategi transformasi digital sehingga dapat berdampak kepada pelanggan. </p>
                            <br/>
                            <p style={{fontSize: 14,fontWeight: 'normal',margin:0}}><b style={{display:'flex',alignItems:'center',fontSize:17}}><span><div style={{width:17,height:17,backgroundColor:chart1Color[3],borderRadius:'100%'}}></div></span>&nbsp;Quadrant 3 Not Ready :</b>  Organisasi dalam kuadran ini belum siap dan butuh waktu persiapan lebih matang untuk melakukan transformasi digital. Organisasi ini belum mampu melakukan diferensiasi strategi berbasis digital. Strategi yang diadopsi dan pengalaman pelanggan yang diciptakan masih belum berubah dari gaya lama. Di sisi lain, dimensi organisasi lainnya, seperti sistem/proses kerja ala <i>rapid-decision making</i>, struktur dan SDM yang <i>agile</i>, serta budaya yang menghargai inovasi, belum dikembangkan sepenuhnya sehingga belum sesuai tuntutan transformasi digital. </p>
                            <br/>
                            <p style={{fontSize: 14,fontWeight: 'normal',margin:0}}><b style={{display:'flex',alignItems:'center',fontSize:17}}><span><div style={{width:17,height:17,backgroundColor:chart1Color[0],borderRadius:'100%'}}></div></span>&nbsp;Quadrant 4 Weak Organization Agility :</b>  Organisasi ini telah mulai melakukan diferensiasi strategi berbasis digital untuk merubah atau menambah nilai kepada pelanggan. Namun dimensi organisasi lainnya seperti sistem/proses kerja ala <i>rapid-decision making</i>, struktur dan SDM yang <i>agile</i>, serta budaya yang menghargai inovasi belum sepenuhnya dikembangkan sehingga belum sesuai tuntutan transformasi digital. Kurangnya penyelarasan antara kapabilitas diferensiasi dan integrasi ini dapat menghambat keberhasilan transformasi. Organisasi dalam kuadran ini perlu segera melakukan penyelarasan untuk mendukung keberhasilan transformasi digital yang sedang dilakukan. </p>
                        </div>
                        <br/>
                        <div style={{width: '100%',textAlign: 'center',}}>
                            <p style={{fontSize: 14,fontWeight: 'normal',fontWeight:'bold',margin:0}}>Gambar 6. Digital Differentiating Capability</p>
                            {/* <p style={{fontSize: 14,fontWeight: 'normal'}}>N = {report.digital.total}</p> */}

                        </div>
                        <br/>
                        <div style={{display:'flex',justifyContent:'center'}}>
                            <div style={{width:'70%',height:100}}>
                            <Bar
                                // height={00}
                                data={bar1}
                                plugins={[ChartDataLabels]}
                                options={{
                                    indexAxis: 'y',
                                    plugins: {
                                        datalabels: {
                                        formatter: function(value) {
                                            if(value!==0){
                                                return value.toFixed(2);

                                            }else{
                                                return ''
                                            }
                                        },
                                        align:'end',
                                        offset: 20,
                                        padding: 0
                                        },
                                        tooltip: {enabled: false},
                                        hover: {mode: null},
                                        legend: {
                                            display: false,
                                        },
                                    },
                                    responsive: true,
                                        maintainAspectRatio: false,
                                        legend: { display:false },
                                    tooltips: {
                                        enabled: false
                                    },
                                    responsive: true,
                                    legend: {
                                        display: false,
                                        
                                    },
                                    scales: {
                                        y: {
                                            stacked: true,
                                            barPercentage: 0.75,
                                            grid: {
                                                display: false,
                                                drawTicks: false,
                                                drawOnChartArea: false
                                            },
                                            ticks: {
                                                padding: 10,
                                                fontColor: '#555759',
                                                fontFamily: 'Lato',
                                                fontSize: 11
                                            }
                                            
                                        },
                                        x: {
                                            stacked: true,
                                            grid: {
                                                display: false,
                                                drawTicks: false,
                                                color: '#464646'
                                            },
                                            suggestedMin: 1,
                                            suggestedMax: 4,
                                            // maxRotation: 0,
                                        ticks: {
                                            padding: 5,
                                            // beginAtZero: true,
                                            fontColor: '#555759',
                                            fontFamily: 'Lato',
                                            fontSize: 11,
                                            
                                            callback: function(label, index, labels) {
                                                return null
                                            // if(label==10){
                                            //     return 'Digital Stategy Differentiated'
                                            // }else if(label==-10){
                                            //     return 'Brick and Mortar Strategy'
                                            // }else if(label==0){
                                            //     return 0
                                            // }else{
                                            //     return null
                                            // }
                                
                                            }
                                            
                                        },
                                        
                                        }
                                    }
                                }}
                                />
                            </div>
                        </div>
                        <br/>
                        <div className='pemetaan div-margin strategic-text'>
                            <p>Selain memliki Differentiating Digital Strategy yang jelas, organisasi yang siap bertransformasi membangun dan menyelaraskan kemampuan organisasi berbasis agilitas atau <i>Organization Integrating Capability</i>. Dimensi <i>agile</i> dalam organisasi terdiri sistem/proses kerja ala <i>rapid-decision making</i>, struktur organisasi yang flat dan fleksibel, pengelolaan talenta SDM yang <i>agile</i>, serta budaya yang menghargai inovasi dan mendorong pengambilan risiko (serta belajar dari kesalahan).   </p>
                        </div>
                        <br/>
                        <div className='pagebreak'></div>
                        <br/>
                        <div className='div-margin' style={{width: '100%',textAlign: 'center'}}>
                            <p style={{fontSize: 14,fontWeight: 'normal',fontWeight:'bold'}}>Gambar 7. Organization Integrating Capability</p>
                            {/* <p style={{fontSize: 14,fontWeight: 'normal'}}>N = {report.digital.total}</p> */}
                            <div className='div-margin'>
                            <div style={{width:'70%'}}>
                            <Bar
                                height={400}
                                data={bar2}
                                plugins={[ChartDataLabels]}
                                options={{
                                    indexAxis: 'y',
                                    maintainAspectRatio: false,
                                    animation: {
                                        duration: 0
                                    },
                                    scales: {
                                        x: {
                                            stacked:true,
                                            min:1,
                                            max:4,
                                            display:true,
                                            grid: {
                                                display:false
                                            }  ,
                                            ticks: {
                                                padding: 5,
                                                beginAtZero: true,
                                                fontColor: '#555759',
                                                fontFamily: 'Lato',
                                                fontSize: 11,
                                                
                                                callback: function(label, index, labels) {
                                                return null
                                                // if(label==-6){
                                                //     return 'Low Organization Agility'
                                                // }else if(label==6){
                                                //     return 'High Organization Agility'
                                                
                                                // }else{
                                                //     return null
                                                // }
                                    
                                                }
                                                
                                            },
                                        },
                                        y: {
                                            display:true,
                                            grid: {
                                                display:false
                                            }  
                                        },
                                    },
                                    plugins: {
                                        datalabels: {
                                            anchor: 'end',
                                            align: 'right',
                                            offset:10,
                                        formatter: function(value) {
                                            return value.toFixed(2)
                                        },
                                        },
                                        tooltip: {enabled: false},
                                        hover: {mode: null},
                                        legend: {
                                            display: false,
                                        },
                                        
                                    },
                                }}
                                />
                            </div>
                            </div>
                        </div>
                        <br/>
                        <div className='pemetaan div-margin strategic-text'>
                            <p><b>Execution-biased Systems:</b> Organisasi yang bertransformasi digital tidak lagi melihat teknologi hanya sebagai <i>enabler</i>, namun investasi dalam teknologi dan digitalisasi terbaru terus dilakukan sehingga teknologi menjadi teintegrasi dalam proses bisnis. Data dilihat sebagai aset strategis yang dapat diakses oleh semua bagian. Proses inovasi dan pengambilan keputusan dilakukan dengan cepat dan lincah berbasis iterasi berulang.</p>
                            <p><b>Empowered Structure:</b> Struktur organisasi yang <i>agile</i>, lintas fungsional dan independen, minim intervensi manajemen puncak diterapkan dalam berinovasi. Pemindahan talenta antar fungsi atau proyek dapat dilakukan dengan lincah tanpa birokrasi berbelit.</p>
                            <p><b>Entrepreneurial People:</b> Sumber daya manusia terus dikembangkan dengan budaya pembelajaran. Inovasi dengan akuntabilitas diterapkan dan diberikan penghargaan. Manajemen kinerja yang adaptif terhadap perubahan bisnis diimplementasikan.</p>
                            <p><b>Adhocracy Culture:</b> Budaya kolaboratif yang berfokus kepada penciptaan nilai baru dan kepuasan pelanggan merupakan roh perusahaan. Inovasi dan <i>risk-taking</i> disertai disiplin dalam melakukan reviu dan mendapatkan pembelajaran dari proses keberhasilan serta kegagalan merupakan bagian dari budaya kerja.</p>
                            <p><b>Ambidextrous Leadership:</b> Kepemimpinan yang menyeimbangkan antara eksploitasi dan eksplorasi, sehingga mampu menjaga fokus kinerja keseharian sambal sekaligus mendorong pengembangan inovasi untuk masa depan.</p>
                            <p>Lihat kembali nilai Anda dan identifikasi dimensi dalam <i>Organization Integrating Capability</i> yang memiliki nilai relatif rendah. Lakukan prioritas untuk melakukan penyelarasan organisasi agar transformasi digital Anda dapat lebih berhasil.</p>
                        </div>
                        <br/>
                        <div className='pagebreak'></div>
                        <br/>
                        <div className='report-shape3'>
                            <h1>Pemetaan Kemampuan Agilitas Strategy-into-Performance Execution Excellence (SPEx2) vs {report_survey.groupName} vs Benchmark</h1>
                        </div>
                        <br/>
                        <div className='pemetaan div-margin strategic-text'>
                            <p>Berbagai riset di Indonesia dan dunia menunjukkan bahwa strategi yang baik, termasuk transformasi digital, sering gagal 
                                diimplementasi. Kemampuan formulasi dan eksekusi strategi (SPEx2) merupakan kemampuan yang akan membedakan 
                                organisasi yang hanya “cerdas” dengan yang <i>agile</i> atau “lincah.” Organisasi yang <i>agile</i> adalah organisasi yang aktif memonitor 
                                perkembangan tren eksternal, cepat memformulasi strategi untuk mengantisipasi dan meresponi perubahan, dan selalu aktif 
                                melakukan reviu atas hasil implementasi strategi.</p>
                            <div style={{width: '100%',textAlign: 'center'}}>
                                <p style={{fontSize: 14,fontWeight: 'normal',fontWeight:'bold'}}>Gambar 8. Pemetaan Kemampuan Agilitas Strategy-Into-Performance Execution Exellence (SPEx2) </p>
                                {/* <p style={{fontSize: 14,fontWeight: 'normal'}}>N = {report.digital.total}</p> */}

                            </div>
                            <br/>
                                <div style={{width:700,margin: 'auto',height: 400}}>
                                <Bar
                                // height={200}
                                data={bar3}
                                plugins={[ChartDataLabels]}
                                options={{
                                    tooltips: {enabled: false},
                                    hover: {mode: null},
                                    
                                    maintainAspectRatio: false,
                                    animation: {
                                        duration: 0
                                    },
                                    scales: {
                                        x: { 
                                            grid: {
                                                display: false,
                                            },
                                            ticks: {
                                                fontColor: "#464646",
                                                fontFamily:'Lato', // this here,
                                                autoSkip: false,
                                                maxRotation: 0,
                                                minRotation: 0
                                            },
                                        },
                                        y: 
                                        {
                                            grid: {
                                                color: '#464646'
                                            },
                                            ticks: {
                                                beginAtZero: true,
                                                fontColor: "#464646",
                                                fontFamily:'Lato' // this here
                                            },
                                        },
                                    
                                    },
                                    plugins: {
                                        datalabels: {
                                        formatter: function(value) {
                                            return '';
                                        },
                                        offset: 2,
                                        padding: 0
                                        },
                                        legend: {
                                            display: true,
                                            position:'bottom',
                                            labels: {
                                                usePointStyle: true
                                            }
                                        },
                                    },
                                }}
                                />
                                </div>
                            <br/>
                            <p><b>Kemampuan SPEx2 yang agile memiliki 5 tahapan:</b></p>
                            <div className="div-flex" style={{alignItems: '-webkit-center',position: 'relative',marginBottom: 20}}>
                                <div style={{top:5,position:'absolute',width: 20,height: 20,backgroundColor: '#0089ed',borderRadius: '100%'}}></div>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <p style={{margin:'0px 0px 0px 20px',fontWeight:' normal'}}><b>Formulasi strategi:</b> Kemampuan organisasi memantau perkembangan luar, memformulasi respon strategi berbasis digital dan data baik untuk perbaikan operasi, mengembangkan bisnis model baru, atau memberikan nilai tambah yang merubah pengalaman pelanggan. Strategi mengedepankan keberanian untuk melakukan disrupsi bahkan mengkanibalisasi bisnis yang ada.</p>
                            </div>
                            <div className="div-flex" style={{alignItems: '-webkit-center',position: 'relative',marginBottom: 20}}>
                                <div style={{top:5,position:'absolute',width: 20,height: 20,backgroundColor: '#0089ed',borderRadius: '100%'}}></div>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <p style={{margin:'0px 0px 0px 20px',fontWeight:' normal'}}><b>Pemetaan strategi:</b> Kemampuan menggambarkan strateginya secara visual dan menarik dengan penanggung
                                jawab yang jelas dalam organisasi disertai pengukuran keberhasilan yang menyeimbangkan indikator <i>leading </i>
                                atau proses dan <i>lagging</i> atau hasil. Komunikasi strategi dilakukan dengan media berbasis digital untuk
                                menjangkau karyawan milenial.
                                </p>
                            </div>
                            
                        </div>
                        <br/>
                        {/* <div className='pagebreak'></div>
                        <br/> */}
                        <div className='pemetaan div-margin strategic-text'>
                        <div className="div-flex" style={{alignItems: '-webkit-center',position: 'relative',marginBottom: 20}}>
                                <div style={{top:5,position:'absolute',width: 20,height: 20,backgroundColor: '#0089ed',borderRadius: '100%'}}></div>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <p style={{margin:'0px 0px 0px 20px',fontWeight:' normal'}}><b>Penyelarasan organisasi:</b> Strategi organisasi diselaraskan dengan investasi dalam pengembangan teknologi
                                digitalisasi dan sumber daya manusia. Struktur organisasi yang ambidekster dibentuk untuk mendukung
                                agilitas.</p>
                            </div>
                            <div className="div-flex" style={{alignItems: '-webkit-center',position: 'relative',marginBottom: 20}}>
                                <div style={{top:5,position:'absolute',width: 20,height: 20,backgroundColor: '#0089ed',borderRadius: '100%'}}></div>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <p style={{margin:'0px 0px 0px 20px',fontWeight:' normal'}}><b>Eksekusi operasional:</b> Program kerja strategis dieksekusi dengan <i>mindset</i> yang fokus kepada pelanggan atau
                                <i>stakeholder</i> utama. Implementasi yang cepat berbasis <i>rapid decision making</i>. Inovasi terus terjadi dalam proses
                                eksekusi. Pemindahan talenta dan pembagian pembelajaran/ide antar proyek atau fungsi mudah dilakukan
                                dengan cepat.</p>
                            </div>
                            <div className="div-flex" style={{alignItems: '-webkit-center',position: 'relative',marginBottom: 20}}>
                                <div style={{top:5,position:'absolute',width: 20,height: 20,backgroundColor: '#0089ed',borderRadius: '100%'}}></div>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <p style={{margin:'0px 0px 0px 20px',fontWeight:' normal'}}><b>Pemantauan dan penyelarasan kembali :</b>  Pemantauan dan reviu kinerja dilakukan dengan disiplin dengan
                                menggunakan pengukuran yang selaras antar individu/unit kerja dengan organisasi serta maturitas bisnis. Ide ide baru terus muncul dalam pembelajaran. Penghargaan atas keberhasilan inovasi diberikan dan kegagalan juga
                                dihargai sebagai bagian dari proses menuju keberhasilan.</p>
                            </div>
                            <p><b>Lihat kembali nilai Anda dan identifikasi tahapan dalam SPEx2 yang memiliki nilai relatif rendah, baik dibanding
                            dengan kelima tahapan dalam organisasi Anda,maupun dibanding perusahaan pembanding. Lakukan prioritas
                            untuk melakukan perbaikan agar transformasi digital Anda dapat lebih berhasil.
                            </b></p>
                        </div>
                    </div>
                </td></tr></tbody>
                <tfoot><tr><td>
                    <div className="footer-space">&nbsp;</div>
                </td></tr></tfoot>
            </table>
            </div>
            }
        </div>
    )
}
export default withRouter(Report)