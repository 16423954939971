export const GET_PROYEK='GET_PROYEK'

export const SET_PROYEK='SET_PROYEK'
export const SET_EMAIL_TEMPLATE='SET_EMAIL_TEMPLATE'

export const SET_BERANDA='SET_BERANDA'
export const SET_BERANDA_SA='SET_BERANDA_SA'
export const RESET_PROYEK='RESET_PROYEK'
export const SET_COUNT='SET_COUNT'

export const GET_LIST_TEST='GET_LIST_TEST'

export const SET_EMAIL_BULK='SET_EMAIL_BULK'
export const SET_EMAIL_BULK_INDIVIDUAL='SET_EMAIL_BULK_INDIVIDUAL'

export const SET_FILTER_SA_PROJECT='SET_FILTER_SA_PROJECT'
export const SET_USE_FILTER='SET_USE_FILTER'

export const GET_LIST_CREDIT='GET_LIST_CREDIT'
export const GET_HISTORY_CREDIT='GET_HISTORY_CREDIT'
export const SET_FILTER_HISTORY_CREDIT='SET_FILTER_HISTORY_CREDIT'
export const RESET_HISTORY_CREDIT='RESET_HISTORY_CREDIT'
