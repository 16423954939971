const config={
    // URL:'https://cors-anywhere.herokuapp.com/api.onegml.com/v1',
    URL:'https://exapi.onegml.com/v1',
    // URL_PARTNER:'https://timbul.dev.kominfo.go.id/api',
    URL_PARTNER:'https://simonas.kominfo.go.id/api',
    USER:'onegmlapi',
    PASS:'O1n6e0G4M7L',
    user_partner:'onegml',
    pass_partner:'AzMr4Ej2kCufLpnX'
    // user_partner:'onegml',
    // pass_partner:'onegml'
}

export const {URL,USER,PASS,URL_PARTNER,user_partner,pass_partner}=config