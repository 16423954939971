import React ,{useState} from 'react'
import {Send} from '@material-ui/icons';
import Paperplane from 'assets/icon/Paperplane.svg'
import { MuiThemeProvider, createMuiTheme,withStyles,makeStyles } from '@material-ui/core/styles'
import {Button,TextField,FormControl,InputLabel,OutlinedInput,Select,MenuItem,
    FormHelperText,InputAdornment,Table,TableHead,TableCell,TableRow,TableBody,FormControlLabel,Checkbox} from '@material-ui/core'
import {
    DatePicker,
    TimePicker,
    DateTimePicker,
    MuiPickersUtilsProvider,
    } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import CevronRight from 'assets/icon/chevron-right.svg'
import CevronLeft from 'assets/icon/chevron-left.svg'
import SearchImg from 'assets/icon/Search.png'
import {useSelector,useDispatch} from 'react-redux'
import { TablePagination } from '@trendmicro/react-paginations';
import '@trendmicro/react-paginations/dist/react-paginations.css';
import {debounce} from 'lodash'
import {modalToggle} from 'redux/actions/general'
import {getReport,setProyek,postProyek,putProyek,getListUserProject,getDetailProjectProgress,getDetailProject,getReportExcel,sendReportKominfo,sendReportKominfoAll,getDataUserBatch,updateDataSent,setCount,getEmailTemplate,getEmailBulk,getEmailBulkIndividual} from 'redux/actions/proyek'
import {getDetailUser,getLog,getDetailUserForDashboard} from 'redux/actions/peserta'
import {partnerRefreshToken,loginToPartner} from 'redux/actions/auth'

import moment from 'moment'
import { toast } from 'react-toastify';
import Download from 'assets/icon/download.svg'
import Excel from 'assets/icon/Excel.svg'
import bg_progress from 'assets/image/bg_progress.svg'
import Close from 'assets/icon/close.svg'
import {user_partner,pass_partner} from 'service/base_url'
import {get} from 'lodash'
import AutoCompleteSelect from 'components/Select'

const useStyles = makeStyles(theme => ({
    textField: {
        [`& fieldset`]: {
            borderRadius: 8,
          },
          width:'100%',
          marginBottom:15
  },
  checkbox:{
      margin:0,
      [`& root`]: {
        margin:0,
      },
  },
  search: {
    [`& fieldset`]: {
        borderRadius: 8,
        height:30,
        margin: theme.spacing(1),
      },
      width:150,
      height:10,
      marginBottom:40
    },
    input:{
        fontSize:14,
        margin:3      
      }
}));

export default function Form_proyek(props) {
    const classes=useStyles()
    const dispatch=useDispatch()
    const proyek = useSelector(state => state.proyek)
    const [filter_peserta,setFilterPeserta]=useState('semua')
    const [modal_filter,setModalFilter]=useState(false)
    const [modal_download,setModalDownload]=useState(false)
    const [search,setSearch]=useState('')
    const {proyek_detail}=proyek
    const refreshData=async ()=>{
        let res1=await dispatch(getDetailProjectProgress(proyek_detail.projectId))
        let res2=await dispatch(getDetailProject(proyek_detail.projectId))
        let res3=await dispatch(getListUserProject(`/${proyek_detail.projectId}/${renderActionFilter()}/${proyek_detail.peserta.peserta_pagination.page}/${proyek_detail.peserta.peserta_pagination.perPage}/${search!==''?search:'*'}`))
        if(res3){
            toast("Data berhasil diperbarui")
        }
    }
    const back=()=>{
        props.tabsToggle('beranda','form_proyek')
    }
    const togglePagination=(page,pageLength)=>{
        dispatch(getListUserProject(`/${proyek_detail.projectId}/${renderActionFilter()}/${page}/${pageLength}/${search!==''?search:'*'}`))
    }
    const searchToggle=debounce(async (value)=>{
        setSearch(value)
        if(value!==search&&value.length>0){
            await dispatch(getListUserProject(`/${proyek_detail.projectId}/${renderActionFilter()}/1/100/${value}`))
            
        }else{
            await dispatch(getListUserProject(`/${proyek_detail.projectId}/${renderActionFilter()}/1/100/*`))

        }
        
    },1000)
    const onChangeFilterPeserta=async (key)=>{
        await setFilterPeserta(key)
        let slug=''
        if(key==='semua'){
            slug= '2/1/0'
        }
        if(key==='selesai'){
            slug= '1/1/0'
        }
        if(key==='process'){
            slug= '0/1/0'
        }
        if(key==='menunggu'){
            slug= '0/0/0'
        }
        if(key==='laporan_terkirim'){
            slug= '1/1/1'
        }
        setModalFilter(false)
        dispatch(getListUserProject(`/${proyek_detail.projectId}/${slug}/${proyek_detail.peserta.peserta_pagination.page}/${proyek_detail.peserta.peserta_pagination.perPage}/${search!==''?search:'*'}`))
    }
    const assignPeserta=async ()=>{
        let res=await dispatch(getEmailTemplate(`/${proyek_detail.projectId}/${props.profile.id}`))
        if(res){
            dispatch(modalToggle({
                modal_open: true,
                modal_title: `Template Email`,
                modal_component: "email_template",
                modal_size:1200,
                modal_data:null,
                modal_action:'email_template'
            }))
        }
    }
    const handleDisableBtn=()=>{
        if(props.tab_action==='detail_proyek'){
            return false
        }else{
            if(proyek_detail.nama!==''&&proyek_detail.tgl_pengerjaan!==null&&proyek_detail.periode!==null&&proyek_detail.deskripsi!==''&&proyek_detail.jenis_test!==''){
                return false
            }else{
                return true
            }
        }
    }
    const handleDisable=()=>{
        if(props.tab_action==='detail_proyek'||!renderIsExpired()){
            return true
        }else{
            return false
        }
    }
    const onSave=async ()=>{
        let new_testId=[]
        proyek_detail.jenis_test.map((data)=>{
            new_testId.push(data.id)
        })
        let data={
            projectId:proyek_detail.projectId,
            userId:props.profile.id,
            name:proyek_detail.nama,
            description:proyek_detail.deskripsi,
            startDate:moment(proyek_detail.tgl_pengerjaan).format('YYYY-MM-DD HH:mm'),
            endDate:moment(proyek_detail.periode).format('YYYY-MM-DD HH:mm'),
            status:"prosess",
            testIds:new_testId
        }
        if(props.tab_action==='add_proyek'){
            let res=await dispatch(postProyek(data))
            if(res){
                props.setTabAction('detail_proyek')
            }
        }
        if(props.tab_action==='edit_proyek'){
            dispatch(putProyek(data))
        }
        if(props.tab_action==='detail_proyek'){
            props.setTabAction('edit_proyek')
        }
    }
    const detail_user=async (data)=>{
        let res=await dispatch(getDetailUserForDashboard(`/${data.id}`))
        let res2=await dispatch(getLog(`/${data.id}/1/10/*`))
        if(res2){
            props.tabsToggle('form_peserta','form_proyek')
            props.setTabAction('detail_peserta')
        }
        
    }
    const renderProgress=(isCompleted,inProcess)=>{
        if(isCompleted == true) {
            return <p style={{margin:0,color:'#A8E090',fontWeight:'bold'}}>Selesai</p>
        }
        else {
            if(inProcess == true) {
                return <p style={{margin:0,color:'#FFB865',fontWeight:'bold'}}>Prosess</p>
            }
            else {
                return <p style={{margin:0,color:'#FF6E79',fontWeight:'bold'}}>Menunggu</p>
            }
        }
    }
    const renderSent=(sent)=>{
        if(sent){
            return <p style={{margin:0,color:'#A8E090',fontWeight:'bold'}}>Terkirim</p>
        }else{
            return <p style={{margin:0,color:'#FF6E79',fontWeight:'bold'}}>-</p>

        }
    }
    const renderAction=(isCompleted,inProcess,id,name,email)=>{
        if(isCompleted == true){
            return (
                <>
                <img onClick={()=>sentEmailToUser(id,name,email)} src={Paperplane} style={{width:15,cursor:'pointer',marginRight:10}}/>
                <img onClick={()=>getExcel(id)} src={Excel} style={{width:15,cursor:'pointer',marginRight:10}}/>
                <img onClick={()=>onReport1(id)} src={Download} style={{width:15,cursor:'pointer',marginRight:10}}/>
                </>
            )
            
        }
    }
    const renderActionFilter=()=>{
        if(filter_peserta==='semua'){
            return '2/1/0'
        }
        if(filter_peserta==='selesai'){
            return '1/1/0'
        }
        if(filter_peserta==='process'){
            return '0/1/0'
        }
        if(filter_peserta==='menunggu'){
            return '0/0/0'
        }
        if(filter_peserta==='laporan_terkirim'){
            return '1/1/1'
        }

    }
    const renderStatus=()=>{
        if(props.tab_action==='add_proyek'){
            return '-'
        }else{
            if(moment().isSameOrBefore(proyek_detail.periode)){
                return 'prosess'
            }else{
                return 'selesai'
            }
        }
    }
    const renderTitle=()=>{
        if(props.tab_action==='add_proyek'){
            return 'Tambah proyek'
        }
        if(props.tab_action==='edit_proyek'){
            return 'Edit proyek'
        }
        if(props.tab_action==='detail_proyek'){
            return 'Detail proyek'
        }
        

    }
    const renderIsExpired=()=>{
        if(props.tab_action!=='add_proyek'){
            if(moment().isSameOrBefore(proyek_detail.periode,'day')){
                return true
            }else{
                return false
            }
        }else{
            return true

        }
    }

    const onReportAll=()=>{
        let new_id=[]
        proyek_detail.peserta.peserta_list.map((data)=>{
            new_id.push(data.id)
        })
        let data={
            projectId:proyek_detail.projectId,
            userIds:new_id

        }
        dispatch(getReport(data))
    }
    const onExcelAll=()=>{
        let new_id=[]
        proyek_detail.peserta.peserta_list.map((data)=>{
            new_id.push(data.id)
        })
        let data={
            projectId:proyek_detail.projectId,
            userIds:new_id

        }
        dispatch(getReportExcel(data))
    }
    const onReport1=(id)=>{
        let data={
            projectId:proyek_detail.projectId,
            userIds:[id]

        }
        dispatch(getReport(data))
    }
    const getExcel=(id)=>{
        let data={
            projectId:proyek_detail.projectId,
            userIds:[id]

        }
        dispatch(getReportExcel(data))
    }
    const sentToKominfo=async (userId,name)=>{
        let login=await dispatch(loginToPartner({username:user_partner,password:pass_partner}))
        if(login){
            let res=await dispatch(sendReportKominfo(`/${proyek_detail.projectId}/${userId}`,name,login.data.access_token))
            if(res){
                let lapor=await dispatch(updateDataSent(`/${proyek_detail.projectId}/${userId}/1`))
                await refreshData()
                if(lapor){
                    dispatch(modalToggle({ 
                        modal_open: true,
                        modal_title: "Data peserta berhasil dikirim",
                        modal_component: "proyek",
                        modal_size:400,
                        modal_type:'alert',
                        modal_data:{
                            msg:`<p>Data user <b>${name}</b> berhasil dikirim ke system Kominfo</p> `,
                            ...res.data
                        },
                        modal_action:'success',
                    }))
                }
            }
        }
    }
    let count=0
    const sentToKominfoAll=async (userId,name)=>{
        dispatch(modalToggle({ 
            modal_open: true,
            modal_title: "",
            modal_component: "",
            modal_size:400,
            modal_type:'prosess',
            modal_data:{},
            modal_action:'prosess',
        }))
        let login=await dispatch(loginToPartner({username:user_partner,password:pass_partner}))
        if(login){
            let active_page=1
            let total_page=0
            for (let index = 0; index < active_page; index++) {
                let getbatch=await dispatch(getDataUserBatch(`/${proyek_detail.projectId}/${active_page}/100/${total_page}`))
                if(getbatch){
                    const currentpage=getbatch.data.currentPage
                    total_page=getbatch.data.totalPage
                    const data=getbatch.data.data
                    let active_user=0
                    for (let index = 0; index < data.length; index++) {
                        

                        let res=await dispatch(sendReportKominfoAll(data[index],login.data.access_token))
                        active_user+=1
                        if(get(res,'status')==201){
                            let lapor=await dispatch(updateDataSent(`/${proyek_detail.projectId}/${data[index].userId}/1`))
                            if(lapor){
                                count+=1
                                await dispatch(setCount(count))
                            }else{
                                await dispatch(setCount(0))
                                break;
                            }
                        }else if(res.response.status==404){
                            console.log('error email not found')
                        }else{
                            alert('error')
                            await dispatch(setCount(0))
                            break;

                        }
                        
                    }
                    
                    if(active_page===total_page){
                        await refreshData()

                        dispatch(modalToggle({ 
                            modal_open: true,
                            modal_title: "Data peserta berhasil dikirim",
                            modal_component: "proyek",
                            modal_size:400,
                            modal_type:'alert',
                            modal_data:{
                                msg:`<p>Data proyek <b>${proyek_detail.nama}</b> berhasil dikirim ke system Kominfo</p> `,
                                ...sentToKominfo.data
                            },
                            modal_action:'success',
                        }))
                        await dispatch(setCount(0))

                        break;
                    }
                    if(active_user===data.length){
                        active_page++
                        console.log('active_page',active_page)
                    }
                }
            }
            
        }
    }
    const sentEmailToUser=async (id,name,email)=>{
        let res=await dispatch(getEmailBulkIndividual(`/${proyek_detail.projectId}/${props.profile.id}`))
        if(res){
            dispatch(modalToggle({
                modal_open: true,
                modal_title: `Kirim hasil ke ${name}`,
                modal_component: "email_template2",
                modal_size:800,
                modal_data:{userIds:id,email:email},
                modal_action:'email_template2'
            }))
        }
    }
    const kirimLaporan=async ()=>{
        let res=await dispatch(getEmailBulk(`/${proyek_detail.projectId}/${props.profile.id}`))
        if(res){
            dispatch(modalToggle({
                modal_open: true,
                modal_title: `Template Email Pengiriman Hasil Laporan`,
                modal_component: "email_template3",
                modal_size:1200,
                modal_data:null,
                modal_action:'email_template3'
            }))
        }
    }
    const deletePeserta=(data)=>{
        dispatch(modalToggle({ 
            modal_open: true,
            modal_title: `detail`,
            modal_component: "confirm_delete",
            modal_size:400,
            modal_type:'confirm',
            modal_data:{
                title:'Peserta',
                userId:data.id,
                projectId:proyek_detail.projectId,
                clientAdminId:props.profile.id,
                userProjectId:data.userProjectId,
                msg:`<p>Apakah anda yakin menghapus ${data.name} dari proyek ${proyek_detail.nama}</p>`
            },
            modal_action:'delete_peserta_proyek'
        }))
    }
    const renderIsDownloaded=()=>{
        let filter=proyek_detail.peserta.peserta_list.filter((data)=>{
            return data.isCompleted===true
        })
        if(filter.length>0){
            return true
        }else{
            return false
        }
    }
    console.log('proyek_detail.peserta.peserta_list', proyek_detail)
    return (
        <div>
            {modal_filter&&<div onClick={()=>setModalFilter(!modal_filter)} className='backdrop'></div>}
            {modal_download&&<div onClick={()=>setModalDownload(!modal_download)} className='backdrop'></div>}
            <div className='head-section'>
                <div>
                    <h4 style={{marginBottom:20}}>{renderTitle()}</h4>
                </div>
                <div className='div-flex'>
                    <Button onClick={back} variant='contained' color='primary' className='btn-rounded btn-remove-capital btn-action' >Kembali</Button>
                    {props.tab_action!=='add_proyek'&&moment().isSameOrBefore(proyek_detail.periode,'day')&&<>&nbsp;&nbsp;<Button disabled={handleDisable()} onClick={assignPeserta} startIcon={<img src={Paperplane} style={{width:15}}/>} variant="text" color='primary' className='btn-rounded btn-remove-capital assign-btn' >Assign peserta</Button></>}
                    &nbsp;&nbsp;
                    {renderIsExpired()&&<Button disabled={handleDisableBtn()} onClick={onSave} variant='contained' color='primary' className='btn-rounded btn-remove-capital btn-action' >
                        {props.tab_action==='add_proyek'&&'Simpan'}
                        {props.tab_action==='edit_proyek'&&'Simpan'}
                        {props.tab_action==='detail_proyek'&&'Edit'}
                    </Button>}
                </div>
            </div>
            <div className='form-content'>
                <div className='form-informasi'>
                    <p className='semi-bold'>Informasi Proyek</p>
                    <TextField
                        label={<>Nama Proyek</>}
                        variant='outlined'
                        value={proyek_detail.nama}
                        size='small'
                        disabled={handleDisable()}
                        className={classes.textField}
                        onChange={(e)=>dispatch(setProyek({nama:e.target.value}))}

                    />
                    <TextField
                        label={<>Deskripsi Proyek</>}
                        variant='outlined'
                        value={proyek_detail.deskripsi}
                        size='small'
                        disabled={handleDisable()}
                        className={classes.textField}
                        onChange={(e)=>dispatch(setProyek({deskripsi:e.target.value}))}
                        multiline
                    />
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <DateTimePicker
                            inputVariant="outlined"
                            value={proyek_detail.tgl_pengerjaan}
                            color='primary' 
                            disabled={handleDisable()}
                            format={'DD MMM YYYY HH:mm'} 

                            onChange={(data)=>dispatch(setProyek({tgl_pengerjaan:data}))}  
                            className={classes.textField}  label='Tanggal pengerjaan' clearable={true} size='small' inputVariant='outlined'
                        />
                        {/* <DateTimePicker 
                            format={'DD MMM YYYY HH:mm'} 
                            disabled={handleDisable()}
                            color='primary' 
                            value={proyek_detail.tgl_pengerjaan} 
                            onChange={(data)=>dispatch(setProyek({tgl_pengerjaan:data}))}  
                            className={classes.textField}  label='Tanggal pengerjaan' clearable={true} size='small' inputVariant='outlined'  /> */}
                    </MuiPickersUtilsProvider>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <DateTimePicker 
                            format={'DD MMM YYYY HH:mm'} 
                            color='primary' 
                            disabled={handleDisable()}
                            value={proyek_detail.periode} 
                            onChange={(data)=>dispatch(setProyek({periode:data}))}  
                            className={classes.textField}  label='Tanggal terakhir pengerjaan' clearable={true} size='small' inputVariant='outlined'  />
                    </MuiPickersUtilsProvider>
                    <TextField
                        label={<>Status</>}
                        variant='outlined'
                        value={renderStatus()}
                        size='small'
                        disabled={true}
                        className={classes.textField}
                        onChange={(e)=>dispatch(setProyek({status:e.target.value}))}

                    />
                    {/* <FormControl disabled={handleDisable()} variant="outlined" size="small" className='add-proposal__field' >
                        <InputLabel  htmlFor="category">Jenis tes</InputLabel>
                        <Select value={proyek_detail.jenis_test} onChange={(e)=>dispatch(setProyek({jenis_test:e.target.value}))} color='primary' name='segmentId'   labelId="label" id="select"  labelWidth={70} className='field-radius'>
                            <MenuItem value={1}>DISC Personality Test</MenuItem>
                        </Select>
                    </FormControl> */}
                    <div style={{width:'100%'}}>
                        <AutoCompleteSelect
                            onChange={(event,value)=>dispatch(setProyek({jenis_test:value}))}
                            options={proyek.list_test}
                            value={proyek_detail.jenis_test}
                            getOptionLabel={(option) => option.text}
                            label={<>Jenis test</>}
                            multiple
                            disabled={handleDisable()}
                        />
                    </div>
                </div>
                <div style={{width:'57%'}}>
                    <div className='progress-pengerjaan'>
                        <img src={bg_progress} style={{position:'absolute',width:'90%',height:'90%',bottom:0}}/>
                        <div className='div-flex div-space-between div-align-center'>
                            <p className='semi-bold'>Progress Pengerjaan</p>
                            {props.tab_action!=='add_proyek'&&<Button onClick={refreshData} color="primary" className='btn-remove-capital' variant="text">Perbarui data</Button>}
                        </div>
                        <div className='progress-wrapp'>
                            
                            <br/><br/>
                            <div className='progress-grid'>
                                <div>
                                    <p className='semi-bold'>Selesai</p>
                                    <div className='div-flex div-space-between'>
                                        <div>
                                            <p style={{fontSize:28,color:'#777777',fontWeight:'bold'}}>{proyek_detail.progress.selesai}</p>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <p className='semi-bold'>Prosess</p>
                                    <div className='div-flex div-space-between'>
                                        <div>
                                            <p style={{fontSize:28,color:'#777777',fontWeight:'bold'}}>{proyek_detail.progress.proses}</p>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <p className='semi-bold'>Menunggu</p>
                                    <div className='div-flex div-space-between'>
                                        <div>
                                            <p style={{fontSize:28,color:'#777777',fontWeight:'bold'}}>{proyek_detail.progress.menunggu}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='daftar-peserta'>
                        <div className='div-flex div-space-between div-align-center'>
                            <div className='div-flex div-align-center'>
                                <p className='semi-bold' style={{marginBottom:0}}>Daftar Peserta</p>
                                &nbsp;&nbsp;
                                <TextField
                                    placeholder="Cari"
                                    variant='outlined'
                                    size='small'
                                    inputProps={{
                                        className:classes.input
                                    }}
                                    InputLabelProps={{shrink: false}}
                                    className={classes.search}
                                    onChange={(e)=>searchToggle(e.target.value)}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                            <img alt="search" src={SearchImg} style={{width:15}}/>
                                        </InputAdornment>
                                        ),
                                    }}
                                />
                            </div>
                            <div className='div-flex' style={{position:'relative'}}>
                                {renderIsDownloaded()&&<Button onClick={()=>setModalDownload(!modal_download)} className='btn-remove-capital' variant="text" size="small" color="primary">Download hasil</Button>}
                                {modal_download&&<div className='download-container'>
                                    <p className='semi-bold'>Pilihan aksi</p>
                                    <Button onClick={onReportAll} className='btn-remove-capital' variant='text' color='primary' size='small'>Download laporan hasil (PDF)</Button>
                                    <Button onClick={onExcelAll} className='btn-remove-capital' variant='text' color='primary' size='small'>Download kode personaliti (Excel)</Button>
                                    <Button onClick={()=>kirimLaporan()} className='btn-remove-capital' variant='text' color='primary' size='small'>Kirim laporan ke peserta</Button>
                                </div>}
                                &nbsp;&nbsp;
                                {modal_filter&&<div className='filter-container'>
                                    <p className='semi-bold'>Filter progress</p>
                                    <FormControlLabel
                                            control={<Checkbox className={classes.checkbox} color="primary" checked={filter_peserta==='semua'} onChange={()=>onChangeFilterPeserta('semua')} name="checkedA" />}
                                            label="Semua"
                                            style={{margin:0}}
                                            style={{color:'#777777'}}
                                        />
                                    <FormControlLabel
                                            control={<Checkbox color="primary" checked={filter_peserta==='menunggu'} onChange={()=>onChangeFilterPeserta('menunggu')} name="checkedA" />}
                                            label="Menunggu"
                                            style={{margin:0}}
                                            style={{color:'#777777'}}
                                        />
                                    <FormControlLabel
                                            control={<Checkbox color="primary" checked={filter_peserta==='process'} onChange={()=>onChangeFilterPeserta('process')} name="checkedA" />}
                                            label="Proses"
                                            style={{margin:0}}
                                            style={{color:'#777777'}}
                                        />
                                    <FormControlLabel
                                            control={<Checkbox color="primary" checked={filter_peserta==='selesai'} onChange={()=>onChangeFilterPeserta('selesai')} name="checkedA" />}
                                            label="Selesai"
                                            style={{margin:0}}
                                            style={{color:'#777777'}}
                                        />
                                    <FormControlLabel
                                            control={<Checkbox color="primary" checked={filter_peserta==='laporan_terkirim'} onChange={()=>onChangeFilterPeserta('laporan_terkirim')} name="checkedA" />}
                                            label="Laporan Terkirim"
                                            style={{margin:0}}
                                            style={{color:'#777777'}}
                                        />
                                </div>}

                                <Button onClick={()=>setModalFilter(!modal_filter)} className='btn-remove-capital' variant="text" size="small" color="primary">Filter</Button>
                            </div>
                        </div>
                        <br/>
                        <Table  size="small" aria-label="a dense table" style={{color:'#777777'}}>
                            <TableHead>
                            <TableRow >
                                <TableCell className='card-content-item-jurnal-text'>No.</TableCell>
                                <TableCell align='left' className='card-content-item-jurnal-text'>Nama peserta</TableCell>
                                <TableCell align='left' className='card-content-item-jurnal-text'>Progres</TableCell>
                                <TableCell align='center' className='card-content-item-jurnal-text'>Status</TableCell>

                                <TableCell align='center' className='card-content-item-jurnal-text'>Aksi</TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                                {proyek_detail.peserta.peserta_list.length>0?proyek_detail.peserta.peserta_list.map((data,i)=>(
                                    <TableRow >
                                        <TableCell align='left'  className='card-content-item-jurnal-text-without-weight' style={{width:20}}>{i+1}</TableCell>
                                        <TableCell onClick={()=>detail_user(data)} align='left'  className='card-content-item-jurnal-text-without-weight-blue' style={{color:'#00A1ED!important',width:150,cursor:'pointer'}}>{data.name}</TableCell>
                                        <TableCell align='left' className='card-content-item-jurnal-text-without-weight' style={{width:40}}>{renderProgress(data.isCompleted,data.inProgress)}</TableCell>
                                        <TableCell align='center' align='center' className='card-content-item-jurnal-text-without-weight' style={{width:40}}>{renderSent(data.sent)}</TableCell>
                                        <TableCell align='center' className='card-content-item-jurnal-text-without-weight'>
                                            
                                            {renderAction(data.isCompleted,data.inProgress,data.id,data.name,data.email)}
                                            {renderIsExpired()&&<img onClick={()=>deletePeserta(data)} src={Close} style={{width:13,cursor:'pointer'}}/>}
                                        </TableCell>
                                    </TableRow>
                                ))
                                :
                                <TableRow >
                                    <TableCell  style={{textAlign:'center'}} colSpan={4} >Belum terdapat peserta</TableCell>
                                </TableRow>
                                }
                        
                            </TableBody>
                        </Table>
                        <div className='card-table__pagination'>
                            {proyek_detail.peserta.peserta_pagination!==null&&
                            <TablePagination
                                    className="card-pagination"
                                    type="reduced"
                                    page={proyek_detail.peserta.peserta_pagination.page}
                                    pageLength={proyek_detail.peserta.peserta_pagination.perPage}
                                    totalRecords={proyek_detail.peserta.peserta_pagination.total}
                                    totalRecords={proyek_detail.peserta.peserta_pagination.total}
                                    onPageChange={({ page, pageLength }) => {
                                        togglePagination(page,pageLength)
                                    }}
                                    prevPageRenderer={() => <img src={CevronLeft} style={{width:10}}/>}
                                    nextPageRenderer={() => <img src={CevronRight}/>}
                                />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
