import React ,{useState}from 'react'
import WelcomeContainer from 'components/WelcomeContainer'
import ChevronLeft from 'assets/icon/chevron-blue-left.svg'
import ChevronRight from 'assets/icon/chevron-blue-right.svg'
import {useSelector} from 'react-redux'
const Welcome=(props)=>{
    let {subToggle,active_intruction,intructionToggle,cfit}=props
    const onClickChevron=(key)=>{
        props.intructionToggle(key)
    }
    return(
        <WelcomeContainer ln="id" btnText={`Mulai proses tahap ${cfit.sub}`} clickAgree={()=>subToggle('next')} welcomeMessage="Anda sedang menjalani proses Culture - Fair Intelligence Test. Terdapat 4 tahap dalam test ini dengan tiap tahap yang memiliki instruksi spesifik harap baca dengan teliti instruksi yang diberikan tiap tahapnya.">
            <div className='welcome-mobile'>
                <h3>Bagaimana menjalani proses Culture - Fair Intelligence Test ?</h3>
                <p className='instruksi-text' style={{fontWeight:'bold',margin:'0px 0px 5px 0px'}}>Instruksi Tahap {cfit.sub}:</p>
                <div dangerouslySetInnerHTML={{__html:cfit.desc}}></div>
                <br/>
                <div className='hide-mobile'>
                    <div className='div-flex div-space-between'>
                        <p>Contoh:</p>
                        <div className='div-flex div-align-center'>
                            {active_intruction!==0&&<img onClick={()=>onClickChevron('back')} src={ChevronLeft} className='chevron-contoh'/>}
                            <p style={{color:'#00a1ed',fontSize:14,fontWeight:'bold',margin:'0px 5px 0px 5px',cursor:'pointer'}} onClick={()=>active_intruction!==cfit.intruction.length-1?onClickChevron('next'):null}>Contoh Lainnya</p>
                            {active_intruction!==cfit.intruction.length-1&&<img onClick={()=>onClickChevron('next')} src={ChevronRight} className='chevron-contoh'/>}
                        </div>
                    </div>
                
                    <br/>
                    <img className='image-contoh' src={cfit.intruction[active_intruction].example_img}/>
                    <p>Jawaban yang benar adalah <span style={{color:'#00a1ed'}}>{cfit.intruction[active_intruction].answer}</span></p>
                </div>
                <div className='show-mobile'>
                    <div className='div-flex div-space-between'>
                        <p>Contoh:</p>
                        <p style={{color:'#cccccc'}}>Contoh lainnya geser</p>
                    </div>
                    <br/>
                    <div className='card-grid'>
                        {cfit.intruction.map((data)=>(
                            <div className='card-grid-contoh'>
                                <img className='image-contoh' src={data.example_img}/>
                                <p>Jawaban yang benar adalah <span style={{color:'#00a1ed'}}>{data.answer}</span></p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </WelcomeContainer>
    )
}

export default Welcome