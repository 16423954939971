import React,{useEffect} from 'react'
import { useDispatch,useSelector } from 'react-redux'
import {getSurveyUuid} from '../../../redux/actions/oas'
import { withRouter } from 'react-router-dom'
import Loading from 'components/Loading'

import Spex2 from './spex2'
import Dtra from './dtra'
 function Index(props) {
     const dispatch = useDispatch()
     const oas = useSelector(state => state.oas)
     let {detail_survey}=oas
    useEffect(() => {
        dispatch(getSurveyUuid( props.match.params.uuid))
    }, [])
    return (
        <div>
            <Loading/>
            {(detail_survey&&detail_survey.surveyId===3)&&<Spex2/>}
            {(detail_survey&&detail_survey.surveyId===7)&&<Dtra/>}
        </div>
    )
}
export default withRouter(Index)