import React,{useState,useEffect} from 'react'
import {useDispatch,useSelector} from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import {Button,TextField,FormControl,InputLabel,OutlinedInput,Select,MenuItem,FormControlLabel,Checkbox} from '@material-ui/core'

import Correct from 'assets/image/Correct.png'
import Wrong from 'assets/image/Wrong.png'
import shape_1 from 'assets/image/shape_1.svg'
import shape_2 from 'assets/image/shape_2.svg'
import shape_3 from 'assets/image/shape_3.svg'
import shape_4 from 'assets/image/shape_4.svg'
import Check from 'assets/icon/Check.svg'
import {tabToggle} from 'redux/actions/master'
import InputMask from 'react-input-mask'
import moment from 'moment'
import './style.css'
import Loader from 'components/Loading'
import {handleFile} from 'components/handleFile'
import {
    DatePicker,
    MuiPickersUtilsProvider,
    } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import {postInfoPeserta,setUpdateBio} from 'redux/actions/assestment'
import {setPeserta,uploadPeserta,uploadPeserta2,getDetailUser} from 'redux/actions/peserta'
import {modalToggle} from 'redux/actions/general'
import Modal from 'components/Modal'
import NotReady from './not_ready'
const useStyles = makeStyles(theme => ({
    textField: {
        [`& fieldset`]: {
            borderRadius: 8,
            },
            width:'100%',
            marginBottom:15
    },
    
}));
export default function Welcome(props) {
    const classes=useStyles()
    const [agree,setAgree]=useState()
    const [isi_form,setIsiForm]=useState(true)

    const dispatch=useDispatch()
    const peserta = useSelector(state => state.peserta)
    const assestment = useSelector(state => state.assestment)
    const general = useSelector(state => state.general)
    const [file_error,setFileError]=useState('')

    const {detail_peserta}=peserta
    useEffect(() => {
        getUsert()
        //test
    }, [])
    const getUsert=async ()=>{
        let res=await dispatch(getDetailUser(`/${props.profile.id}`))
        if(res){
            if(res.data.completed){
                window.location.assign(`/done/${res.data.projectName}`)
            }
        }
    }
    const clickAgree=async ()=>{
        let data={
            userId:props.profile.id,
            name:detail_peserta.nama,
            email:props.profile.email,
            phone:detail_peserta.telepon,
            idNumber:detail_peserta.no_ktp,
            birthDate:moment(detail_peserta.tgl_lahir).format('YYYY-MM-DD'),
            city:detail_peserta.kota_asal,
            gender:detail_peserta.jk,
            education:detail_peserta.institusi,
            eduLevel:detail_peserta.jenjang
        }
        console.log('detail_peserta.file', detail_peserta.file)
        let res=await dispatch(postInfoPeserta(data))
        if(res){
            window.location.assign('/portal')
        }
        // window.location.assign('/portal')
        // window.location.assign('/assestment')
        // dispatch(tabToggle('assestment','welcome'))
    }
    const handleDisable=()=>{
        if(detail_peserta.nama!==''&&detail_peserta.tgl_lahir!==null&&detail_peserta.jk!==''&&detail_peserta.kota_asal!==''&&detail_peserta.no_ktp!==''&&detail_peserta.telepon!==''&&detail_peserta.institusi!==''&&detail_peserta.jenjang!==''){
            return false
        }else{
            return true
        }
    }
    const handleDisableMulai=()=>{
        if(assestment.is_update_bio){
            return false
        }else{
            return true
        }
    }
    const onSave=async ()=>{
        let data={
            userId:props.profile.id,
            name:detail_peserta.nama,
            email:props.profile.email,
            phone:detail_peserta.telepon,
            idNumber:detail_peserta.no_ktp,
            birthDate:moment(detail_peserta.tgl_lahir).format('YYYY-MM-DD'),
            city:detail_peserta.kota_asal,
            gender:detail_peserta.jk,
            education:detail_peserta.institusi,
            eduLevel:detail_peserta.jenjang
        }
        console.log('detail_peserta.file', detail_peserta.file)
        let res=await dispatch(postInfoPeserta(data))
        if(res){
            if(detail_peserta.file!==null){
                let fd=new FormData()
                fd.append('userId',props.profile.id)
                fd.append('files',detail_peserta.file)
                await dispatch(uploadPeserta2(fd))
                // window.location.assign('/portal')
            }
            dispatch(setUpdateBio(true))
            
            // setIsiForm(false)
        }
    }
    const handleFiles=async (evt)=>{
        let handle=await handleFile(evt)
        if(handle.file_error===null){
            dispatch(setPeserta({file:handle.file}))
            dispatch(setPeserta({file_name:handle.file_name}))
        }else{
            setFileError(handle.file_error)
        }
    }
    const resetPass=()=>{
        console.log('aye')
        dispatch(modalToggle({
            modal_open: true,
            modal_title: `Reset Password`,
            modal_component: "reset_password",
            modal_size:350,
            modal_data:null,
            modal_action:'reset_password'
        }))
    }
    
    const renderForm=()=>{
        return(
            <div className='info-wrapper'>
                <h2>Informasi data pribadi</h2>
                <div className='info-flex'>
                    <div className='info-data-diri'>
                        <p className='semi-bold'>Data diri</p>
                        <TextField
                            label={<>Nama</>}
                            variant='outlined'
                            value={detail_peserta.nama}
                            size='small'
                            // disabled={handleDisable()}
                            className={classes.textField}
                            onChange={(e)=>dispatch(setPeserta({nama:e.target.value}))}

                        />
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <DatePicker 
                                format={'DD MMM YYYY'} 
                                color='primary' 
                                value={detail_peserta.tgl_lahir} 
                                onChange={(data)=>dispatch(setPeserta({tgl_lahir:data}))}  
                                className={classes.textField}  label='Tanggal lahir' clearable={true} size='small' inputVariant='outlined'  />
                        </MuiPickersUtilsProvider>
                        <FormControl variant="outlined" size="small" className='add-proposal__field' >
                            <InputLabel  htmlFor="category">Jenis kelamin</InputLabel>
                            <Select value={detail_peserta.jk} onChange={(e)=>dispatch(setPeserta({jk:e.target.value}))}  color='primary' name='segmentId'   labelId="label" id="select"  labelWidth={100} className='field-radius'>
                                <MenuItem value="Laki-laki">Laki-laki</MenuItem>
                                <MenuItem value="Perempuan">Perempuan</MenuItem>
                            </Select>
                        </FormControl>
                        <TextField
                            label={<>Kota asal</>}
                            variant='outlined'
                            value={detail_peserta.kota_asal}
                            size='small'
                            // disabled={handleDisable()}
                            className={classes.textField}
                            onChange={(e)=>dispatch(setPeserta({kota_asal:e.target.value}))}

                        />
                        <TextField
                            label={<>No.KTP</>}
                            variant='outlined'
                            value={detail_peserta.no_ktp}
                            size='small'
                            // disabled={handleDisable()}
                            className={classes.textField}
                            onChange={(e)=>dispatch(setPeserta({no_ktp:e.target.value}))}

                        />
                    </div>
                    <div className='info-kontak' >
                        <p className='semi-bold'>Kontak</p>
                        <InputMask
                            maskChar={null}
                            mask="9999-9999-9999-9999"
                            onChange={(e)=>dispatch(setPeserta({telepon:e.target.value}))}
                            value={detail_peserta.telepon}
                        >
                        {() =>
                            <TextField
                                label={<>Telepon</>}
                                variant='outlined'
                                // value={detail_peserta.telepon}
                                size='small'
                                // disabled={handleDisable()}
                                className={classes.textField}
                                // onChange={(e)=>dispatch(setPeserta({telepon:e.target.value}))}

                            />
                        }
                        </InputMask>
                        <br/><br/>
                        <p className='semi-bold'>Informasi pendidikan</p>
                        <TextField
                            label={<>Institusi pendidikan terakhir</>}
                            variant='outlined'
                            value={detail_peserta.institusi}
                            size='small'
                            // disabled={handleDisable()}
                            className={classes.textField}
                            onChange={(e)=>dispatch(setPeserta({institusi:e.target.value}))}

                        />
                        {/* <TextField
                            label={<>Jenjang pendidikan terakhir</>}
                            variant='outlined'
                            value={detail_peserta.jenjang}
                            size='small'
                            // disabled={handleDisable()}
                            className={classes.textField}
                            onChange={(e)=>dispatch(setPeserta({jenjang:e.target.value}))}

                        /> */}
                        <FormControl variant="outlined" size="small" className='add-proposal__field' >
                            <InputLabel  htmlFor="category">Jenjang pendidikan terakhir</InputLabel>
                            <Select value={detail_peserta.jenjang} onChange={(e)=>dispatch(setPeserta({jenjang:e.target.value}))}  color='primary' name='segmentId'   labelId="label" id="select"  labelWidth={200} className='field-radius'>
                                <MenuItem value="SD">SD</MenuItem>
                                <MenuItem value="SMP">SMP</MenuItem>
                                <MenuItem value="SMA">SMA</MenuItem>
                                <MenuItem value="Diploma 1">Diploma 1</MenuItem>
                                <MenuItem value="Diploma 2">Diploma 2</MenuItem>
                                <MenuItem value="Diploma 3">Diploma 3</MenuItem>
                                <MenuItem value="Diploma 4">Diploma 4</MenuItem>
                                <MenuItem value="Sarjana">Sarjana</MenuItem>
                                <MenuItem value="Magister">Magister</MenuItem>
                                <MenuItem value="Doktor">Doktor</MenuItem>
                            </Select>
                        </FormControl>
                        <p className='semi-bold' style={{marginBottom:0}}>Foto diri</p>
                        <div className='div-flex div-align-center div-space-between' style={{width:'100%'}}>
                            {file_error!==''&&<p style={{fontSize:12,color:'red',margin:0,fontWeight:'bold'}}>{file_error}</p>}
                            
                            {detail_peserta.file!==null||detail_peserta.file_url!==''?
                            detail_peserta.file_url!==''?
                            <p onClick={()=>window.open(detail_peserta.file_url,'_blank')} style={{cursor:'pointer',fontSize:12,color:'#00a1ed',margin:0,fontWeight:'bold'}}>Sudah tersedia</p>
                            :
                            <p style={{fontSize:12,color:'#777777',margin:0,fontWeight:'bold'}}>{detail_peserta.file_name}</p>
                            :
                            <p style={{fontSize:12,color:'#777777',margin:0,fontWeight:'bold'}}>Foto belum tersedia</p>
                            }
                            
                            <Button  component="label" size='small' className='btn-remove-capital' variant='text' color='primary'>
                                Upload file
                                <input
                                    type="file"
                                    style={{ display: "none" }}
                                    onChange={(e)=>handleFiles(e)}
                                />
                            </Button>
                        </div>
                        <br/>
                        <div className='footer-form-mobile' style={{display:'flex',justifyContent:'space-between'}}>
                            <Button onClick={resetPass}    style={{fontweight:'bold',textTransform:'none',borderRadius:20,fontWeight:'bold'}} color='primary' variant='text' size='large'>Ganti password</Button>
                            <Button onClick={onSave} disabled={handleDisable()}   style={{width:150,fontweight:'bold',textTransform:'none',borderRadius:20,fontWeight:'bold'}} color='primary' variant='contained' size='large'>Simpan</Button>

                        </div>
                    </div>
                   
                </div>
                <div style={{display:'flex',justifyContent:'space-between'}}>
                    <Button onClick={resetPass}   className='hide-mobile' style={{width:'30%',textTransform:'none',borderRadius:20,fontWeight:'bold'}} color='primary' variant='outlined' size='large'>Ganti password</Button>
                    <Button onClick={onSave} disabled={handleDisable()}  className='hide-mobile' style={{width:'30%',textTransform:'none',borderRadius:20,fontWeight:'bold'}} color='primary' variant='contained' size='large'>Simpan</Button>

                </div>
            </div>
        )
    }
    return (
        <div>
            <Loader/>
            <Modal token={props.token} profile={props.profile}/>
            {moment().isBefore(detail_peserta.start_date)?
            <NotReady detail_peserta={detail_peserta}  />
            :
            <div className='welcome-wrapper'>
                <div className='welcome-text'>
                    <div className='welcome-mobile' >
                        <h2>Selamat datang, <span>{detail_peserta.nama}</span></h2>
                        <p style={{width:'90%'}}>Anda sedang menjalani proses pengisian test psikologis secara online. Baca Instruksi yang tersedia di setiap unit test yang anda ikuti. Di sepanjang proses pengisian ini, foto Anda juga akan diambil secara berkala untuk verifikasi keikutsertaan Anda dalam proses ini. Mohon untuk mengklik tombol yang sesuai untuk mengijinkan pengambilan foto Anda ini. Terima kasih. Selamat mengerjakan.</p>
                        <Button onClick={clickAgree} disabled={handleDisableMulai()} className='hide-mobile' style={{width:'80%',textTransform:'none',borderRadius:20,fontWeight:'bold'}} color='primary' variant='contained' size='large'>Mulai</Button>
                    </div>
                </div>
                <div className='welcome-example'>
                    <img style={{top:35,left:-20}} className='shape-img' src={shape_1}/>
                    <img style={{top:5,right:50}} className='shape-img' src={shape_2}/>
                    <img style={{top:350,left:-40}} className='shape-img' src={shape_3}/>
                    <img style={{bottom:10,left:300}} className='shape-img' src={shape_4}/>
                    <img style={{bottom:30,right:20}} className='shape-img' src={shape_1}/>
                    <div className='card-example'>
                        {renderForm()}
                    </div>
                </div>
                <br className='show-mobile'/>
                <div className='welcome-mobile show-mobile div-flex div-justify-center' style={{width:'100%'}}>
                    {/* {isi_form?
                    <Button onClick={onSave} disabled={handleDisable()}   style={{width:'100%',textTransform:'none',borderRadius:20,fontWeight:'bold'}} color='primary' variant='contained' size='large'>Simpan</Button>
                    :<Button onClick={clickAgree} disabled={handleDisableMulai()} style={{width:'100%',textTransform:'none',borderRadius:20,fontWeight:'bold'}} color='primary' variant='contained' size='large'>Mulai</Button>} */}
                    <Button onClick={clickAgree} disabled={handleDisableMulai()} style={{width:'100%',textTransform:'none',borderRadius:20,fontWeight:'bold'}} color='primary' variant='contained' size='large'>Mulai</Button>

                </div>
                <br className='show-mobile'/>

            </div>
            }
        </div>
    )
}
