import React,{useEffect,useState} from 'react'
import './style.css'
import {Button,FormControl,InputLabel,OutlinedInput,InputAdornment
    ,Table,TableHead,TextField,TableCell,TableRow,TableBody} from '@material-ui/core'
    import CevronRight from 'assets/icon/chevron-right.svg'
import CevronLeft from 'assets/icon/chevron-left.svg'
import Skeleton from 'components/Skeleton'
import { TablePagination } from '@trendmicro/react-paginations';
import '@trendmicro/react-paginations/dist/react-paginations.css';
import {debounce} from 'lodash'
import SearchImg from 'assets/icon/Search.png'
import Eye from 'assets/icon/eye.svg'
import Edit from 'assets/icon/edit.svg'
import close from 'assets/icon/close.svg'
import {useSelector,useDispatch} from 'react-redux'
import Layout from 'components/Layouts'
import {getListUser,getDetailUser,getRiwayat,getLog,getDetailUserForDashboard} from 'redux/actions/peserta'
import {modalToggle} from 'redux/actions/general'
import { MuiThemeProvider, createMuiTheme,withStyles,makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    search: {
        [`& fieldset`]: {
            borderRadius: 8,
            height:30,
            margin: theme.spacing(1),
          },
          width:200,
          height:10,
          marginBottom:40
  },
  input:{
    fontSize:14,
    margin:3      
  }
}));
export default function Index(props) {
    const dispatch=useDispatch()
    const classes=useStyles()
    const peserta = useSelector(state => state.peserta)
    const general = useSelector(state => state.general)
    const [search,setSearch]=useState('')
    const {peserta_list,peserta_pagination}=peserta
    useEffect(() => {
        if(peserta.peserta_list.length<1){
            dispatch(getListUser(`/0/2/0/1/10/*`))
        }
    }, [])
    const togglePagination=(page,pageLength)=>{
        dispatch(getListUser(`/${0}/2/0/${page}/${pageLength}/${search!==''?search:'*'}`))
    }
    const searchToggle=debounce(async (value)=>{
        setSearch(value)
        if(value!==search&&value.length>0){
            await dispatch(getListUser(`/${0}/2/0/1/10/${value}`))
            
        }else{
            await dispatch(getListUser(`/${0}/2/0/1/10/*`))
        }
        
    },1000)
    const detail_user=async (data)=>{
        let res=await dispatch(getDetailUserForDashboard(`/${data.id}`))
        let res2=await dispatch(getLog(`/${data.id}/1/10/*`))
        if(res2){
            props.tabsToggle('form_peserta','list_peserta')
            props.setTabAction('detail_peserta')
        }
    }
    const edit_peserta=async (data)=>{
        let res=await dispatch(getDetailUserForDashboard(`/${data.id}`))
        let res2=await dispatch(getLog(`/${data.id}/1/10/*`))
        if(res2){
            props.tabsToggle('form_peserta','list_peserta')
            props.setTabAction('edit_peserta')
        }
        
    }
    const delete_peserta=(data)=>{
        dispatch(modalToggle({ 
            modal_open: true,
            modal_title: `detail`,
            modal_component: "confirm_delete",
            modal_size:400,
            modal_type:'confirm',
            modal_data:{
                title:'Peserta',
                id:data.id,
                msg:`<p>Apakah anda yakin menghapus ${data.name}</p>`
            },
            modal_action:'delete_peserta'
        }))
    }
    return (
        <div>
            <div className='head-section'>
                    <div>
                        <h4 style={{marginBottom:0,marginTop:0}}>Manajemen Peserta</h4>
                    </div>

                </div>
                <div className='card-content'>
                <div className='card-table'>
                    <div className='card-table__head'>
                    <TextField
                            placeholder="Cari"
                            variant='outlined'
                            size='small'
                            InputLabelProps={{shrink: false}}
                            className={classes.search}
                            inputProps={{
                                className:classes.input
                            }}
                            onChange={(e)=>searchToggle(e.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                    <img alt="search" src={SearchImg} style={{width:15}}/>
                                </InputAdornment>
                                ),
                              }}
                        />
                    </div>
                    <div className='card-table__content'>
                    <Table  size="small" aria-label="a dense table">
                        <TableHead>
                        <TableRow>
                            <TableCell align="left" style={{width:10}}>No.</TableCell>
                            <TableCell align="left" style={{width:90}}>Nama Peserta</TableCell>
                            <TableCell align="left">Email</TableCell>
                            <TableCell align="left">Kota asal</TableCell>
                            <TableCell align="left" style={{width:50}}>Pendidikan</TableCell>
                            <TableCell align="left" >Tanggal terdaftar</TableCell>
                            <TableCell align="right">Aksi</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                            {general.isLoadingTable?
                                <Skeleton count={7}/>
                            :
                            peserta_list.map((data,i)=>(
                                <TableRow key={i}>
                                    <TableCell >{i+1}</TableCell>
                                    <TableCell >{data.name}</TableCell>
                                    <TableCell>{data.email}</TableCell>
                                    <TableCell>{data.city}</TableCell>
                                    <TableCell>{data.education}</TableCell>
                                    <TableCell>
                                    19 Okt 2020
                                    </TableCell>
                                    <TableCell align="right">
                                        <div >
                                        <img src={Eye} onClick={()=>detail_user(data)} className='icon-action'/>
                                        <img src={Edit} onClick={()=>edit_peserta(data)} className='icon-action'/>
                                        <img src={close} onClick={()=>delete_peserta(data)} className='icon-action'/>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))
                            
                            }
                        </TableBody>
                    </Table>
                    <div className='card-table__pagination'>
                    {peserta_pagination!==null&&
                    <TablePagination
                            className="card-pagination"
                            type="reduced"
                            page={peserta_pagination.page}
                            pageLength={peserta_pagination.perPage}
                            totalRecords={peserta_pagination.total}
                            totalRecords={peserta_pagination.total}
                            onPageChange={({ page, pageLength }) => {
                                togglePagination(page,pageLength)
                            }}
                            prevPageRenderer={() => <img src={CevronLeft} style={{width:10}}/>}
                            nextPageRenderer={() => <img src={CevronRight}/>}
                        />
                    }
                </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
