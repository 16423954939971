import React,{useEffect,useState} from 'react'
import BerandaWomen from 'assets/image/oas/beranda-women.png'
import BerandaFile from 'assets/image/oas/beranda-file.png'
import BerandaPeople from 'assets/image/oas/beranda-people.png'
import {Button,FormControl,InputLabel,OutlinedInput,InputAdornment
    ,Table,TableHead,TableCell,TableRow,TableBody} from '@material-ui/core'
import DataTable from 'components/DataTable'
import SearchTable from 'components/SearchTable'
import Close from 'assets/icon/close.svg'
import Eye from 'assets/icon/eye.svg'
import _ from 'lodash'
import { useDispatch,useSelector } from 'react-redux'
import { getAssestmentList,getAssestmentDetail,deleteAssestment,setOas } from 'redux/actions/oas'
import { modalToggle } from 'redux/actions/general'
import moment from 'moment'
const RenderHead=(props)=>(
    <div className='div-flex div-space-between' style={{alignItems:'flex-end'}}>
        <SearchTable
            {...props}
            searchToggle={(value)=>props.searchToggle(value)}
        />
        <Button onClick={props.refreshAssestment} className='btn-remove-capital' variant='text' color="primary">Refresh</Button>
    </div>
)
export default function List(props) {
    const dispatch = useDispatch()
    const oas = useSelector(state => state.oas)
    const general = useSelector(state => state.general)
    const [search, setsearch] = useState('')
    let {list_assestment,list_assestment_pagination,all_total_assestment,all_total_participant}=oas
    useEffect(() => {
        dispatch(getAssestmentList('/1/10/*'))
    }, [])
    const searchToggle=_.debounce(async (e)=>{
        setsearch(e)
        dispatch(getAssestmentList(`/1/10/${e!==''?e:'*'}`))

    },1000)
    const togglePagination=(page,pageLength)=>{
        dispatch(getAssestmentList(`/${page}/${pageLength}/${search!==''?search:'*'}`))
    }
    const addAssestment=()=>{
        dispatch(setOas({list_group:[]}))
        dispatch(setOas({group_pagination:null}))
        dispatch(setOas({individual_report:[]}))
        dispatch(setOas({individual_report_pagination:null}))
        props.settab('add')
    }
    const detailAssestment=async (data)=>{
        dispatch(setOas({list_group:[]}))
        dispatch(setOas({group_pagination:null}))
        dispatch(setOas({individual_report:[]}))
        dispatch(setOas({individual_report_pagination:null}))
        await dispatch(getAssestmentDetail(data.id))
        props.settab('detail')
    }
    const deleteAction=async (data)=>{
        let res=await dispatch(deleteAssestment(data.id))
        if(res){
            dispatch(getAssestmentList('/1/10/*'))
        }
    }
    const onDeleteAssestment=(data)=>{
        dispatch(modalToggle({
            modal_open: true,
            modal_title: `list`,
            modal_component: "confirm_delete",
            modal_size:400,
            modal_type:'confirm',
            modal_data:{
                title:'Assestment',
                action:()=>deleteAction(data),
                msg:`<p>Yakin hapus <b>${data.name}</b> ?</p>`
            },
            modal_action:'delete_talenta_project'
        }))
    }
    const refreshAssestment=()=>{
        dispatch(getAssestmentList('/1/10/*'))

    }
    return (
        <div>
            <div className="beranda-info">
                <img src={BerandaWomen} className="beranda-women"/>
                <div className="beranda-card-container">
                    <div className="beranda-card">
                        <h2>Total Assestment</h2>
                        <div className="div-flex div-space-between div-align-center">
                            <h1>{all_total_assestment}</h1>
                            <img src={BerandaFile}/>
                        </div>
                    </div>
                    <div className="beranda-card">
                        <h2>Total Participant</h2>
                        <div className="div-flex div-space-between div-align-center">
                            <h1>{all_total_participant}</h1>
                            <img src={BerandaPeople}/>
                        </div>
                    </div>
                </div>
            </div>
            <br/>
            <div className='head-section'>  
                <h4 data-testid="heading"><b>List Assessment</b></h4>
                
                <Button onClick={addAssestment} className='btn-remove-capital btn-rounded' variant='contained' color="primary">Create Assessment</Button>
            </div>
            <DataTable
                head={['No.','Activity Name','Assessmet Type','Client Name','Date','Total Participant','Action']}
                body={()=>{
                    return list_assestment.map((data,i)=>(
                        <TableRow key={i} data-testid="proyek-item">
                            <TableCell>{i+1}</TableCell>
                            <TableCell>{data.name}</TableCell>
                            <TableCell>{data.title}</TableCell>
                            <TableCell>Publik</TableCell>
                            <TableCell>{moment(data.startDate).format("DD-MMM-YYYY")}</TableCell>
                            <TableCell>{data.total}</TableCell>
                            <TableCell align="right">
                                <img src={Eye} onClick={()=>detailAssestment(data)} className='icon-action'/>
                                <img src={Close} onClick={()=>onDeleteAssestment(data)} className='icon-action'/>
                            </TableCell>
                        </TableRow>
                    ))
                }}
                cardHead={<RenderHead {...props} refreshAssestment={refreshAssestment}  searchToggle={searchToggle} />}
                loading={general.isLoadingTable}
                pagination={list_assestment_pagination}
                togglePagination={togglePagination}
            />
        </div>
    )
}
