import React from 'react'
import SurveyContainer from 'components/oascomponent/SurveyContainer'
import { useSelector,useDispatch } from 'react-redux'
import { setOas } from 'redux/actions/oas'
export default function Survey(props) {
    const dispatch = useDispatch()
    const oas = useSelector(state => state.oas)

    let {jawaban}=oas
    const chooseAnswer=(id)=>{
        jawaban[props.active_tab].option=id
        dispatch(setOas({jawaban:jawaban}))
    }
    const activeAnswer=(idOption)=>{
        if(jawaban[props.active_tab].option===idOption){
            return true
        }else{
            return false
        }
    }
    const handleDisabled=()=>{
        if(jawaban[props.active_tab].option===null){
            return true
        }else{
            return false
        }
    }
    return (
        <SurveyContainer handleDisabled={handleDisabled} totalQuestion={props.totalQuestion} current_question={props.active_tab} nameSurvey={props.nameSurvey} renderTextQuestionNumber={props.renderTextQuestionNumber} ln="id" btnText="Selanjutnya" clickNext={()=>props.tabToggle(props.active_tab+1)} clickBack={()=>props.tabToggle(props.active_tab-1)} welcomeMessage="Anda berikut merupakan halaman survey untuk Strategy and Performance Execution Excellence® Audit">
               <p>{props.content.items[0].text.id}</p>
               <div className="spex2-option">
                   {props.content.items[0].options.map((d,i)=>(
                        <div onClick={()=>chooseAnswer(d.id)} key={i} className={`spex2-option-item ${activeAnswer(d.id)&&'selected'} `}>
                            <div style={{padding:10}}>
                                <p>{d.text.id}</p>
                            </div>
                        </div>
                   ))}
               </div>
        </SurveyContainer>
    )
}
