import CfitExample1 from 'assets/image/cfit/cfit-assestment/sub1/example/example-1.png'
import CfitExample2 from 'assets/image/cfit/cfit-assestment/sub1/example/example-2.png'
import CfitExample3 from 'assets/image/cfit/cfit-assestment/sub1/example/example-3.png'

import S1N1 from 'assets/image/cfit/cfit-assestment/sub1/S1N1.svg'
import S1N11 from 'assets/image/cfit/cfit-assestment/sub1/S1N1-1.svg'
import S1N12 from 'assets/image/cfit/cfit-assestment/sub1/S1N1-2.svg'
import S1N13 from 'assets/image/cfit/cfit-assestment/sub1/S1N1-3.svg'
import S1N14 from 'assets/image/cfit/cfit-assestment/sub1/S1N1-4.svg'
import S1N15 from 'assets/image/cfit/cfit-assestment/sub1/S1N1-5.svg'
import S1N16 from 'assets/image/cfit/cfit-assestment/sub1/S1N1-6.svg'

import S1N2 from 'assets/image/cfit/cfit-assestment/sub1/S1N2.svg'
import S1N21 from 'assets/image/cfit/cfit-assestment/sub1/S1N2-1.svg'
import S1N22 from 'assets/image/cfit/cfit-assestment/sub1/S1N2-2.svg'
import S1N23 from 'assets/image/cfit/cfit-assestment/sub1/S1N2-3.svg'
import S1N24 from 'assets/image/cfit/cfit-assestment/sub1/S1N2-4.svg'
import S1N25 from 'assets/image/cfit/cfit-assestment/sub1/S1N2-5.svg'
import S1N26 from 'assets/image/cfit/cfit-assestment/sub1/S1N2-6.svg'

import S1N3 from 'assets/image/cfit/cfit-assestment/sub1/S1N3.svg'
import S1N31 from 'assets/image/cfit/cfit-assestment/sub1/S1N3-1.svg'
import S1N32 from 'assets/image/cfit/cfit-assestment/sub1/S1N3-2.svg'
import S1N33 from 'assets/image/cfit/cfit-assestment/sub1/S1N3-3.svg'
import S1N34 from 'assets/image/cfit/cfit-assestment/sub1/S1N3-4.svg'
import S1N35 from 'assets/image/cfit/cfit-assestment/sub1/S1N3-5.svg'
import S1N36 from 'assets/image/cfit/cfit-assestment/sub1/S1N3-6.svg'

import S1N4 from 'assets/image/cfit/cfit-assestment/sub1/S1N4.svg'
import S1N41 from 'assets/image/cfit/cfit-assestment/sub1/S1N4-1.svg'
import S1N42 from 'assets/image/cfit/cfit-assestment/sub1/S1N4-2.svg'
import S1N43 from 'assets/image/cfit/cfit-assestment/sub1/S1N4-3.svg'
import S1N44 from 'assets/image/cfit/cfit-assestment/sub1/S1N4-4.svg'
import S1N45 from 'assets/image/cfit/cfit-assestment/sub1/S1N4-5.svg'
import S1N46 from 'assets/image/cfit/cfit-assestment/sub1/S1N4-6.svg'

import S1N5 from 'assets/image/cfit/cfit-assestment/sub1/S1N5.svg'
import S1N51 from 'assets/image/cfit/cfit-assestment/sub1/S1N5-1.svg'
import S1N52 from 'assets/image/cfit/cfit-assestment/sub1/S1N5-2.svg'
import S1N53 from 'assets/image/cfit/cfit-assestment/sub1/S1N5-3.svg'
import S1N54 from 'assets/image/cfit/cfit-assestment/sub1/S1N5-4.svg'
import S1N55 from 'assets/image/cfit/cfit-assestment/sub1/S1N5-5.svg'
import S1N56 from 'assets/image/cfit/cfit-assestment/sub1/S1N5-6.svg'

import S1N6 from 'assets/image/cfit/cfit-assestment/sub1/S1N6.svg'
import S1N61 from 'assets/image/cfit/cfit-assestment/sub1/S1N6-1.svg'
import S1N62 from 'assets/image/cfit/cfit-assestment/sub1/S1N6-2.svg'
import S1N63 from 'assets/image/cfit/cfit-assestment/sub1/S1N6-3.svg'
import S1N64 from 'assets/image/cfit/cfit-assestment/sub1/S1N6-4.svg'
import S1N65 from 'assets/image/cfit/cfit-assestment/sub1/S1N6-5.svg'
import S1N66 from 'assets/image/cfit/cfit-assestment/sub1/S1N6-6.svg'

import S1N7 from 'assets/image/cfit/cfit-assestment/sub1/S1N7.svg'
import S1N71 from 'assets/image/cfit/cfit-assestment/sub1/S1N7-1.svg'
import S1N72 from 'assets/image/cfit/cfit-assestment/sub1/S1N7-2.svg'
import S1N73 from 'assets/image/cfit/cfit-assestment/sub1/S1N7-3.svg'
import S1N74 from 'assets/image/cfit/cfit-assestment/sub1/S1N7-4.svg'
import S1N75 from 'assets/image/cfit/cfit-assestment/sub1/S1N7-5.svg'
import S1N76 from 'assets/image/cfit/cfit-assestment/sub1/S1N7-6.svg'

import S1N8 from 'assets/image/cfit/cfit-assestment/sub1/S1N8.svg'
import S1N81 from 'assets/image/cfit/cfit-assestment/sub1/S1N8-1.svg'
import S1N82 from 'assets/image/cfit/cfit-assestment/sub1/S1N8-2.svg'
import S1N83 from 'assets/image/cfit/cfit-assestment/sub1/S1N8-3.svg'
import S1N84 from 'assets/image/cfit/cfit-assestment/sub1/S1N8-4.svg'
import S1N85 from 'assets/image/cfit/cfit-assestment/sub1/S1N8-5.svg'
import S1N86 from 'assets/image/cfit/cfit-assestment/sub1/S1N8-6.svg'

import S1N9 from 'assets/image/cfit/cfit-assestment/sub1/S1N9.svg'
import S1N91 from 'assets/image/cfit/cfit-assestment/sub1/S1N9-1.svg'
import S1N92 from 'assets/image/cfit/cfit-assestment/sub1/S1N9-2.svg'
import S1N93 from 'assets/image/cfit/cfit-assestment/sub1/S1N9-3.svg'
import S1N94 from 'assets/image/cfit/cfit-assestment/sub1/S1N9-4.svg'
import S1N95 from 'assets/image/cfit/cfit-assestment/sub1/S1N9-5.svg'
import S1N96 from 'assets/image/cfit/cfit-assestment/sub1/S1N9-6.svg'

import S1N10 from 'assets/image/cfit/cfit-assestment/sub1/S1N10.svg'
import S1N101 from 'assets/image/cfit/cfit-assestment/sub1/S1N10-1.svg'
import S1N102 from 'assets/image/cfit/cfit-assestment/sub1/S1N10-2.svg'
import S1N103 from 'assets/image/cfit/cfit-assestment/sub1/S1N10-3.svg'
import S1N104 from 'assets/image/cfit/cfit-assestment/sub1/S1N10-4.svg'
import S1N105 from 'assets/image/cfit/cfit-assestment/sub1/S1N10-5.svg'
import S1N106 from 'assets/image/cfit/cfit-assestment/sub1/S1N10-6.svg'

import S1N_11 from 'assets/image/cfit/cfit-assestment/sub1/S1N11.svg'
import S1N111 from 'assets/image/cfit/cfit-assestment/sub1/S1N11-1.svg'
import S1N112 from 'assets/image/cfit/cfit-assestment/sub1/S1N11-2.svg'
import S1N113 from 'assets/image/cfit/cfit-assestment/sub1/S1N11-3.svg'
import S1N114 from 'assets/image/cfit/cfit-assestment/sub1/S1N11-4.svg'
import S1N115 from 'assets/image/cfit/cfit-assestment/sub1/S1N11-5.svg'
import S1N116 from 'assets/image/cfit/cfit-assestment/sub1/S1N11-6.svg'

import S1N_12 from 'assets/image/cfit/cfit-assestment/sub1/S1N12.svg'
import S1N121 from 'assets/image/cfit/cfit-assestment/sub1/S1N12-1.svg'
import S1N122 from 'assets/image/cfit/cfit-assestment/sub1/S1N12-2.svg'
import S1N123 from 'assets/image/cfit/cfit-assestment/sub1/S1N12-3.svg'
import S1N124 from 'assets/image/cfit/cfit-assestment/sub1/S1N12-4.svg'
import S1N125 from 'assets/image/cfit/cfit-assestment/sub1/S1N12-5.svg'
import S1N126 from 'assets/image/cfit/cfit-assestment/sub1/S1N12-6.svg'

import S1N_13 from 'assets/image/cfit/cfit-assestment/sub1/S1N13.svg'
import S1N131 from 'assets/image/cfit/cfit-assestment/sub1/S1N13-1.svg'
import S1N132 from 'assets/image/cfit/cfit-assestment/sub1/S1N13-2.svg'
import S1N133 from 'assets/image/cfit/cfit-assestment/sub1/S1N13-3.svg'
import S1N134 from 'assets/image/cfit/cfit-assestment/sub1/S1N13-4.svg'
import S1N135 from 'assets/image/cfit/cfit-assestment/sub1/S1N13-5.svg'
import S1N136 from 'assets/image/cfit/cfit-assestment/sub1/S1N13-6.svg'

import S2C1 from 'assets/image/cfit/cfit-assestment/sub2/example/S2C1.svg'
import S2C2 from 'assets/image/cfit/cfit-assestment/sub2/example/S2C2.svg'

import S2N11 from 'assets/image/cfit/cfit-assestment/sub2/S2N1-1.svg'
import S2N12 from 'assets/image/cfit/cfit-assestment/sub2/S2N1-2.svg'
import S2N13 from 'assets/image/cfit/cfit-assestment/sub2/S2N1-3.svg'
import S2N14 from 'assets/image/cfit/cfit-assestment/sub2/S2N1-4.svg'
import S2N15 from 'assets/image/cfit/cfit-assestment/sub2/S2N1-5.svg'

import S2N21 from 'assets/image/cfit/cfit-assestment/sub2/S2N2-1.svg'
import S2N22 from 'assets/image/cfit/cfit-assestment/sub2/S2N2-2.svg'
import S2N23 from 'assets/image/cfit/cfit-assestment/sub2/S2N2-3.svg'
import S2N24 from 'assets/image/cfit/cfit-assestment/sub2/S2N2-4.svg'
import S2N25 from 'assets/image/cfit/cfit-assestment/sub2/S2N2-5.svg'

import S2N31 from 'assets/image/cfit/cfit-assestment/sub2/S2N3-1.svg'
import S2N32 from 'assets/image/cfit/cfit-assestment/sub2/S2N3-2.svg'
import S2N33 from 'assets/image/cfit/cfit-assestment/sub2/S2N3-3.svg'
import S2N34 from 'assets/image/cfit/cfit-assestment/sub2/S2N3-4.svg'
import S2N35 from 'assets/image/cfit/cfit-assestment/sub2/S2N3-5.svg'

import S2N41 from 'assets/image/cfit/cfit-assestment/sub2/S2N4-1.svg'
import S2N42 from 'assets/image/cfit/cfit-assestment/sub2/S2N4-2.svg'
import S2N43 from 'assets/image/cfit/cfit-assestment/sub2/S2N4-3.svg'
import S2N44 from 'assets/image/cfit/cfit-assestment/sub2/S2N4-4.svg'
import S2N45 from 'assets/image/cfit/cfit-assestment/sub2/S2N4-5.svg'

import S2N51 from 'assets/image/cfit/cfit-assestment/sub2/S2N5-1.svg'
import S2N52 from 'assets/image/cfit/cfit-assestment/sub2/S2N5-2.svg'
import S2N53 from 'assets/image/cfit/cfit-assestment/sub2/S2N5-3.svg'
import S2N54 from 'assets/image/cfit/cfit-assestment/sub2/S2N5-4.svg'
import S2N55 from 'assets/image/cfit/cfit-assestment/sub2/S2N5-5.svg'

import S2N61 from 'assets/image/cfit/cfit-assestment/sub2/S2N6-1.svg'
import S2N62 from 'assets/image/cfit/cfit-assestment/sub2/S2N6-2.svg'
import S2N63 from 'assets/image/cfit/cfit-assestment/sub2/S2N6-3.svg'
import S2N64 from 'assets/image/cfit/cfit-assestment/sub2/S2N6-4.svg'
import S2N65 from 'assets/image/cfit/cfit-assestment/sub2/S2N6-5.svg'

import S2N71 from 'assets/image/cfit/cfit-assestment/sub2/S2N7-1.svg'
import S2N72 from 'assets/image/cfit/cfit-assestment/sub2/S2N7-2.svg'
import S2N73 from 'assets/image/cfit/cfit-assestment/sub2/S2N7-3.svg'
import S2N74 from 'assets/image/cfit/cfit-assestment/sub2/S2N7-4.svg'
import S2N75 from 'assets/image/cfit/cfit-assestment/sub2/S2N7-5.svg'

import S2N81 from 'assets/image/cfit/cfit-assestment/sub2/S2N8-1.svg'
import S2N82 from 'assets/image/cfit/cfit-assestment/sub2/S2N8-2.svg'
import S2N83 from 'assets/image/cfit/cfit-assestment/sub2/S2N8-3.svg'
import S2N84 from 'assets/image/cfit/cfit-assestment/sub2/S2N8-4.svg'
import S2N85 from 'assets/image/cfit/cfit-assestment/sub2/S2N8-5.svg'

import S2N91 from 'assets/image/cfit/cfit-assestment/sub2/S2N9-1.svg'
import S2N92 from 'assets/image/cfit/cfit-assestment/sub2/S2N9-2.svg'
import S2N93 from 'assets/image/cfit/cfit-assestment/sub2/S2N9-3.svg'
import S2N94 from 'assets/image/cfit/cfit-assestment/sub2/S2N9-4.svg'
import S2N95 from 'assets/image/cfit/cfit-assestment/sub2/S2N9-5.svg'

import S2N101 from 'assets/image/cfit/cfit-assestment/sub2/S2N10-1.svg'
import S2N102 from 'assets/image/cfit/cfit-assestment/sub2/S2N10-2.svg'
import S2N103 from 'assets/image/cfit/cfit-assestment/sub2/S2N10-3.svg'
import S2N104 from 'assets/image/cfit/cfit-assestment/sub2/S2N10-4.svg'
import S2N105 from 'assets/image/cfit/cfit-assestment/sub2/S2N10-5.svg'

import S2N111 from 'assets/image/cfit/cfit-assestment/sub2/S2N11-1.svg'
import S2N112 from 'assets/image/cfit/cfit-assestment/sub2/S2N11-2.svg'
import S2N113 from 'assets/image/cfit/cfit-assestment/sub2/S2N11-3.svg'
import S2N114 from 'assets/image/cfit/cfit-assestment/sub2/S2N11-4.svg'
import S2N115 from 'assets/image/cfit/cfit-assestment/sub2/S2N11-5.svg'

import S2N121 from 'assets/image/cfit/cfit-assestment/sub2/S2N12-1.svg'
import S2N122 from 'assets/image/cfit/cfit-assestment/sub2/S2N12-2.svg'
import S2N123 from 'assets/image/cfit/cfit-assestment/sub2/S2N12-3.svg'
import S2N124 from 'assets/image/cfit/cfit-assestment/sub2/S2N12-4.svg'
import S2N125 from 'assets/image/cfit/cfit-assestment/sub2/S2N12-5.svg'

import S2N131 from 'assets/image/cfit/cfit-assestment/sub2/S2N13-1.svg'
import S2N132 from 'assets/image/cfit/cfit-assestment/sub2/S2N13-2.svg'
import S2N133 from 'assets/image/cfit/cfit-assestment/sub2/S2N13-3.svg'
import S2N134 from 'assets/image/cfit/cfit-assestment/sub2/S2N13-4.svg'
import S2N135 from 'assets/image/cfit/cfit-assestment/sub2/S2N13-5.svg'

import S2N141 from 'assets/image/cfit/cfit-assestment/sub2/S2N14-1.svg'
import S2N142 from 'assets/image/cfit/cfit-assestment/sub2/S2N14-2.svg'
import S2N143 from 'assets/image/cfit/cfit-assestment/sub2/S2N14-3.svg'
import S2N144 from 'assets/image/cfit/cfit-assestment/sub2/S2N14-4.svg'
import S2N145 from 'assets/image/cfit/cfit-assestment/sub2/S2N14-5.svg'

import S3C1 from 'assets/image/cfit/cfit-assestment/sub3/example/S3C1.svg'
import S3C2 from 'assets/image/cfit/cfit-assestment/sub3/example/S3C2.svg'
import S3C3 from 'assets/image/cfit/cfit-assestment/sub3/example/S3C3.svg'

import S3N1 from 'assets/image/cfit/cfit-assestment/sub3/S3N1.svg'
import S3N11 from 'assets/image/cfit/cfit-assestment/sub3/S3N1-1.svg'
import S3N12 from 'assets/image/cfit/cfit-assestment/sub3/S3N1-2.svg'
import S3N13 from 'assets/image/cfit/cfit-assestment/sub3/S3N1-3.svg'
import S3N14 from 'assets/image/cfit/cfit-assestment/sub3/S3N1-4.svg'
import S3N15 from 'assets/image/cfit/cfit-assestment/sub3/S3N1-5.svg'
import S3N16 from 'assets/image/cfit/cfit-assestment/sub3/S3N1-6.svg'

import S3N2 from 'assets/image/cfit/cfit-assestment/sub3/S3N2.svg'
import S3N21 from 'assets/image/cfit/cfit-assestment/sub3/S3N2-1.svg'
import S3N22 from 'assets/image/cfit/cfit-assestment/sub3/S3N2-2.svg'
import S3N23 from 'assets/image/cfit/cfit-assestment/sub3/S3N2-3.svg'
import S3N24 from 'assets/image/cfit/cfit-assestment/sub3/S3N2-4.svg'
import S3N25 from 'assets/image/cfit/cfit-assestment/sub3/S3N2-5.svg'
import S3N26 from 'assets/image/cfit/cfit-assestment/sub3/S3N2-6.svg'

import S3N3 from 'assets/image/cfit/cfit-assestment/sub3/S3N3.svg'
import S3N31 from 'assets/image/cfit/cfit-assestment/sub3/S3N3-1.svg'
import S3N32 from 'assets/image/cfit/cfit-assestment/sub3/S3N3-2.svg'
import S3N33 from 'assets/image/cfit/cfit-assestment/sub3/S3N3-3.svg'
import S3N34 from 'assets/image/cfit/cfit-assestment/sub3/S3N3-4.svg'
import S3N35 from 'assets/image/cfit/cfit-assestment/sub3/S3N3-5.svg'
import S3N36 from 'assets/image/cfit/cfit-assestment/sub3/S3N3-6.svg'

import S3N4 from 'assets/image/cfit/cfit-assestment/sub3/S3N4.svg'
import S3N41 from 'assets/image/cfit/cfit-assestment/sub3/S3N4-1.svg'
import S3N42 from 'assets/image/cfit/cfit-assestment/sub3/S3N4-2.svg'
import S3N43 from 'assets/image/cfit/cfit-assestment/sub3/S3N4-3.svg'
import S3N44 from 'assets/image/cfit/cfit-assestment/sub3/S3N4-4.svg'
import S3N45 from 'assets/image/cfit/cfit-assestment/sub3/S3N4-5.svg'
import S3N46 from 'assets/image/cfit/cfit-assestment/sub3/S3N4-6.svg'

import S3N5 from 'assets/image/cfit/cfit-assestment/sub3/S3N5.svg'
import S3N51 from 'assets/image/cfit/cfit-assestment/sub3/S3N5-1.svg'
import S3N52 from 'assets/image/cfit/cfit-assestment/sub3/S3N5-2.svg'
import S3N53 from 'assets/image/cfit/cfit-assestment/sub3/S3N5-3.svg'
import S3N54 from 'assets/image/cfit/cfit-assestment/sub3/S3N5-4.svg'
import S3N55 from 'assets/image/cfit/cfit-assestment/sub3/S3N5-5.svg'
import S3N56 from 'assets/image/cfit/cfit-assestment/sub3/S3N5-6.svg'

import S3N6 from 'assets/image/cfit/cfit-assestment/sub3/S3N6.svg'
import S3N61 from 'assets/image/cfit/cfit-assestment/sub3/S3N6-1.svg'
import S3N62 from 'assets/image/cfit/cfit-assestment/sub3/S3N6-2.svg'
import S3N63 from 'assets/image/cfit/cfit-assestment/sub3/S3N6-3.svg'
import S3N64 from 'assets/image/cfit/cfit-assestment/sub3/S3N6-4.svg'
import S3N65 from 'assets/image/cfit/cfit-assestment/sub3/S3N6-5.svg'
import S3N66 from 'assets/image/cfit/cfit-assestment/sub3/S3N6-6.svg'

import S3N7 from 'assets/image/cfit/cfit-assestment/sub3/S3N7.svg'
import S3N71 from 'assets/image/cfit/cfit-assestment/sub3/S3N7-1.svg'
import S3N72 from 'assets/image/cfit/cfit-assestment/sub3/S3N7-2.svg'
import S3N73 from 'assets/image/cfit/cfit-assestment/sub3/S3N7-3.svg'
import S3N74 from 'assets/image/cfit/cfit-assestment/sub3/S3N7-4.svg'
import S3N75 from 'assets/image/cfit/cfit-assestment/sub3/S3N7-5.svg'
import S3N76 from 'assets/image/cfit/cfit-assestment/sub3/S3N7-6.svg'

import S3N8 from 'assets/image/cfit/cfit-assestment/sub3/S3N8.svg'
import S3N81 from 'assets/image/cfit/cfit-assestment/sub3/S3N8-1.svg'
import S3N82 from 'assets/image/cfit/cfit-assestment/sub3/S3N8-2.svg'
import S3N83 from 'assets/image/cfit/cfit-assestment/sub3/S3N8-3.svg'
import S3N84 from 'assets/image/cfit/cfit-assestment/sub3/S3N8-4.svg'
import S3N85 from 'assets/image/cfit/cfit-assestment/sub3/S3N8-5.svg'
import S3N86 from 'assets/image/cfit/cfit-assestment/sub3/S3N8-6.svg'

import S3N9 from 'assets/image/cfit/cfit-assestment/sub3/S3N9.svg'
import S3N91 from 'assets/image/cfit/cfit-assestment/sub3/S3N9-1.svg'
import S3N92 from 'assets/image/cfit/cfit-assestment/sub3/S3N9-2.svg'
import S3N93 from 'assets/image/cfit/cfit-assestment/sub3/S3N9-3.svg'
import S3N94 from 'assets/image/cfit/cfit-assestment/sub3/S3N9-4.svg'
import S3N95 from 'assets/image/cfit/cfit-assestment/sub3/S3N9-5.svg'
import S3N96 from 'assets/image/cfit/cfit-assestment/sub3/S3N9-6.svg'

import S3N10 from 'assets/image/cfit/cfit-assestment/sub3/S3N10.svg'
import S3N101 from 'assets/image/cfit/cfit-assestment/sub3/S3N10-1.svg'
import S3N102 from 'assets/image/cfit/cfit-assestment/sub3/S3N10-2.svg'
import S3N103 from 'assets/image/cfit/cfit-assestment/sub3/S3N10-3.svg'
import S3N104 from 'assets/image/cfit/cfit-assestment/sub3/S3N10-4.svg'
import S3N105 from 'assets/image/cfit/cfit-assestment/sub3/S3N10-5.svg'
import S3N106 from 'assets/image/cfit/cfit-assestment/sub3/S3N10-6.svg'

import S3N_11 from 'assets/image/cfit/cfit-assestment/sub3/S3N11.svg'
import S3N111 from 'assets/image/cfit/cfit-assestment/sub3/S3N11-1.svg'
import S3N112 from 'assets/image/cfit/cfit-assestment/sub3/S3N11-2.svg'
import S3N113 from 'assets/image/cfit/cfit-assestment/sub3/S3N11-3.svg'
import S3N114 from 'assets/image/cfit/cfit-assestment/sub3/S3N11-4.svg'
import S3N115 from 'assets/image/cfit/cfit-assestment/sub3/S3N11-5.svg'
import S3N116 from 'assets/image/cfit/cfit-assestment/sub3/S3N11-6.svg'

import S3N_12 from 'assets/image/cfit/cfit-assestment/sub3/S3N12.svg'
import S3N121 from 'assets/image/cfit/cfit-assestment/sub3/S3N12-1.svg'
import S3N122 from 'assets/image/cfit/cfit-assestment/sub3/S3N12-2.svg'
import S3N123 from 'assets/image/cfit/cfit-assestment/sub3/S3N12-3.svg'
import S3N124 from 'assets/image/cfit/cfit-assestment/sub3/S3N12-4.svg'
import S3N125 from 'assets/image/cfit/cfit-assestment/sub3/S3N12-5.svg'
import S3N126 from 'assets/image/cfit/cfit-assestment/sub3/S3N12-6.svg'

import S3N_13 from 'assets/image/cfit/cfit-assestment/sub3/S3N13.svg'
import S3N131 from 'assets/image/cfit/cfit-assestment/sub3/S3N13-1.svg'
import S3N132 from 'assets/image/cfit/cfit-assestment/sub3/S3N13-2.svg'
import S3N133 from 'assets/image/cfit/cfit-assestment/sub3/S3N13-3.svg'
import S3N134 from 'assets/image/cfit/cfit-assestment/sub3/S3N13-4.svg'
import S3N135 from 'assets/image/cfit/cfit-assestment/sub3/S3N13-5.svg'
import S3N136 from 'assets/image/cfit/cfit-assestment/sub3/S3N13-6.svg'

import S4C1 from 'assets/image/cfit/cfit-assestment/sub4/example/S4C1.svg'
import S4C2 from 'assets/image/cfit/cfit-assestment/sub4/example/S4C2.svg'
import S4C3 from 'assets/image/cfit/cfit-assestment/sub4/example/S4C3.svg'

import S4N1 from 'assets/image/cfit/cfit-assestment/sub4/S4N1.svg'
import S4N11 from 'assets/image/cfit/cfit-assestment/sub4/S4N1-1.svg'
import S4N12 from 'assets/image/cfit/cfit-assestment/sub4/S4N1-2.svg'
import S4N13 from 'assets/image/cfit/cfit-assestment/sub4/S4N1-3.svg'
import S4N14 from 'assets/image/cfit/cfit-assestment/sub4/S4N1-4.svg'
import S4N15 from 'assets/image/cfit/cfit-assestment/sub4/S4N1-5.svg'

import S4N2 from 'assets/image/cfit/cfit-assestment/sub4/S4N2.svg'
import S4N21 from 'assets/image/cfit/cfit-assestment/sub4/S4N2-1.svg'
import S4N22 from 'assets/image/cfit/cfit-assestment/sub4/S4N2-2.svg'
import S4N23 from 'assets/image/cfit/cfit-assestment/sub4/S4N2-3.svg'
import S4N24 from 'assets/image/cfit/cfit-assestment/sub4/S4N2-4.svg'
import S4N25 from 'assets/image/cfit/cfit-assestment/sub4/S4N2-5.svg'

import S4N3 from 'assets/image/cfit/cfit-assestment/sub4/S4N3.svg'
import S4N31 from 'assets/image/cfit/cfit-assestment/sub4/S4N3-1.svg'
import S4N32 from 'assets/image/cfit/cfit-assestment/sub4/S4N3-2.svg'
import S4N33 from 'assets/image/cfit/cfit-assestment/sub4/S4N3-3.svg'
import S4N34 from 'assets/image/cfit/cfit-assestment/sub4/S4N3-4.svg'
import S4N35 from 'assets/image/cfit/cfit-assestment/sub4/S4N3-5.svg'

import S4N4 from 'assets/image/cfit/cfit-assestment/sub4/S4N4.svg'
import S4N41 from 'assets/image/cfit/cfit-assestment/sub4/S4N4-1.svg'
import S4N42 from 'assets/image/cfit/cfit-assestment/sub4/S4N4-2.svg'
import S4N43 from 'assets/image/cfit/cfit-assestment/sub4/S4N4-3.svg'
import S4N44 from 'assets/image/cfit/cfit-assestment/sub4/S4N4-4.svg'
import S4N45 from 'assets/image/cfit/cfit-assestment/sub4/S4N4-5.svg'

import S4N5 from 'assets/image/cfit/cfit-assestment/sub4/S4N5.svg'
import S4N51 from 'assets/image/cfit/cfit-assestment/sub4/S4N5-1.svg'
import S4N52 from 'assets/image/cfit/cfit-assestment/sub4/S4N5-2.svg'
import S4N53 from 'assets/image/cfit/cfit-assestment/sub4/S4N5-3.svg'
import S4N54 from 'assets/image/cfit/cfit-assestment/sub4/S4N5-4.svg'
import S4N55 from 'assets/image/cfit/cfit-assestment/sub4/S4N5-5.svg'

import S4N6 from 'assets/image/cfit/cfit-assestment/sub4/S4N6.svg'
import S4N61 from 'assets/image/cfit/cfit-assestment/sub4/S4N6-1.svg'
import S4N62 from 'assets/image/cfit/cfit-assestment/sub4/S4N6-2.svg'
import S4N63 from 'assets/image/cfit/cfit-assestment/sub4/S4N6-3.svg'
import S4N64 from 'assets/image/cfit/cfit-assestment/sub4/S4N6-4.svg'
import S4N65 from 'assets/image/cfit/cfit-assestment/sub4/S4N6-5.svg'

import S4N7 from 'assets/image/cfit/cfit-assestment/sub4/S4N7.svg'
import S4N71 from 'assets/image/cfit/cfit-assestment/sub4/S4N7-1.svg'
import S4N72 from 'assets/image/cfit/cfit-assestment/sub4/S4N7-2.svg'
import S4N73 from 'assets/image/cfit/cfit-assestment/sub4/S4N7-3.svg'
import S4N74 from 'assets/image/cfit/cfit-assestment/sub4/S4N7-4.svg'
import S4N75 from 'assets/image/cfit/cfit-assestment/sub4/S4N7-5.svg'

import S4N8 from 'assets/image/cfit/cfit-assestment/sub4/S4N8.svg'
import S4N81 from 'assets/image/cfit/cfit-assestment/sub4/S4N8-1.svg'
import S4N82 from 'assets/image/cfit/cfit-assestment/sub4/S4N8-2.svg'
import S4N83 from 'assets/image/cfit/cfit-assestment/sub4/S4N8-3.svg'
import S4N84 from 'assets/image/cfit/cfit-assestment/sub4/S4N8-4.svg'
import S4N85 from 'assets/image/cfit/cfit-assestment/sub4/S4N8-5.svg'

import S4N9 from 'assets/image/cfit/cfit-assestment/sub4/S4N9.svg'
import S4N91 from 'assets/image/cfit/cfit-assestment/sub4/S4N9-1.svg'
import S4N92 from 'assets/image/cfit/cfit-assestment/sub4/S4N9-2.svg'
import S4N93 from 'assets/image/cfit/cfit-assestment/sub4/S4N9-3.svg'
import S4N94 from 'assets/image/cfit/cfit-assestment/sub4/S4N9-4.svg'
import S4N95 from 'assets/image/cfit/cfit-assestment/sub4/S4N9-5.svg'

import S4N10 from 'assets/image/cfit/cfit-assestment/sub4/S4N10.svg'
import S4N101 from 'assets/image/cfit/cfit-assestment/sub4/S4N10-1.svg'
import S4N102 from 'assets/image/cfit/cfit-assestment/sub4/S4N10-2.svg'
import S4N103 from 'assets/image/cfit/cfit-assestment/sub4/S4N10-3.svg'
import S4N104 from 'assets/image/cfit/cfit-assestment/sub4/S4N10-4.svg'
import S4N105 from 'assets/image/cfit/cfit-assestment/sub4/S4N10-5.svg'

import Example from 'assets/image/soal1.png'
import Questions_1_10 from 'assets/image/toefl/Questions-1-10.png'
import Questions_11_18 from 'assets/image/toefl/Questions-11-18.png'
import Questions_19_28 from 'assets/image/toefl/Questions-19-28.png'
import Questions_29_40 from 'assets/image/toefl/Questions-29-40.png'
import Question_41_50 from 'assets/image/toefl/Questions-41-50.png'
 const image_import={
    cfit:{
        CfitExample1,CfitExample2,CfitExample3,
        S1N1,S1N11,S1N12,S1N13,S1N14,S1N15,S1N16,
        S1N2,S1N21,S1N22,S1N23,S1N24,S1N25,S1N26,
        S1N3,S1N31,S1N32,S1N33,S1N34,S1N35,S1N36,
        S1N4,S1N41,S1N42,S1N43,S1N44,S1N45,S1N46,
        S1N5,S1N51,S1N52,S1N53,S1N54,S1N55,S1N56,
        S1N6,S1N61,S1N62,S1N63,S1N64,S1N65,S1N66,
        S1N7,S1N71,S1N72,S1N73,S1N74,S1N75,S1N76,
        S1N8,S1N81,S1N82,S1N83,S1N84,S1N85,S1N86,
        S1N9,S1N91,S1N92,S1N93,S1N94,S1N95,S1N96,
        S1N10,S1N101,S1N102,S1N103,S1N104,S1N105,S1N106,
        S1N_11,S1N111,S1N112,S1N113,S1N114,S1N115,S1N116,
        S1N_12,S1N121,S1N122,S1N123,S1N124,S1N125,S1N126,
        S1N_13,S1N131,S1N132,S1N133,S1N134,S1N135,S1N136,

        S2C1,S2C2,
        S2N11,S2N12,S2N13,S2N14,S2N15,
        S2N21,S2N22,S2N23,S2N24,S2N25,
        S2N31,S2N32,S2N33,S2N34,S2N35,
        S2N41,S2N42,S2N43,S2N44,S2N45,
        S2N51,S2N52,S2N53,S2N54,S2N55,
        S2N61,S2N62,S2N63,S2N64,S2N65,
        S2N71,S2N72,S2N73,S2N74,S2N75,
        S2N81,S2N82,S2N83,S2N84,S2N85,
        S2N91,S2N92,S2N93,S2N94,S2N95,
        S2N101,S2N102,S2N103,S2N104,S2N105,
        S2N111,S2N112,S2N113,S2N114,S2N115,
        S2N121,S2N122,S2N123,S2N124,S2N125,
        S2N131,S2N132,S2N133,S2N134,S2N135,
        S2N141,S2N142,S2N143,S2N144,S2N145,

        S3C1,S3C2,S3C3,
        S3N1,S3N11,S3N12,S3N13,S3N14,S3N15,S3N16,
        S3N2,S3N21,S3N22,S3N23,S3N24,S3N25,S3N26,
        S3N3,S3N31,S3N32,S3N33,S3N34,S3N35,S3N36,
        S3N4,S3N41,S3N42,S3N43,S3N44,S3N45,S3N46,
        S3N5,S3N51,S3N52,S3N53,S3N54,S3N55,S3N56,
        S3N6,S3N61,S3N62,S3N63,S3N64,S3N65,S3N66,
        S3N7,S3N71,S3N72,S3N73,S3N74,S3N75,S3N76,
        S3N8,S3N81,S3N82,S3N83,S3N84,S3N85,S3N86,
        S3N9,S3N91,S3N92,S3N93,S3N94,S3N95,S3N96,
        S3N10,S3N101,S3N102,S3N103,S3N104,S3N105,S3N106,
        S3N_11,S3N111,S3N112,S3N113,S3N114,S3N115,S3N116,
        S3N_12,S3N121,S3N122,S3N123,S3N124,S3N125,S3N126,
        S3N_13,S3N131,S3N132,S3N133,S3N134,S3N135,S3N136,

        S4C1,S4C2,S4C3,
        S4N1,S4N11,S4N12,S4N13,S4N14,S4N15,
        S4N2,S4N21,S4N22,S4N23,S4N24,S4N25,
        S4N3,S4N31,S4N32,S4N33,S4N34,S4N35,
        S4N4,S4N41,S4N42,S4N43,S4N44,S4N45,
        S4N5,S4N51,S4N52,S4N53,S4N54,S4N55,
        S4N6,S4N61,S4N62,S4N63,S4N64,S4N65,
        S4N7,S4N71,S4N72,S4N73,S4N74,S4N75,
        S4N8,S4N81,S4N82,S4N83,S4N84,S4N85,
        S4N9,S4N91,S4N92,S4N93,S4N94,S4N95,
        S4N10,S4N101,S4N102,S4N103,S4N104,S4N105,

    },
    toefl:{
        Example,
        Questions_1_10,Questions_11_18,Questions_19_28,Questions_29_40,Question_41_50
    }
    
}
export default image_import