import React ,{useState,useEffect}from 'react'
import WelcomeContainer from 'components/WelcomeContainer'
import Check from 'assets/icon/Check.svg'
import Correct from 'assets/image/Correct.png'
import {useSelector,useDispatch} from 'react-redux'
import {setToeflTime} from 'redux/actions/assestment'
const Welcome=(props)=>{
    const dispatch=useDispatch()
    const assestment = useSelector(state => state.assestment)
    const {toefl_start_time}=assestment
    let time_count=0
    let {partToggle,clickAgreePart,active_intruction,intructionToggle,toefl}=props
    
    useEffect(() => {
        
    }, [])
    const onClickAgree=()=>{
        clickAgreePart()
    }
    return(
        <WelcomeContainer ln="en" btnText={`Start`} clickAgree={()=>onClickAgree()} welcomeMessage="You are currently undergoing the English Proficiency Test process. Read the instructions provided at each stage of the test carefully.">
            <div className='welcome-mobile'>
                <h3>How to take the English Proficiency  Test process ?</h3>
                <div dangerouslySetInnerHTML={{__html:toefl.desc}}></div>
                {/* <div className=''>
                    <div className='div-flex div-space-between'>
                        <p>Contoh:</p>
                    </div>
                
                    <div className='papi-example-wrapper'>
                        <div className='div-flex' style={{gap:2,marginBottom:5}}>
                            <div className='papi-example-checkbox checked-example'>
                                <img src={Check}/>
                            </div>
                            <div className='papi-example-question'>
                                <p>Saya seorang pekerja “keras” </p>
                            </div>
                        </div>
                        <div className='div-flex' style={{gap:2,marginBottom:5}}>
                            <div className='papi-example-checkbox'>
                                <img src={Check}/>
                            </div>
                            <div className='papi-example-question'>
                                <p>Saya seorang pekerja “keras” </p>
                            </div>
                        </div>
                        <div style={{textAlign:'center',marginTop:10}}>
                            <img src={Correct} style={{width:50}}/>
                        </div>
                    </div>
                    <p>Bekerjalah dengan cepat, tetapi jangan sampai ada nomor pernyataan yang terlewatkan.</p>
                </div> */}
            </div>
        </WelcomeContainer>
    )
}

export default Welcome