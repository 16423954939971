import React ,{useEffect}from 'react'
import './style.css'
import ACS from 'assets/image/ACS.png'
import shape_portal1 from 'assets/image/shape_portal1.svg'
import shape_portal2 from 'assets/image/shape_portal2.svg'
import Disc from 'assets/image/disc_login.png'
import CFIT from 'assets/image/CFIT.svg'
import TOELF from 'assets/image/TOELF.png'
import PAPI from 'assets/image/PAPI.png'
import {Button} from '@material-ui/core'
import { MuiThemeProvider, createMuiTheme,withStyles, } from '@material-ui/core/styles'
import {useDispatch,useSelector} from 'react-redux'
import {getListTest,getLisByUserId} from 'redux/actions/general'
import {getDetailUser} from 'redux/actions/peserta'
import Loader from 'components/Loading'
import {resetQuestion,resetPapi,resetCfit,resetToefl,resetAssestmentReducer} from 'redux/actions/assestment'

const themeButton = createMuiTheme({ 
    palette: { 
        primary: {
            main:'#00a1ed',
            contrastText: '#FFFFFF',
        },
    } 
})
export default function Index(props) {
    const dispatch=useDispatch()
    const general = useSelector(state => state.general)
    useEffect(() => {
        // dispatch(resetAssestmentReducer())
        getUsert()
        dispatch(getLisByUserId(`/${props.profile.id}`))
        dispatch(getListTest())
        
    }, [])
    const getUsert=async ()=>{
        let res=await dispatch(getDetailUser(`/${props.profile.id}`))
        if(res){
            if(res.data.completed){
                window.location.assign(`/done/${res.data.projectName}`)
            }
        }
    }
    const renderImg=(title)=>{
        switch (title) {
            case 'Work Personality Trait':
                return Disc
                break;
            case 'Work Preference Test':
                return PAPI
            case 'English Ability Test':
                return TOELF
            case 'Intellectual Ability Test':
                return CFIT
            default:
                break;
        }
    }
    const renderLink=(title)=>{
        switch (title) {
            case 'Work Personality Trait':
                return '/disc/welcome'
                break;
            case 'Work Preference Test':
                return '/papi/assestment'
            case 'English Ability Test':
                return '/toefl/assestment'
            case 'Intellectual Ability Test':
                return '/cfit/assestment'
            default:
                break;
        }
    }
    const handleDisable=(index)=>{
        // return false
        if(general.list_test_by_userid.length>0){
            if(index==0&&general.list_test_by_userid[0].completed==false){
                return false
            }else if(index!==0){
                if(general.list_test_by_userid[index].completed){
                    return true
                }else{
                    if(general.list_test_by_userid[index-1].completed){
                        return false
                    }else{
                        return true
                    }
                }
            }else{
                return true
            }
        }
    }
    const renderGap=()=>{

        if(general.list_test_by_userid.length>3){
            return 20
        }else{
            return 60
        }
    }
    // console.log('assestment.list_test_by_userid', general.list_test_by_userid)
    return (
        <div style={{backgroundColor:'#fcfcfc'}}>
            <Loader/>
            <img src={shape_portal1} className='portal-shape shape-top'/>
            <img src={shape_portal2} className='portal-shape shape-bottom'/>
            <div className='portal-header'>
                <img src={ACS} className='portal-logo'/>
            </div>
            <div className='portal-body'>
                <div style={{position:'absolute'}}>
                <h2 className='portal-body-h2'>Providing Assessment & Talent Management solutions</h2>
                <p className='portal-body-p'>Deliver assessment report as valuable information for the participants<br/> and provide development plan for each participant.</p>
                </div>
                <br/>
                <div className='portal-card-wrapper' >
                    {general.list_test_by_userid.map((data,i)=>(
                        <div className='portal-card' key={i} style={{marginRight:renderGap(),marginBottom:10}}>
                            <img src={renderImg(data.text)}/>
                            <div className='portal-card-content'>
                            <h1>{data.text}</h1>
                            <p>{data.description}</p>
                            
                            </div>
                            <div className='portal-card-footer'>
                            <MuiThemeProvider theme={themeButton}>
                                <Button disabled={handleDisable(i)} onClick={()=>window.location.assign(renderLink(data.text))} className='btn-remove-capital btn-rounded' style={{width:'100%'}} size="medium" color='primary' variant='contained'>Ikuti Test</Button>
                            </MuiThemeProvider>
                            </div>
                        </div>
                    ))}
                   
                    
                </div>
            </div>
        </div>
    )
}
