import * as actionTypes from "../constants/peserta";
import moment from 'moment'
const initialState={
    peserta_list:[],
    peserta_pagination:{
        page:0,
        perPage:10,
        total:0
    },
    
    riwayat_list:[],
    riwayat_pagination:{
        page:0,
        perPage:1,
        total:0
    },

    log_list:[],
    log_pagination:{
        page:0,
        perPage:1,
        total:0
    },

    detail_log_list:[],
    detail_log_pagination:{
        page:0,
        perPage:10,
        total:0
    },

    detail_peserta:{
        last_log:null,
        userId:0,
        nama:'',
        tgl_lahir:null,
        jk:'',
        kota_asal:'',
        no_ktp:'',
        email:'',
        telepon:'',
        file:null,
        file_name:'',
        file_url:'',
        institusi:'',
        jenjang:'',
        nama_proyek:'',
        tgl_terdaftar:null,
        ketersediaan:'',

        start_date:null,
        end_date:null
    }
}
export default (state=initialState,action)=>{
    switch (action.type) {
        case actionTypes.GET_PESERTA:
            return{
                ...state,
                peserta_list:action.payload.peserta_list,
                peserta_pagination:action.payload.peserta_pagination,
                
            }
        case actionTypes.GET_RIWAYAT:
            return{
                ...state,
                riwayat_list:action.payload.riwayat_list,
                riwayat_pagination:action.payload.riwayat_pagination,
                
            }
        case actionTypes.GET_LOG:
            return{
                ...state,
                log_list:action.payload.log_list,
                log_pagination:action.payload.log_pagination,
                
            }
        
        case actionTypes.GET_DETAIL_LOG:
            return{
                ...state,
                detail_log_list:action.payload.detail_log_list,
                detail_log_pagination:action.payload.detail_log_pagination,
                
            }
        case actionTypes.SET_PESERTA:
            return{
                ...state,
                detail_peserta:{
                    ...state.detail_peserta,
                    [Object.keys(action.payload)]:Object.values(action.payload)[0]
                }
            }
        default:
            return state;
    }
}

