import React,{useState} from 'react'
import {useSelector,useDispatch} from 'react-redux'
import ChevronLeft from 'assets/icon/chevron-blue-left.svg'
import ChevronRight from 'assets/icon/chevron-blue-right.svg'
// import '../../../views/assestment/style.css'
export default function ToeflIntruksi(props) {
    const dispatch=useDispatch()
    const assestment = useSelector(state => state.assestment)
    const general = useSelector(state => state.general)
    const {active_sub,active_part}=assestment
    const [active_intruction, setActiveIntruction] = useState(0)
    const toefl=assestment.toefl[active_sub].part[active_part]

    const intructionToggle=(key)=>{
        if(key==='next'){
            setActiveIntruction(active_intruction+1)
        }else{
            setActiveIntruction(active_intruction-1)
        }
    }
    const onClickChevron=(key)=>{
        intructionToggle(key)
    }
    return (
        <div>
            
            <div className='intruksi-modal'>
                <div className='welcome-mobile'>
                <h3>{toefl.title_desc_modal}</h3>
                <div dangerouslySetInnerHTML={{__html:toefl.desc}}></div>
                <br/>
               
            </div>
            </div>
        </div>
    )
}
