import React,{useState,useEffect} from 'react'
import { makeStyles } from '@material-ui/core/styles'

import Correct from 'assets/image/Correct.png'
import Wrong from 'assets/image/Wrong.png'
import Check from 'assets/icon/Check.svg'


import WelcomeContainer from 'components/WelcomeContainer'
import Camera from 'components/Camera'
import {useSelector} from 'react-redux'
import Cookie from 'universal-cookie'
const cookie = new Cookie()
export default function Welcome(props) {
    const general = useSelector(state => state.general)
    const clickAgree=()=>{
        cookie.set('start_time',Date.now())
        window.location.assign('/disc/assestment')
    }

    return (
        <div>
           {general.on_cam&&<Camera profile={props.profile}/>}
            <WelcomeContainer ln="id" btnText="Mulai" clickAgree={clickAgree} welcomeMessage="Anda sedang menjalani proses Work Personality Test anda diminta meluangkan waktu 20 menit dari 24 pertanyaan yang tersedia">
                    <div className='welcome-mobile'>
                        <h3>Bagaimana menjalani proses Work Personality Test?</h3>
                        <p>Anda diminta memilih hanya satu <b>M</b> (<b style={{color:'#78000F'}}>M</b>enggambarkan diri anda) dan satu <b>T</b> (<b style={{color:'#78000F'}}>T</b>idak menggambarkan diri anda) di dalam sekelompok pernyataan. Ketika anda ragu dengan semua pilihan yang tersedia, anda tetap perlu memilih satu yang paling mencermkinkan diri anda, dan satu yang paling tidak mencerminkan. Anda tidak perlu memikirkan pilihan anda. Biasanya pilihan pertama anda adalah yang terbaik.</p>
                        <div className='div-flex div-space-between'>
                            <p>Contoh :</p>
                            <p onClick={()=>window.scrollTo(500,0)} className='show-mobile'>Geser</p>
                        </div>
                        <div className='card-grid'>
                            <div className='card-grid-assestment'>
                                <div className='grid-head2'>
                                    <p style={{color:'#78000F'}}>M</p>
                                    <p style={{color:'#78000F'}}>T</p>
                                </div>
                                <div className='div-flex grid-content'>
                                    <div className='left-card-example'></div>
                                    <div className='middle-card-example'>
                                        <p>Baik hati</p>
                                    </div>
                                    <div className='right-card-example active-card'>
                                        <img src={Check} style={{width:15}}/>
                                    </div>
                                </div>
                                <div className='div-flex grid-content'>
                                    <div className='left-card-example'></div>
                                    <div className='middle-card-example'>
                                        <p>Memiliki keberanian</p>
                                    </div>
                                    <div className='right-card-example'></div>
                                </div>
                                <div className='div-flex grid-content'>
                                    <div className='left-card-example'></div>
                                    <div className='middle-card-example'>
                                        <p>Menyenangkan</p>
                                    </div>
                                    <div className='right-card-example'></div>
                                </div>
                                <div className='div-flex grid-content'>
                                    <div className='left-card-example active-card'>
                                    <img src={Check} style={{width:15}}/>
                                    </div>
                                    <div className='middle-card-example'>
                                        <p>Periang</p>
                                    </div>
                                    <div className='right-card-example'></div>
                                </div>
                                <br/>
                                <div style={{textAlign:'center'}}>
                                    <img src={Correct} style={{width:'25%'}}/>
                                </div>
                            </div>
                            <div className='card-grid-assestment'>
                            <div className='grid-head2'>
                                    <p style={{color:'#78000F'}}>M</p>
                                    <p style={{color:'#78000F'}}>T</p>
                                </div>
                                <div className='div-flex grid-content'>
                                    <div className='left-card-example '></div>
                                    <div className='middle-card-example'>
                                        <p>Baik hati</p>
                                    </div>
                                    <div className='right-card-example '>

                                    </div>
                                </div>
                                <div className='div-flex grid-content'>
                                    <div className='left-card-example'></div>
                                    <div className='middle-card-example'>
                                        <p>Memiliki keberanian</p>
                                    </div>
                                    <div className='right-card-example'></div>
                                </div>
                                <div className='div-flex grid-content'>
                                    <div className='left-card-example'></div>
                                    <div className='middle-card-example'>
                                        <p>Menyenangkan</p>
                                    </div>
                                    <div className='right-card-example'></div>
                                </div>
                                <div className='div-flex grid-content'>
                                    <div className='left-card-example active-card'>
                                    <img src={Check} style={{width:15}}/>
                                    </div>
                                    <div className='middle-card-example'>
                                        <p>Periang</p>
                                    </div>
                                    <div className='right-card-example'></div>
                                </div>
                                <br/>
                                <div style={{textAlign:'center'}}>
                                    <img src={Wrong} style={{width:'25%'}}/>
                                </div>
                            </div>
                            <div className='card-grid-assestment'>
                            <div className='grid-head2'>
                                    <p style={{color:'#78000F'}}>M</p>
                                    <p style={{color:'#78000F'}}>T</p>
                                </div>
                                <div className='div-flex grid-content'>
                                    <div className='left-card-example active-card'>
                                    <img src={Check} style={{width:15}}/>
                                    </div>
                                    <div className='middle-card-example'>
                                        <p>Baik hati</p>
                                    </div>
                                    <div className='right-card-example active-card'>
                                    <img src={Check} style={{width:15}}/>
                                    </div>
                                </div>
                                <div className='div-flex grid-content'>
                                    <div className='left-card-example'></div>
                                    <div className='middle-card-example'>
                                        <p>Memiliki keberanian</p>
                                    </div>
                                    <div className='right-card-example'></div>
                                </div>
                                <div className='div-flex grid-content'>
                                    <div className='left-card-example'></div>
                                    <div className='middle-card-example'>
                                        <p>Menyenangkan</p>
                                    </div>
                                    <div className='right-card-example'></div>
                                </div>
                                <div className='div-flex grid-content'>
                                    <div className='left-card-example '></div>
                                    <div className='middle-card-example'>
                                        <p>Periang</p>
                                    </div>
                                    <div className='right-card-example'></div>
                                </div>
                                <br/>
                                <div style={{textAlign:'center'}}>
                                    <img src={Wrong} style={{width:'25%'}}/>
                                </div>
                            </div>
                        </div>
                        
                    </div>
            </WelcomeContainer>
        </div>
    )
}
