import React,{useEffect} from 'react'
import '../style.css'
import Logo1 from 'assets/image/logo.png'
import Map from 'assets/image/map.svg'

import report_5 from 'assets/image/report_5.jpg'
import report_6 from 'assets/image/report_6.jpg'
import report_7 from 'assets/image/report_7.jpg'
import report_8 from 'assets/image/report_8.jpg'
import moment from 'moment'
import {useDispatch, useSelector} from 'react-redux'
import { withRouter } from 'react-router-dom'
import {getSurveyReport} from 'redux/actions/oas'
import Loading from 'components/Loading'
import { Bar } from "react-chartjs-2";
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);
const options = {
    indexAxis: "y" ,
    elements: {
      bar: {
        borderWidth: 0,
        barPercentage:3
      }
    },
    responsive: true,
    plugins: {
      legend: {
        display: false
      },
      title: {
        display: false,
        text: "Chart.js Horizontal Bar Chart"
      }
    },
  
    scales: {
      x: {
        min: 0.0,
        max: 4.0,
        grid: {
          display: false
        },
        ticks: {
          callback: function (value, index, values) {
            if (value === 0.5) {
              return "Fail";
            } else if (value === 1.5) {
              return "Poor";
            } else if (value === 2.5) {
              return "Good";
            } else if (value === 3.5) {
              return "Outstanding";
            } else {
              return;
            }
          }
        }
      },
      y: {}
    }
  };
  
  const labels = [["Strategy ","formulation"], ["Organizational level ","strategy mapping"], "Organizational alignment", "Operational execution", ["Monitoring and realignment ","at organization level"], ["Monitoring and ","realignment at unit level"], ["Leadership and ","infrastructure"]];
  
 const data = {
    labels,
    datasets: [
      {
        label: "Dataset 1",
        data: [],
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: []
      }
    ]
  };
function Report_individu(props) {
    const dispatch = useDispatch()
    const oas = useSelector(state => state.oas)
    let {report_survey}=oas
    useEffect(() => {
        getReport()
    }, [])
    const renderBgColor=(score)=>{
      console.log(`score`, score)
        if(score<2){
            return '#ed4900'
        }
        if(score>=2&&score<=3.5){
            return '#00ed64'
        }
        if(score>3.5){
            return '#00a1ed'
        }
    }
    const getReport=async ()=>{
        let res=await dispatch(getSurveyReport(`/3/${props.match.params.uuid}`))
        if(res){
            res.data.dimensions.map((d)=>{
                data.datasets[0].data.push(d.indicators[0].value)
                data.datasets[0].backgroundColor.push(renderBgColor(d.indicators[0].value))
            })
        }
    }
    let oo=[]
    const setonLoaded=(index)=>{
      if(oo.length<4){
        oo.push(index)
      }else{
          oo=[]
          setTimeout(()=>{
              
              window.print()
              
          },3000)
      }
    }
    return (
        <div>
            <Loading/>
            {report_survey&&<div className='report-wrapper' >
                <div className='report-header'>
                    <div className='report-shape'></div>
                    <div className='div-margin'>
                        <img src={Logo1} onLoad={()=>setonLoaded(0)} style={{width:100}}/>
                    </div>
                </div>
                <div className='report-footer'>
                    <div className='div-margin'>
                        <p>Report date :  {moment().format('DD MMM YYYY')}</p>
                    </div>
                </div>
                <table style={{width:'100%'}}>
                <thead><tr><td>
                    <div className="header-space">&nbsp;</div>
                </td></tr>
                </thead>
                <tbody><tr><td>
                    <div className="content">

                        <div className='div-flex div-margin'>
                            {/* {renderImage().map((data,index)=>( */}
                                <div style={{width:140,height:140}}>
                                <img src={report_5} onLoad={()=>setonLoaded(1)}  style={{width:'100%'}}/>
                                &nbsp;&nbsp;
                                </div>
                                <div style={{width:140,height:140}}>
                                <img src={report_6} onLoad={()=>setonLoaded(2)}  style={{width:'100%'}}/>
                                &nbsp;&nbsp;
                                </div>
                                <div style={{width:140,height:140}}>
                                <img src={report_7} onLoad={()=>setonLoaded(3)}  style={{width:'100%'}}/>
                                &nbsp;&nbsp;
                                </div>
                                <div style={{width:140,height:140}}>
                                <img src={report_8} onLoad={()=>setonLoaded(4)}  style={{width:'100%'}}/>
                                &nbsp;&nbsp;
                                </div>
                            {/* ))} */}
                        </div>
                        <br/>
                        <div className='report-title div-flex div-align-center div-margin'>
                            <h1 className='report-h1-title'>{report_survey.title}</h1>
                            <h1 className='vertical-title'>Confidential</h1>
                        </div>
                        <div className='report-information div-margin'>
                            <p className='text-bold'>Respondent Information</p>
                            {report_survey.cover.map((data,i)=>(
                                <div className='div-flex'>
                                    <p style={{width:250}}>{data.indicator}</p>
                                    <p>:&nbsp;{data.text}</p>
                                </div>
                                // <div className='div-flex'>
                                //     <p style={{width:250}}>Perusahaan</p>
                                //     <p>:&nbsp;GML</p>
                                // </div>
                           ))}  
                        </div>
                        <div className='report-map'>
                            <img src={Map}/>
                        </div>
                        
                        <div className='pagebreak'></div>
                        
                        <br/>
                        <div className='report-shape3'>
                            <h1>Result of Strategy and Performance Execution Excellence Audit</h1>
                        </div>
                        <div className='div-margin strategic-text'>
                            <p>{report_survey.description}</p>
                        </div>
                        <br/>
                        <div className='div-margin'>
                            <div style={{width:'70%'}}>
                            <Bar height={200} options={options} data={data} />

                            </div>
                        </div>
                    </div>
                </td></tr></tbody>
                <tfoot><tr><td>
                    <div className="footer-space">&nbsp;</div>
                </td></tr></tfoot>
            </table>
            </div>}
        </div>
    )
}
export default withRouter(Report_individu)