import React, { Component } from 'react'
import './style.css'
import Chevron from 'assets/icon/chevron_white.svg'

import {Collapse} from '@material-ui/core'
import {connect} from 'react-redux'
import {logout} from 'redux/actions/auth'
import {modalToggle} from 'redux/actions/general'
import {withRouter} from 'react-router-dom'
import Cookie from 'universal-cookie'
import ava_default from 'assets/icon/avadefault.svg'
import Dashboard1 from 'assets/icon/Dashboard1.svg'
import Dashboard2 from 'assets/icon/Dashboard2.svg'
import Folders2 from 'assets/icon/Folders2.svg'
import Folders3 from 'assets/icon/Folders3.svg'
import Users2 from 'assets/icon/Users2.svg'
import Users1 from 'assets/icon/Users1.svg'
import { Dashboard } from '@material-ui/icons'
const cookie=new Cookie()
const profile=cookie.get('profile_cookie')

class index extends Component {
    state={
        page_collapse:false,
        admin_collapse:false,
        profile:null
    }
    componentDidMount(){
        let url=this.props.location.pathname
        let page_collapse=url.includes('/page')
        let admin_collapse=url.includes('/admin')
        this.setState({page_collapse,admin_collapse,profile})
    }
    toggleCollapse=(key)=>{
        this.setState({[key]:!this.state[key]})
    }
    toggleActive=(key)=>{
        let url=this.props.location.pathname
        let match=url.includes(key)
        return match
    }
    gantiPassword=()=>{
        this.props.modalToggle({
            modal_open: true,
            modal_title: `Reset Password`,
            modal_component: "reset_password",
            modal_size:350,
            modal_data:null,
            modal_action:'reset_password'
        })
    }
    render() {
        const {page_collapse,admin_collapse}=this.state
        const {roleId}=profile
        console.log('roleId', roleId)
        return (
            <div className='sidebar-wrapper'>
                <div className='is-collapse admin-list' onClick={()=>this.toggleCollapse('admin_collapse')}>
                    <div className='' style={{display:'flex',alignItems:'center'}}>
                        <img src={ava_default} style={{width:30}}/>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <a>Admin</a>
                    </div>
                    <div>
                        <img src={Chevron} className={admin_collapse?'chevron':' active-chevron'}/>
                    </div>
                </div>
                <Collapse in={admin_collapse}>
                    <div className='content-collapsee' onClick={()=>this.gantiPassword()}>
                        <div className='list-collapse'>
                            <a>Ganti Password</a>
                        </div>
                    </div>
                    <div className='content-collapsee' onClick={()=>this.props.logout()}>
                        <div className='list-collapse'>
                            <a>Logout</a>
                        </div>
                    </div>
                    
                </Collapse>
                <div className='hr'/>
                <div onClick={()=>window.location.assign('/dashboard')} className={`list-sidebar ${this.toggleActive('/dashboard')?'is-active-menu':''}`}>
                    <img src={this.toggleActive('/dashboard')?Dashboard2:Dashboard1} className='icon-size'/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <a >Beranda</a>
                </div>
                {roleId===1&&
                <div onClick={()=>window.location.assign('/proyek')} className={`list-sidebar ${this.toggleActive('/proyek')?'is-active-menu':''}`}>
                    <img src={this.toggleActive('/proyek')?Folders2:Folders3} className='icon-size'/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <a >Proyek</a>
                </div>}
                {roleId===1&&
                <div onClick={()=>window.location.assign('/peserta')} className={`list-sidebar ${this.toggleActive('/peserta')?'is-active-menu':''}`}>
                    <img src={this.toggleActive('/peserta')?Users1:Users2} className='icon-size'/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <a >Manajemen peserta</a>
                </div>}
                {roleId===3&&
                <div onClick={()=>window.location.assign('/client')} className={`list-sidebar ${this.toggleActive('/client')?'is-active-menu':''}`}>
                    <img src={this.toggleActive('/client')?Users1:Users2} className='icon-size'/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <a >Manajemen client</a>
                </div>}
                
            </div>
        )
    }
}
const mapStateToProps=(state)=>({

})
const mapDispatchToProps={
    logout,modalToggle
}
export default withRouter(connect(mapStateToProps,mapDispatchToProps)(index));