import React,{useState} from 'react'
import DemografiContainer from 'components/oascomponent/DemografiContainer'
import TextField from 'components/TextField'
import InputMask from 'react-input-mask'
import {RadioGroup ,Radio,FormControlLabel,FormControl,InputLabel,OutlinedInput,Select,MenuItem,} from '@material-ui/core'
import {makeStyles } from '@material-ui/core/styles'
import { useDispatch,useSelector } from 'react-redux'

const useStyles = makeStyles(theme => ({
    textField: {
      [`& fieldset`]: {
        borderRadius: 10,
      },
      width:'100%',
      marginBottom:15,
  },
 
  
}));
export default function Demografi(props) {
    const classes=useStyles()
    const dispatch = useDispatch()
    const oas = useSelector(state => state.oas)
    const [field, setfield] = useState('')
    const [isOther,setIsOther]=useState(false)

    let {jawaban,detail_survey}=oas
    const onChangeDemografi=(i,value,type)=>{
        setfield(value)
        if(type!=='single'){
            jawaban[props.active_tab][i].answerText=value
        }
        if(type==='single'){
            jawaban[props.active_tab][i].answerText=''
            jawaban[props.active_tab][i].ratingId=parseInt(value)

        }
        if(type==='dropdown'){
            jawaban[props.active_tab][i].answerText=''
            jawaban[props.active_tab][i].ratingId=parseInt(value)
        }
    }
    const onChangeOther=(index,value,type,name)=>{
        setfield(value)
        if(type==='singleother'){
            if(name==='Lainnya'||name==='Other'){
                setIsOther({...isOther,[index]:true})
                jawaban[props.active_tab][index].answerText=''
    
                jawaban[props.active_tab][index].ratingId=parseInt(value)
            }else{
    
                setIsOther({...isOther,[index]:false})

                jawaban[props.active_tab][index].answerText=value
                jawaban[props.active_tab][index].ratingId=0
    
            }
        }else if(type==='otherValue'){
            jawaban[props.active_tab][index].answerText=value

        }
        
    }
    const renderOtherDisable=(answer,id)=>{
        if(answer===id){
            return false
        }else{

            return true
        }
    }
    const handleDisabled=()=>{
        var re = /\S+@\S+\.\S+/;
        let status=[]
        jawaban[props.active_tab].map((d,i)=>{
            if(d.type==='textfield'){
                status.push(d.answerText==='')
            }
            if(d.type==='email'){
                status.push(!re.test(d.answerText))
            }
            if(d.type==='single'||d.type==='dropdown'){
                status.push(d.ratingId===0)
            }
            if(d.type==='singleother'){
                if(isOther[i]){
                    status.push(d.ratingId===0||d.answerText==='')
                }else{
                    status.push(d.answerText==='')
                }
            }
        })
        let disable=status.includes(true)
        return disable
    }
    // console.log(`props.content`, jawaban[props.active_tab])
    return (
        <DemografiContainer handleDisabled={handleDisabled} ln="id" btnText="Mulai Survey" clickAgree={()=>props.tabToggle(props.active_tab+1)} welcomeMessage="Anda berikut merupakan halaman survey untuk Strategy and Performance Execution Excellence® Audit">
            <h2 style={{margin:0,marginBottom:20,color:'#333333'}}>Data Responden</h2>
                {props.content.items.map((d,i)=>{
                    if(d.itemType==='textfield'||d.itemType==='email'){
                        return(
                            <TextField
                                label={d.text.id}
                                variant='outlined'
                                value={jawaban[props.active_tab][i].answerText}
                                size='small'
                                style={{marginBottom:20}}
                                onChange={(e)=>onChangeDemografi(i,e.target.value,d.itemType)}
                            />
                        )
                    }
                    if(d.itemType==='phone'){
                        return(
                            <InputMask
                                maskChar={null}
                                mask="9999-9999-9999-9999"
                                value={jawaban[props.active_tab][i].answerText}
                                onChange={(e)=>onChangeDemografi(i,e.target.value,d.itemType)}

                            >
                            {() =>
                                <TextField
                                    label={d.text.id}
                                    color='primary'
                                    variant='outlined'
                                    size='small'
                                    style={{marginBottom:20}}
                                />
                            }
                            </InputMask>
                        )
                    }
                    if(d.itemType==='single'){
                        return(
                            <div key={i} >
                                <p style={{color:'#808080',fontSize:12,fontWeight:'bold'}}>{d.text.id} </p>
                                <RadioGroup value={jawaban[props.active_tab][i].ratingId} onChange={(e)=>onChangeDemografi(i,e.target.value,d.itemType)}   style={{marginBottom:8}} aria-label="gender" name="gender1"  >
                                    <div className='lps-flex'>
                                        {d.options.map((dat,i)=>(
                                            <>
                                            <FormControlLabel key={i} value={dat.id} control={<Radio color='primary'/>} label={<p style={{fontSize:12,color:'#777777',fontWeight:'bold'}}>{dat.text.id}</p>} />
                                            
                                            </>
                                        ))}
                                    
                                    </div>
                                </RadioGroup>
                            </div>
                        )
                    }
                    if(d.itemType==='dropdown'){
                        return(
                            <div className='tdra-field'>
                                <FormControl  variant="outlined" size="small" style={{marginTop:15}} className='add-proposal__field' >
                                    <InputLabel  htmlFor="category">{d.text.id}</InputLabel>
                                    <Select onChange={(e)=>onChangeDemografi(i,e.target.value,d.itemType)} value={parseInt(jawaban[props.active_tab][i].ratingId)}  className={classes.textField}   color='primary' name='segmentId'   labelId="label" id="select"  labelWidth={110} style={{borderRadius:10}}>
                                    {d.options.map((opt,i)=>(
                                        <MenuItem key={i}  value={opt.id}>{opt.text.id}</MenuItem>

                                    ))}
                                    </Select>
                                </FormControl>
                            </div>
                        )
                    }
                    if(d.itemType==='singleother'){
                        return(
                            <div key={i}>
                                <p style={{color:'#808080',fontSize:12,fontWeight:'bold'}}>{d.text.id}</p>
                                    <RadioGroup value={isOther[i]?jawaban[props.active_tab][i].ratingId:parseInt(jawaban[props.active_tab][i].answerText)}  aria-label="gender" name="gender1"  onChange={(e)=>onChangeOther(i,e.target.value,d.itemType,e.target.name)}>
                                    {/* <div className='form-demografi ' > */}
                                        <div className='lps-flex'>
                                            {d.options.map((dat,i2)=>(
                                                <div>
                                                <FormControlLabel 
                                                    key={i2}  
                                                    className='radio-height'  
                                                    value={dat.id} 
                                                    name={dat.text.id} 
                                                    control={<Radio color='primary' size='small'/>} 
                                                    label={<p style={{fontSize:12,color:'#777777',fontWeight:'bold'}}>{dat.text.id}</p>} 
                                                />
                                                {dat.text.en==='Other'&&
                                                    <div className='form-80'>
                                                        <TextField
                                                            color='primary'
                                                            variant='outlined'
                                                            size='small'
                                                            name='name'
                                                            className={classes.textField}
                                                            onChange={(e)=>onChangeOther(i,e.target.value,'otherValue','')}
                                                            value={isOther[i]?jawaban[props.active_tab][i].answerText:''}
                                                            disabled={renderOtherDisable(jawaban[props.active_tab][i].ratingId,dat.id)}
            
                                                        />
                                                    </div>
                                                }
                                                </div>
                                            ))}
                                        {/* </div> */}
                                        
                                    </div>
                                </RadioGroup>
                                <br/>
                            </div>
                        )
                    }
                })}
        </DemografiContainer>
    )
}
