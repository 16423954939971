import React,{useRef,useState,useEffect} from 'react'
import {Button,TextField} from '@material-ui/core'
import { MuiThemeProvider, createMuiTheme,withStyles, makeStyles} from '@material-ui/core/styles'
import AutoCompleteSelect from 'components/Select'
import moment from 'moment'
import Picker from 'react-month-picker'
import 'react-month-picker/css/month-picker.css';
import {useSelector,useDispatch} from 'react-redux'
import {setFilterSaProject,getSaListProject,setUseFilter} from 'redux/actions/proyek'
const themeButton = createMuiTheme({ 
    palette: { 
        primary: {
            main:'#00a1ed',
            contrastText: '#FFFFFF',

        },
        secondary: {
            main:'#ffc466',
            contrastText: '#FFFFFF',
        }
    } 
})
const useStyles = makeStyles(theme => ({
    textField: {
        [`& fieldset`]: {
            borderRadius: 8,
          },
          width:'100%',
          marginBottom:15
  },
  textField2: {
    [`& fieldset`]: {
      borderRadius: 10,
    },
    width:'100%',
    marginBottom:10
}
}));
const MonthBox=(props)=>{
    const classes=useStyles()
    const _handleClick=(e)=> {
        props.onClick && props.onClick(e);
    }
    let {textPeriode}=props
    return(
        <TextField
            label={<>Tanggal Mulai</>}
            variant='outlined'
            value={textPeriode}
            size='small'
            className={classes.textField}
            onFocus={()=>_handleClick()}
        />
    )
}
const pickerLang = {
    months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    from: 'From', to: 'To',
}
export default function Filter_project(props) {
    const dispatch=useDispatch()
    const pickRange=useRef(null)
    const classes=useStyles()
    const [filter,setFilter]=useState({
        textPeriode:'All periode',
        rangeValue:{from: {year: parseInt(moment().format('YYYY')), month:parseInt(moment().format('M')) }, to: {year: parseInt(moment().format('YYYY')), month: parseInt(moment().format('M'))}}
    })
    useEffect(() => {
        let {modal_data}=props
        setFilter(modal_data)
    }, [])
    const max=moment().add(10,'year').format('YYYY')
    const min=moment().subtract(10,'year').format('YYYY')
    let {from,to}=filter.rangeValue
    let renderFrom=`${from.year}${from.month<10?`0${from.month}`:from.month}`
    let renderTo=`${to.year}${to.month<10?`0${to.month}`:to.month}`
    const _handleClickRangeBox=(e)=>{
        pickRange.current.show()
    }
    const handleRangeDissmis=(value)=>{
        setFilter({
            ...filter,
            rangeValue:value,
            textPeriode:pickerLang.months[value.from.month-1]+' '+ value.from.year +' - '+pickerLang.months[value.to.month-1]+' '+ value.to.year 
        })
    }
    const onClickSimpan=async ()=>{
        dispatch(setFilterSaProject({textPeriode:filter.textPeriode}))
        dispatch(setFilterSaProject({rangeValue:filter.rangeValue}))
        await dispatch(getSaListProject(`/${renderFrom}/${renderTo}/1/10/*`))
        dispatch(setUseFilter(true))
        props.modalToggleReset()

    }
    return (
        <div>
            <Picker
                ref={pickRange}
                years={{min: {year:parseInt(min),month:12},max:{year:parseInt(max),month:12}}}
                value={filter.rangeValue}
                lang={pickerLang}
                theme="light"
                // onChange={handleRangeChange}
                onDismiss={handleRangeDissmis}
            >
                
            </Picker>
           {/* <div style={{width:'100%'}}>
                <AutoCompleteSelect
                    // onChange={(event,value)=>dispatch(setProyek({jenis_test:value}))}
                    options={[]}
                    // value={proyek_detail.jenis_test}
                    getOptionLabel={(option) => option.text}
                    label={<>Jenis test</>}
                    multiple
                    // disabled={handleDisable()}
                />
            </div> */}
            
            <MonthBox
                textPeriode={filter.textPeriode} 
                value={filter.rangeValue} 
                onClick={_handleClickRangeBox} 
            />
            <div style={{textAlign:'right'}}>
                <MuiThemeProvider theme={themeButton}>
                    <Button size="small" onClick={onClickSimpan} color="primary" variant="contained" className='btn-remove-capital btn-rounded' >Save</Button>
                </MuiThemeProvider>
            </div>
        </div>
    )
}
