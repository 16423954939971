import React, { Component } from 'react'
import '../style.css'
import { MuiThemeProvider, createMuiTheme,withStyles, } from '@material-ui/core/styles'
import {Button } from '@material-ui/core'
import {connect} from 'react-redux'
import {deletePeserta,getListUser} from 'redux/actions/peserta'
import {deleteClient} from 'redux/actions/client'
import {deletePesertaProyek,getListUserProject} from 'redux/actions/proyek'
import {modalToggleReset} from 'redux/actions/general'
import {tabToggle} from 'redux/actions/master'
const themeButton = createMuiTheme({ 
    palette: { 
        primary: {
            main:'#ffc466',
        },
        secondary: {
            main:'#ff6e79',
            contrastText: '#FFFFFF',
        }
    } 
})
class confirm_delete extends Component {
    
    deletePeserta=async ()=>{
        let {modal_data,modal_action,profile,token}=this.props

        await this.props.deletePeserta(modal_data.id)
        this.props.getListUser('/0/2/0/1/10/*')
        this.props.tabToggle('list_peserta','form_peserta')
    }
    deletePesertaFromProyek=async ()=>{
        let {modal_data,modal_action,profile,token}=this.props
        
        await this.props.deletePesertaProyek(`/${modal_data.userProjectId}/${modal_data.clientAdminId}`)
        this.props.getListUserProject(`/${modal_data.projectId}/2/0/0/1/100/*`)
    }
    deleteClient=async ()=>{
        let {modal_data}=this.props
        await this.props.deleteClient(`/${modal_data.clientId}/${modal_data.userId}`)
    }
    renderAction=()=>{
        let {modal_data,modal_action,profile,token}=this.props
        switch (modal_action) {
            case 'delete_peserta':
                return this.deletePeserta()
            case 'delete_peserta_proyek':
                return this.deletePesertaFromProyek()
            case 'delete_admin':
                return modal_data.action()
            case 'delete_client':
                return modal_data.action()
            default:
                return modal_data.action()
                break;
        }
        // case
    }
    render() {
        // console.log('this.props.modal_data', this.props.modal_data)
        let {modal_data,modal_title}=this.props
        return (
            <div className='confirm-container'>
                <h4>Delete {modal_data.title}</h4>
                <div dangerouslySetInnerHTML={{ __html: modal_data.msg }}></div>
                {/* <p>Are you sure delete <b>{modal_data.title}</b></p> */}
                <div className='card-footer'>
                    <MuiThemeProvider theme={themeButton}>
                        <Button onClick={()=>this.props.modalToggleReset()}   size='small' color='primary' className='btn-remove-capital'>Tidak, batalkan</Button>
                        <Button onClick={()=>this.renderAction()}   size='small' color='secondary' variant='contained' className='btn-remove-capital btn-rounded '>Ya, hapus</Button>
                    </MuiThemeProvider>
                </div>
            </div>
        )
    }
}
const mapStateToProps=(state)=>({
    general:state.general,
    files:state.files,
})
const mapDispatchToProps={
    modalToggleReset,
    deletePeserta,
    tabToggle,
    getListUser,
    deletePesertaProyek,
    getListUserProject,
    deleteClient
}
export default connect(mapStateToProps,mapDispatchToProps)(confirm_delete)