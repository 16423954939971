import {apiCall} from 'service/apiCall'
import {setLoading,setError,modalToggle} from './general'
import Cookie from 'universal-cookie'
import {get} from 'lodash'
import { URL, USER,PASS } from 'service/base_url'
import * as actionTypes from 'redux/constants/assestment'
import {tabToggle} from './master'
const cookie=new Cookie
const token=cookie.get('login_cookie')
const profile=cookie.get('profile_cookie')
export const setPapiActiveSub =(payload)=>dispatch=>{
    dispatch({
        type:actionTypes.SET_PAPI_ACTIVE_SUB,
        payload
    })
}
export const setCfitActiveSub =(payload)=>dispatch=>{
    dispatch({
        type:actionTypes.SET_CFIT_ACTIVE_SUB,
        payload
    })
}
export const setActiveSub =(payload)=>dispatch=>{
    dispatch({
        type:actionTypes.SET_ACTIVE_SUB,
        payload
    })
}
export const setActivePart =(payload)=>dispatch=>{
    dispatch({
        type:actionTypes.SET_ACTIVE_PART,
        payload
    })
}
export const resetAssestmentReducer=()=>dispatch=>{
    dispatch({
        type:actionTypes.RESET_ALL
    })
}
export const setCountFoto=(data)=>dispatch=>{
    dispatch({
        type:actionTypes.SET_COUNT_FOTO,
        payload:data
    })
}
export const setQuestion=(data)=>dispatch=>{
    dispatch({
        type:actionTypes.SET_QUESTION,
        payload:data
    })
}
export const setRecord=(data)=>dispatch=>{
    dispatch({
        type:actionTypes.SET_RECORD,
        payload:data
    })
}
export const setUpdateBio=(data)=>dispatch=>{
    dispatch({
        type:actionTypes.SET_UPDATE_BIO,
        payload:data
    })
}
export const setCfit=(data)=>dispatch=>{
    dispatch({
        type:actionTypes.SET_CFIT,
        payload:data
    })
}
export const setPapi=(data)=>dispatch=>{
    dispatch({
        type:actionTypes.SET_PAPI,
        payload:data
    })
}
export const resetQuestion=(data)=>dispatch=>{
    dispatch({
        type:actionTypes.RESET_QUESTION,
    })
}
export const resetPapi=(data)=>dispatch=>{
    dispatch({
        type:actionTypes.RESET_PAPI,
    })
}
export const resetCfit=(data)=>dispatch=>{
    dispatch({
        type:actionTypes.RESET_CFIT,
    })
}
export const resetToefl=(data)=>dispatch=>{
    dispatch({
        type:actionTypes.RESET_TOEFL,
    })
}
export const setToefl=(data)=>dispatch=>{
    dispatch({
        type:actionTypes.SET_TOEFL,
        payload:data
    })
}

export const setCfitTime=(data)=>dispatch=>{
    dispatch({
        type:actionTypes.SET_CFIT_TIME,
        payload:data
    })
}
export const setDiscTime=(data)=>dispatch=>{
    dispatch({
        type:actionTypes.SET_DISC_TIME,
        payload:data
    })
}
export const setPapiTime=(data)=>dispatch=>{
    dispatch({
        type:actionTypes.SET_PAPI_TIME,
        payload:data
    })
}
export const setToeflTime=(data)=>dispatch=>{
    dispatch({
        type:actionTypes.SET_TOEFL_TIME,
        payload:data
    })
}
export const postJawaban=(data)=>async dispatch=>{
    dispatch(setLoading(true))
        let dataReq={
            url:'/disc/user/answer',
            method:'POST',
            data:{data:data,headers:{ 'Authorization': `Bearer ${token}`},}
        }
        let res=await dispatch(apiCall(dataReq))
        if(get(res,'status')===200){
            await dispatch(resetQuestion())
            dispatch(setLoading(false))
            window.location.assign('/portal')
            // dispatch(tabToggle('done','assestment'))
        }else{
            dispatch(setLoading(false))
            // dispatch(setError('The Email or password is incorrect. Please try again '))
        }
}

export const postCfit=(data)=>async dispatch=>{
    dispatch(setLoading(true))
        let dataReq={
            url:'/cfit/answer',
            method:'POST',
            data:{data:data,headers:{ 'Authorization': `Bearer ${token}`},}
        }
        let res=await dispatch(apiCall(dataReq))
        if(get(res,'status')===200){
            await dispatch(resetCfit())
            dispatch(setLoading(false))

            window.location.assign('/portal')
            // dispatch(tabToggle('done','assestment'))
        }else{
            dispatch(setLoading(false))
            // dispatch(setError('The Email or password is incorrect. Please try again '))
        }
}
export const postPapi=(data)=>async dispatch=>{
    dispatch(setLoading(true))
        let dataReq={
            url:'/mpt/answer',
            method:'POST',
            data:{data:data,headers:{ 'Authorization': `Bearer ${token}`},}
        }
        let res=await dispatch(apiCall(dataReq))
        if(get(res,'status')===200){
            await dispatch(resetPapi())
            dispatch(setLoading(false))

            window.location .assign('/portal')
            // dispatch(tabToggle('done','assestment'))
        }else{
            dispatch(setLoading(false))
            // dispatch(setError('The Email or password is incorrect. Please try again '))
        }
}
export const postToefl=(data)=>async dispatch=>{
    dispatch(setLoading(true))
        let dataReq={
            url:'/toefl/answer',
            method:'POST',
            data:{data:data,headers:{ 'Authorization': `Bearer ${token}`},}
        }
        let res=await dispatch(apiCall(dataReq))
        if(get(res,'status')===200){
            await dispatch(resetToefl())
            dispatch(setLoading(false))

            window.location.assign('/portal')
            // dispatch(tabToggle('done','assestment'))
        }else{
            dispatch(setLoading(false))
            // dispatch(setError('The Email or password is incorrect. Please try again '))
        }
}
export const postInfoPeserta=(data)=>async dispatch=>{
    dispatch(setLoading(true))
        let dataReq={
            url:'/disc/user/info',
            method:'POST',
            data:{data:data,headers:{ 'Authorization': `Bearer ${token}`},}
        }
        let res=await dispatch(apiCall(dataReq))
        if(get(res,'status')===200){
            dispatch(setLoading(false))
            return res
        }else{
            dispatch(setLoading(false))
            // dispatch(setError('The Email or password is incorrect. Please try again '))
        }
}
export const postFoto=(data,count_foto)=>async dispatch=>{
    // dispatch(setLoading(true))
        let dataReq={
            url:'/assess/photo',
            method:'POST',
            data:{data:data,headers:{ 'Authorization': `Bearer ${token}`},}
        }
        let res=await dispatch(apiCall(dataReq))
        if(get(res,'status')===200){

            return res
            
        }
}