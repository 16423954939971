import React ,{useState}from 'react'
import './style.css'
import {useSelector,useDispatch} from 'react-redux'
import Layout from 'components/Layouts'
import {tabToggle} from 'redux/actions/master'
import ListPeserta from './list_peserta'
import FormPeserta from './form_peserta'
import DetailLog from './detail_log'
export default function Index(props) {
    const dispatch=useDispatch()
    const master = useSelector(state => state.master)
    const [tab_action,setTabAction]=useState('list_peserta')
    const {tab_active,tab_back}=master
    const tabsToggle=(key,back)=>{
        dispatch(tabToggle(key,back))
    }
    return (
        <Layout>
            {tab_active==='list_peserta'&&<ListPeserta tab_active={tab_active} tab_back={tab_back} tab_action={tab_action} setTabAction={setTabAction} profile={props.profile} token={props.token} tabsToggle={tabsToggle}/>}
            {tab_active==='form_peserta'&&<FormPeserta tab_active={tab_active} tab_back={tab_back} tab_action={tab_action} setTabAction={setTabAction} profile={props.profile} token={props.token} tabsToggle={tabsToggle}/>}
            {tab_active==='detail_log'&&<DetailLog tab_active={tab_active} tab_back={tab_back} tab_action={tab_action} setTabAction={setTabAction} profile={props.profile} token={props.token} tabsToggle={tabsToggle}/>}
        </Layout>
    )
}
