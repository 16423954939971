import React,{useState,useEffect} from 'react'
import './style.css'
import {Button,FormControl,TextField,InputLabel,OutlinedInput,InputAdornment
    ,Table,TableHead,TableCell,TableRow,TableBody} from '@material-ui/core'
    import CevronRight from 'assets/icon/chevron-right.svg'
import CevronLeft from 'assets/icon/chevron-left.svg'
import Skeleton from 'components/Skeleton'
import { TablePagination } from '@trendmicro/react-paginations';
import '@trendmicro/react-paginations/dist/react-paginations.css';
import {debounce} from 'lodash'
import SearchImg from 'assets/icon/Search.png'
import Eye from 'assets/icon/eye.svg'
import Edit from 'assets/icon/edit.svg'
import {useSelector,useDispatch} from 'react-redux'
import {resetProyek,getProject,getDetailProjectProgress,getDetailProject,getListUserProject,setProyek,getListTest,getEmailTemplate} from 'redux/actions/proyek'
import moment from 'moment'
import {modalToggle} from 'redux/actions/general'
import { MuiThemeProvider, createMuiTheme,withStyles,makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    search: {
        [`& fieldset`]: {
            borderRadius: 8,
            height:30,
            margin: theme.spacing(1),
          },
          width:200,
          height:10,
          marginBottom:40,
  },
  input:{
    fontSize:14,
    margin:3      
  }
  
}));
export default function Index(props) {
    const classes=useStyles()

    const dispatch=useDispatch()
    const proyek = useSelector(state => state.proyek)
    const general = useSelector(state => state.general)
    const [pagination,setPagination]=useState({
        page:1,
        perPage:10
    })
    const [search,setSearch]=useState('')
    const [tooltip,setTooltip]=useState('')
    const {proyek_list,proyek_pagination}=proyek
    useEffect(() => {
        // if(proyek_list.length<1){
            dispatch(getProject(`/${pagination.page}/${pagination.perPage}/${search!==''?search:'*'}`))
        // }
    }, [])
    
    const togglePagination=(page,pageLength)=>{
        dispatch(getProject(`/${page}/${pageLength}/${search!==''?search:'*'}`))
    }
    const searchToggle=debounce(async (value)=>{
        setSearch(value)
        if(value!==search&&value.length>0){
            await dispatch(getProject(`/${1}/${10}/${value!==''?value:'*'}`))
            
        }else{
            await dispatch(getProject(`/${1}/${10}/*`))
        }
        
    },1000)
    const addProyek=async ()=>{
        await dispatch(resetProyek())
        let res=await dispatch(getListTest())
        if(res){
            props.tabsToggle('form_proyek','list_proyek')
            props.setTabAction('add_proyek')
        }
        
    }
    const detailProyek=async (data)=>{
        let res1=await dispatch(getDetailProjectProgress(data.id))
        let res2=await dispatch(getDetailProject(data.id))
        
        let res3=await dispatch(getListUserProject(`/${data.id}/2/0/0/1/100/*`))
        let res4=await dispatch(getListTest())

        if(res4){
            props.tabsToggle('form_proyek','list_proyek')
            props.setTabAction('detail_proyek')
        }
    }
    const editProyek=async (data)=>{
        let res1=await dispatch(getDetailProjectProgress(data.id))
        let res2=await dispatch(getDetailProject(data.id))
        let res3=await dispatch(getListUserProject(`/${data.id}/2/0/0/1/100/*`))
        let res4=await dispatch(getListTest())

        if(res4){
            props.tabsToggle('form_proyek','list_proyek')
            props.setTabAction('edit_proyek')
        }
    }
    const assignPeserta=async (id)=>{
        let res=await dispatch(getEmailTemplate(`/${id}/${props.profile.id}`))
        if(res){
            dispatch(modalToggle({
                modal_open: true,
                modal_title: `Template Email`,
                modal_component: "email_template",
                modal_size:1200,
                modal_data:null,
                modal_action:'email_template'
            }))
        }
        dispatch(setProyek({projectId:id}))
    }
    // console.log('proyek_list', proyek_list)
    return (
        <div>
            <div className='head-section'>
                    <div>
                        <h4 style={{marginBottom:0,marginTop:0}}>Proyek</h4>
                    </div>
                    <Button onClick={addProyek} color='primary' className='btn-rounded btn-remove-capital btn-action' variant="contained">Tambah Proyek</Button>

                </div>
                <div className='card-content'>
                <div className='card-table'>
                    <div className='card-table__head'>
                        <TextField
                            placeholder="Cari"
                            variant='outlined'
                            size='small'
                            inputProps={{
                                className:classes.input
                            }}
                            InputLabelProps={{shrink: false}}
                            className={classes.search}
                            onChange={(e)=>searchToggle(e.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                    <img alt="search" src={SearchImg} style={{width:15}}/>
                                </InputAdornment>
                                ),
                              }}
                        />
                    </div>
                    <div className='card-table__content'>
                    <Table  size="small" aria-label="a dense table">
                        <TableHead>
                        <TableRow>
                            <TableCell align="left" style={{width:10}}>No.</TableCell>
                            <TableCell align="left" style={{width:90}}>Nama Proyek</TableCell>
                            <TableCell align="left">Tanggal</TableCell>
                            <TableCell align="left">Periode</TableCell>
                            <TableCell align="left" style={{width:50}}>Pembuat</TableCell>
                            <TableCell align="left" >Progress pengerjaan</TableCell>
                            <TableCell align="center">Aksi</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                            {general.isLoadingTable?
                                <Skeleton count={7}/>
                            :
                            proyek_list.length<1?
                            <TableRow>
                                <TableCell colSpan={7} style={{textAlign:'center'}}>belum ada proyek survey di bulan {moment().format('MMMM')}, <Button onClick={addProyek} className='btn-remove-capital' variant="text" color="primary">Tambah Proyek</Button></TableCell>
                            </TableRow>
                            :proyek_list.map((data,i)=>(
                                <TableRow key={i}>
                                    <TableCell >{i+1}</TableCell>
                                    <TableCell >{data.name}</TableCell>
                                    <TableCell>{moment(data.startDate).format('DD MMM YYYY')}</TableCell>
                                    <TableCell>{moment(data.endDate).format('DD MMM YYYY')}</TableCell>
                                    <TableCell>{data.createdBy}</TableCell>
                                    <TableCell style={{position:'relative'}}>
                                    {tooltip[i]&&<div className='tooltip-progress'>
                                        <p>Peserta<br/><span style={{color:'#CCCCCC',fontSize:12}}>{data.completed}/{data.assigned}</span></p>
                                    </div>}
                                    <progress onMouseEnter={()=>setTooltip({[i]:true})} onMouseLeave={()=>setTooltip({[i]:false})} className='bar-desktop' max={data.assigned} value={data.completed} data-label={`${data.completed===0?0:((data.completed/data.assigned)*100).toFixed(0)}%`}>
                                    </progress>
                                    </TableCell>
                                    <TableCell align="center">
                                        <div style={{display:'flex',alignItems:'center'}}>
                                        <img src={Eye} onClick={()=>detailProyek(data)} className='icon-action'/>
                                        {((data.completed/data.assigned)*100).toFixed(0)!==100&&<img src={Edit} onClick={()=>editProyek(data)} className='icon-action'/>}
                                        {((data.completed/data.assigned)*100).toFixed(0)!==100&&moment().isSameOrBefore(moment(data.endDate),'day')&&<Button onClick={()=>assignPeserta(data.id)} size="small" className='btn-remove-capital' variant="text" color="primary">Assign</Button>}
                                        </div>
                                    </TableCell>

                                </TableRow>
                            ))
                            
                            }
                        </TableBody>
                    </Table>
                    <div className='card-table__pagination'>
                    {proyek_pagination!==null&&
                    <TablePagination
                            className="card-pagination"
                            type="reduced"
                            page={proyek_pagination.page}
                            pageLength={proyek_pagination.perPage}
                            totalRecords={proyek_pagination.total}
                            totalRecords={proyek_pagination.total}
                            onPageChange={({ page, pageLength }) => {
                                togglePagination(page,pageLength)
                            }}
                            prevPageRenderer={() => <img src={CevronLeft} style={{width:10}}/>}
                            nextPageRenderer={() => <img src={CevronRight}/>}
                        />
                    }
                </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
