import React,{useState} from 'react'
import Welcome from './welcome'
import Demografi from './demografi'
import Survey from './survey'
import Result from './result'
import '../style.css'
import { useSelector,useDispatch } from 'react-redux'
export default function Index() {
    const dispatch = useDispatch()
    const oas = useSelector(state => state.oas)
    const [active_tab, setactive_tab] = useState(0)
    const [status, setstatus] = useState('process')
    let {detail_survey,result_survey}=oas
    const tabToggle=(num)=>{
        setactive_tab(num)
    }
    const statusToggle=(stat)=>{
        setstatus(stat)
    }
    
    let total= detail_survey?.pages.filter((d)=>{
        return d.itemType==='single'
    }).length
    // console.log(`total`, total)
    // console.log(`detail_survey`, oas.jawaban)
    return (
        <div>
            {status!=='result'?
                <>
                    {detail_survey.pages[active_tab].itemType===""&&
                        <Welcome 
                            active_tab={active_tab} 
                            tabToggle={tabToggle}
                            content={detail_survey.pages[active_tab]}
                        />
                    }
                    {detail_survey.pages[active_tab].itemType==="single"&&
                        <Survey 
                            active_tab={active_tab} 
                            tabToggle={tabToggle}
                            content={detail_survey.pages[active_tab]}
                            totalQuestion={total}
                            nameSurvey="Strategy and Performance Execution Excellence® Audit"
                        />
                    }
                    {detail_survey.pages[active_tab].itemType==="varied"&&
                        <Demografi 
                            active_tab={active_tab} 
                            tabToggle={tabToggle}
                            content={detail_survey.pages[active_tab]}
                            totalQuestion={total}
                            nameSurvey="Strategy and Performance Execution Excellence® Audit"
                            statusToggle={statusToggle}
                        />
                    }
                    
                </>
            :
                <Result 
                    result_survey={result_survey}
                />
            }
        </div>
    )
}
