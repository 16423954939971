import React from 'react'
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'ckeditor5-build-classic-with-upload-image'
import {URL as newUrl}  from 'service/base_url'
import Cookie from 'universal-cookie'
import {Button,TextField} from '@material-ui/core'
import {useDispatch,useSelector} from 'react-redux'
import {modalToggle} from 'redux/actions/general'
import {setEmailTemplate,setEmailBulkIndividual,postEmailTemplate,postEmailBulk} from 'redux/actions/proyek'
import { MuiThemeProvider, createMuiTheme,withStyles,makeStyles } from '@material-ui/core/styles'
import ChipInput from 'material-ui-chip-input'

const cookie=new Cookie()
const token=cookie.get('login_cookie')
ClassicEditor.defaultConfig = {
	toolbar: { 
		items: [
			'heading',
			'|',
			'bold', 
			'italic',
			'alignment',
			'link',
			'bulletedList',
			'numberedList',
			'|',
			'indent',
			'outdent',
			'|',
			'imageUpload',
			'blockQuote',
			'insertTable',
			'undo',
			'redo'
		]
	},
	alignment: {
		options: [ 'left', 'right','center' ]
	},
	image: {
		toolbar: [
			'imageStyle:full',
			'imageStyle:side',
			'imageStyle:alignLeft',
			'imageStyle:alignCenter',
			'imageStyle:alignRight',
			'|',
			'imageTextAlternative'
		],
		styles:[
			'full',
			'side',
			'alignLeft',
			'alignCenter',
			'alignRight'
		]
	},
	table: {
		contentToolbar: [
			'tableColumn',
			'tableRow',
			'mergeTableCells'
		]
	},
	// This value must be kept in sync with the language defined in webpack.config.js.
	language: 'en',
	simpleUpload: {
		// The URL that the images are uploaded to.
		uploadUrl: `${newUrl}/upload/ckeditor`,

		// Headers sent along with the XMLHttpRequest to the upload server.
		headers: {
			'X-CSRF-TOKEN': 'CSFR-Token',
			Authorization: `Bearer ${token}`
		}
    }
    
   
};
const useStyles = makeStyles(theme => ({
    textField: {
        [`& fieldset`]: {
            borderRadius: 8,
          },
          width:'100%',
          marginBottom:15
  },
  textField2: {
    [`& fieldset`]: {
      borderRadius: 10,
    },
    width:'100%',
    marginBottom:10
}
}));
export default function Email_template(props) {
	const dispatch = useDispatch()
	const proyek = useSelector(state => state.proyek)
	let {email_bulk,proyek_detail,email_bulk_individual}=proyek
    const classes=useStyles()

	const onClickSimpan=async ()=>{
		let data={
			projectId:proyek_detail.projectId,
			subject:email_bulk_individual.subject,
			email:email_bulk_individual.email,
			userId:props.profile.id,
			userIds:[props.modal_data.userIds],
			cc:email_bulk_individual.cc,
			to:props.modal_data.email
		}
		dispatch(postEmailBulk(data))
		
	}
	const deleteCC=(chip,index)=>{
		let filter=email_bulk_individual.cc.filter((data)=>{
            return data!==chip
        })
        dispatch(setEmailBulkIndividual({cc:[...filter]}))
	}
	const onAdd=(chip)=>{
		var re = /\S+@\S+\.\S+/;
		if(re.test(chip)){
			dispatch(setEmailBulkIndividual({cc:[...email_bulk_individual.cc,chip]}))

		}
	}
    return (
        <div style={{maxHeight:500,overflowY:'scroll',overflowX:'hidden'}}>
            <div className='div-flex'>
                <div style={{width:'100%'}}>
                <ChipInput
                        size="small"
                        color="primary"
                        label="CC"
                        // onUpdateInput={(e) => dispatch(setEmailBulk({cc:[e.target.value]}))}
                        value={email_bulk_individual.cc}
                        onAdd={(chip) =>onAdd(chip)}
                        // onAdd={(chip) =>console.log('chip', chip)}
                        onDelete={(chip, index) => deleteCC(chip,index)}
                        // variant="outlined"
                        className={classes.textField2}
                        // disabled={handleFieldDisable()}
                    />
				<TextField
					label={<>Subject email</>}
					// variant='outlined'
					value={email_bulk_individual.subject}
					size='small'
					// disabled={handleDisable()}
					className={classes.textField}
					onChange={(e)=>dispatch(setEmailBulkIndividual({subject:e.target.value}))}

				/>
                <CKEditor
                    data={email_bulk_individual.email}
                    editor={ ClassicEditor }
                    onChange={(event,editor)=>{
                        const data=editor.getData()
                        dispatch(setEmailBulkIndividual({email:data}))
                    }}
                    style={{height:50}}
                    // disabled={handleFieldDisable()}
                />
                </div>
            </div>
            <br/>
            <div style={{textAlign:'center'}}>
                <Button onClick={onClickSimpan} color="primary" variant="contained" className='btn-remove-capital btn-rounded' >Kirim</Button>
            </div>
        </div>
    )
}
