import React from 'react'
import WelcomeContainer from 'components/oascomponent/WelcomeContainer'

export default function Welcome(props) {
    return (
        <WelcomeContainer ln="id" btnText="Ikuti Survey" clickAgree={()=>props.tabToggle(props.active_tab+1)} welcomeMessage="Berikut merupakan halaman survey untuk Strategy and Performance Execution Excellence® Audit">
                <h3>Strategy and Performance Execution Excellence® Audit</h3>
                <p>{props.content.intro.id}</p>
                {/* <p >Salah satu ukuran utama bagaimana sebuah organisasi berjalan dengan efektif antara lain dilihat dari kinerja yang dihasilkan. Apakah sesuai dengan target yang telah ditetapkan atau jauh lebih rendah dari yang ditetapkan.  
                <br/><br/>
                Ketika sebuah organisasi dirasa kurang dalam mencapai kinerjanya, maka ada banyak hal didalam organisasi yang perlu untuk dilihat dan dievaluasi dengan tujuan bisa ditingkatkan kinerjanya. 
                <br/><br/>

                Salah satu cara analisa awal yang dapat dilakukan ialah melalui Survey Strategy and Performance Execution Excellence (Spex2). Dimensi yang diukur dalam survey adalah sebagai berikut ini:<br/>
                Formulasi Strategi<br/>
                Pemetaaan Strategi Level Organisasi<br/>
                Penyelarasan Organisasi<br/>
                Eksekusi Operasional<br/>
                Pemantauan dan Penyelarasan Kembali<br/>
                Kepemimpinan dan Infrastruktur<br/>
                Isilah sesuai dengan kondisi perusahaan Anda saat ini.</p>
                <br/> */}
        </WelcomeContainer>
    )
}
