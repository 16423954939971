import React,{useState} from 'react'
import {Button,TextField,FormControl,InputLabel,OutlinedInput,Select,MenuItem,
    FormHelperText,InputAdornment,Table,TableHead,TableCell,TableRow,TableBody,FormControlLabel,Checkbox,makeStyles} from '@material-ui/core'
import AutoCompleteSelect from 'components/Select'
import {modalToggle,modalToggleReset} from 'redux/actions/general'
import {useSelector,useDispatch} from 'react-redux'
import Edit from 'assets/icon/edit.svg'
import Close from 'assets/icon/close.svg'
import SearchImg from 'assets/icon/Search.png'
import {debounce} from 'lodash'
import {setDetailClient,getDetailClient,postClient2,putClient2,deleteAdmin} from 'redux/actions/client'
import {getHistoryCredit} from 'redux/actions/proyek'
import Skeleton from 'components/Skeleton'
import CevronRight from 'assets/icon/chevron-right.svg'
import CevronLeft from 'assets/icon/chevron-left.svg'
import { TablePagination } from '@trendmicro/react-paginations';
import '@trendmicro/react-paginations/dist/react-paginations.css';
import moment from 'moment'

const useStyles = makeStyles(theme => ({
    textField: {
        [`& fieldset`]: {
            borderRadius: 8,
            },
            width:'100%',
            marginBottom:15
    },
    checkbox:{
        margin:0,
        [`& root`]: {
        margin:0,
        },
    },
    search: {
    [`& fieldset`]: {
        borderRadius: 8,
        height:30,
        margin: theme.spacing(1),
        },
        width:150,
        height:10,
        marginBottom:40
    },
    input:{
        fontSize:14,
        margin:3      
        }
}));
export default function Detail(props) {
    const dispatch = useDispatch()
    const [search2, setSearch2] = useState('')
    const client = useSelector(state => state.client)
    const general = useSelector(state => state.general)
    const proyek = useSelector(state => state.proyek)
    let {client_detail,list_industry}=client
    let {history_credit_pagination,filter_history_credit,history_credit,use_filter}=proyek
    let {tab}=props
    let renderFrom2=`${filter_history_credit.rangeValue.from.year}${filter_history_credit.rangeValue.from.month<10?`0${filter_history_credit.rangeValue.from.month}`:filter_history_credit.rangeValue.from.month}`
    let renderTo2=`${filter_history_credit.rangeValue.to.year}${filter_history_credit.rangeValue.to.month<10?`0${filter_history_credit.rangeValue.to.month}`:filter_history_credit.rangeValue.to.month}`
    const classes=useStyles()
    const back=()=>{
        props.tabToggle('list')
    }
    const handleDisable=()=>{
        if(props.tab==='detail'){
            return true
        }else{
            return false
        }
    }
    const handleDisableBtn=()=>{
        if(client_detail.industry!==null){
            return false
        }else{
            return true
        }
    }
    const onClickSimpan=async ()=>{
        let data={
            clientId:client_detail.clientId,
            company:client_detail.company,
            industryId:client_detail.industry.id,
            phone:client_detail.phone,
            address:client_detail.address,
            userId:props.profile.id,
        }
        if(tab==='add'){
            
           let res=await dispatch(postClient2(data))
           await dispatch(getHistoryCredit(`/${res.data.clientId}/0/0/0/1/10/*`))
           if(res){
                dispatch(setDetailClient({clientId:res.data.clientId}))
                props.tabToggle('detail')
           }
        }
        if(tab==='edit'){
            let res=await dispatch(putClient2(data))
            await dispatch(getHistoryCredit(`/${client_detail.clientId}/0/0/0/1/10/*`))
            if(res){
                 props.tabToggle('edit')
            }
        }
        if(tab==='detail'){
            props.tabToggle('edit')
        }
        
    }
    const renderTitle=()=>{
        if(tab==='add'){
            return 'Tambah klien '
        }
        if(tab==='edit'){
            return 'Edit klien '
        }
        if(tab==='detail'){
            return 'Detail klien '
        }
    }
    const addKredit=()=>{
        dispatch(modalToggle({
            modal_open: true,
            modal_title: `Tambah Kredit`,
            modal_component: "add_kredit",
            modal_size:350,
            modal_data:{tab:tab},
            modal_action:'add_kredit'
        }))
    }

    const onAddAdmin=()=>{
        dispatch(modalToggle({
            modal_open: true,
            modal_title: `Tambah Admin`,
            modal_component: "add_admin",
            modal_size:300,
            modal_data:null,
            modal_action:'add_admin'
        }))
    }
    const onEditAdmin=(data,index)=>{
        dispatch(modalToggle({
            modal_open: true,
            modal_title: `Edit Admin`,
            modal_component: "add_admin",
            modal_size:300,
            modal_data:{...data,index:index,tab:tab},
            modal_action:'edit_admin'
        }))
    }
    const deleteFunc=async (data,index)=>{
        await dispatch(deleteAdmin(`/${props.profile.id}/${data.userId}`))
        dispatch(getDetailClient(`/${client_detail.clientId}`))
    }
    const onDeleteAdmin=(data,i)=>{
        dispatch(modalToggle({ 
            modal_open: true,
            modal_title: `detail`,
            modal_component: "confirm_delete",
            modal_size:400,
            modal_type:'confirm',
            modal_data:{
                title:'Admin',
                msg:`<p>Apakah anda yakin menghapus ${data.name} </p>`,
                action:()=>deleteFunc(data,i)
            },
            modal_action:'delete_admin'
        }))
    }

    const filterData=()=>{
        dispatch(modalToggle({
            modal_open: true,
            modal_title: `Filter`,
            modal_component: "filter_history",
            modal_size:300,
            modal_data:{...filter_history_credit,clientId:client_detail.clientId},
            modal_action:'filter_history_from_detail'
        }))
    }
    const historyCreditTogglePagination=(page,pageLength)=>{
        let new_assestment_id=[]
        filter_history_credit.assestmentId.map((data)=>{
            new_assestment_id.push(data.id)
        })
        dispatch(getHistoryCredit(`/${client_detail.clientId}/${new_assestment_id.length>0?new_assestment_id:0}/${use_filter?renderFrom2:0}/${use_filter?renderTo2:0}/${page}/${pageLength}/${search2!==''?search2:'*'}`))
    }
    const historyCreditSearch=debounce(async (value)=>{
        setSearch2(value)
        dispatch(getHistoryCredit(`/${client_detail.clientId}/0/0/0/1/10/${value.length>0?value:'*'}`))
    },1000)
    // console.log('client_detail.kredit', client_detail.kredit)
    return (
        <div>
            <div className='head-section'>
                <div>
                    <h4 style={{marginBottom:20}}>{renderTitle()}</h4>
                </div>
                <div className='div-flex'>
                    <Button onClick={back} variant='outlined' color='primary' className='btn-rounded btn-remove-capital btn-action' >Kembali</Button>
                    {tab==='add'&&<Button disabled={handleDisableBtn()} onClick={onClickSimpan} variant='contained' color='primary' className='btn-rounded btn-remove-capital btn-action' >Simpan</Button>}
                    {tab==='edit'&&<Button onClick={onClickSimpan} variant='contained' color='primary' className='btn-rounded btn-remove-capital btn-action' >Simpan</Button>}
                    {tab==='detail'&&<Button onClick={onClickSimpan} variant='contained' color='primary' className='btn-rounded btn-remove-capital btn-action' >Edit</Button>}

                    
                </div>
            </div>
            <div className='card-content' style={{padding:20}}>
                <div className='div-flex div-space-between' style={{justifyContent:'space-between'}}>
                    <div className='client-info'>
                        <p className='semi-bold'>Informasi Perusahaan Klien</p>
                        <TextField
                            label={<>Nama Perusahaan</>}
                            variant='outlined'
                            value={client_detail.company}
                            size='small'
                            disabled={handleDisable()}
                            className={classes.textField}
                            onChange={(e)=>dispatch(setDetailClient({company:e.target.value}))}

                        />
                        <AutoCompleteSelect
                            onChange={(event,value)=>dispatch(setDetailClient({industry:value}))}
                            options={list_industry}
                            value={client_detail.industry}
                            getOptionLabel={(option) => option.text}
                            label={<>Industry</>}
                            disabled={handleDisable()}
                        />
                        <TextField
                            label={<>Phone No.</>}
                            // type="number"
                            variant='outlined'
                            value={client_detail.phone}
                            size='small'
                            disabled={handleDisable()}
                            className={classes.textField}
                            onChange={(e)=>dispatch(setDetailClient({phone:e.target.value}))}

                        />
                        <TextField
                            label={<>Alamat</>}
                            multiline
                            variant='outlined'
                            value={client_detail.address}
                            size='small'
                            disabled={handleDisable()}
                            className={classes.textField}
                            onChange={(e)=>dispatch(setDetailClient({address:e.target.value}))}

                        />
                        <div className='div-flex div-space-between' style={{marginBottom:10}}>
                            <p className='semi-bold-without-margin'>Kredit</p>
                            {client_detail.clientId!==0&&<Button disabled={handleDisable()} onClick={addKredit} style={{padding:0}} variant='text' size="small" color='primary' className='btn-remove-capital'>Tambah Kredit</Button>}
                        </div>
                        {client_detail.kredit.map((data,i)=>(
                            <div key={i} className='div-flex div-space-between div-align-center' style={{marginBottom:10}}>
                                <p className='semi-bold-without-margin'>{data.assestment.text}</p>
                                <div style={{width:75,textAlign:'center',border:'1.5px solid #252525',borderRadius:10}} >
                                    {data.balance}
                                </div>
                            </div>
                        ))}
                        {client_detail.kredit.length<1&&
                        <div style={{textAlign:'center'}}>
                            <p style={{margin:0,fontSize:12,color:'#808080'}}>Tambahkan jenis test</p>
                            <div style={{width:'100%',height:1,backgroundColor:'#808080'}}></div>
                        </div>}
                    </div>
                    <div className='admin-info'>
                        <div className='div-flex div-space-between'>
                            <p className='semi-bold-without-margin'>Admin</p>
                            {client_detail.clientId!==0&&<Button disabled={handleDisable()} onClick={onAddAdmin} style={{padding:0}} variant='text' size="small" color='primary' className='btn-remove-capital'>Tambah Admin</Button>}
                        </div>
                        <br/>
                        <Table  size="small" aria-label="a dense table" style={{color:'#777777'}}>
                            <TableHead>
                            <TableRow >
                                <TableCell className='card-content-item-jurnal-text'>Nama</TableCell>
                                <TableCell align='left' className='card-content-item-jurnal-text'>Email</TableCell>
                                <TableCell align='center' className='card-content-item-jurnal-text'>Aksi</TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                                {client_detail.admin.length>0?client_detail.admin.map((data,i)=>(
                                    <TableRow >
                                        <TableCell align='left'  className='card-content-item-jurnal-text-without-weight' >{data.name}</TableCell>
                                        <TableCell  align='left'  className='card-content-item-jurnal-text-without-weight' >{data.email}</TableCell>
                                        <TableCell align='center' className='card-content-item-jurnal-text-without-weight'>
                                            <img src={Edit} onClick={()=>handleDisable()?null:onEditAdmin(data,i)} className='icon-action'/>
                                            <img src={Close} onClick={()=>handleDisable()?null:onDeleteAdmin(data,i)} className='icon-action'/>
                                        </TableCell>
                                    </TableRow>
                                ))
                                :
                                <TableRow >
                                    <TableCell  style={{textAlign:'center'}} colSpan={4} >Belum terdapat admin</TableCell>
                                </TableRow>
                                }
                        
                            </TableBody>
                        </Table>
                        <div className='card-table__pagination'>
                            {/* {proyek_detail.peserta.peserta_pagination!==null&&
                            <TablePagination
                                    className="card-pagination"
                                    type="reduced"
                                    page={proyek_detail.peserta.peserta_pagination.page}
                                    pageLength={proyek_detail.peserta.peserta_pagination.perPage}
                                    totalRecords={proyek_detail.peserta.peserta_pagination.total}
                                    totalRecords={proyek_detail.peserta.peserta_pagination.total}
                                    onPageChange={({ page, pageLength }) => {
                                        togglePagination(page,pageLength)
                                    }}
                                    prevPageRenderer={() => <img src={CevronLeft} style={{width:10}}/>}
                                    nextPageRenderer={() => <img src={CevronRight}/>}
                                />
                            } */}
                        </div>
                    </div>
                </div>
                <br/>
                <div>
                    <p className='semi-bold'>Riwayat Kredit</p>
                    <div >
                        <div className='card-table__head'>
                            <TextField
                                placeholder="Cari"
                                variant='outlined'
                                size='small'
                                inputProps={{
                                    className:classes.input
                                }}
                                InputLabelProps={{shrink: false}}
                                className={classes.search}
                                onChange={(e)=>historyCreditSearch(e.target.value)}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                        <img alt="search" src={SearchImg} style={{width:15}}/>
                                    </InputAdornment>
                                    ),
                                }}
                            />
                            <Button onClick={filterData} color="primary" className='btn-remove-capital' variant="text">Filter</Button>
                        </div>
                        <div className='card-table__content'>
                        <Table  size="small" aria-label="a dense table">
                            <TableHead>
                            <TableRow>
                                <TableCell align="left" >No.</TableCell>
                                <TableCell align="left" >Aktifitas</TableCell>
                                <TableCell align="left">Jenis test</TableCell>
                                <TableCell align="left">Tanggal</TableCell>
                                <TableCell align="left" >Keterangan</TableCell>
                                <TableCell align="center" >Jumlah</TableCell>
                                <TableCell align="center">Sisa Kredit</TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                                {general.isLoadingTable?
                                    <Skeleton count={7}/>
                                :
                                history_credit.length<1?
                                <TableRow>
                                    <TableCell colSpan={7} style={{textAlign:'center'}}>Belum terdapat aktifitas kredit</TableCell>
                                </TableRow>
                                :history_credit.map((data,i)=>(
                                    <TableRow key={i}>
                                    <TableCell >{i+1}</TableCell>
                                    <TableCell >{data.activity}</TableCell>
                                    <TableCell>{data.assessment}</TableCell>
                                    <TableCell>{moment(data.lastUpdated).format('DD MMM YYYY')}</TableCell>
                                    <TableCell>{data.description}</TableCell>
                                    <TableCell align="center" style={{color:data.amount>=0?'#a8e090':'#ff6e79',fontWeight:'bold'}}>{data.amount>=0&&'+'}{data.amount}</TableCell>
                                    <TableCell align="center" >{data.balance}</TableCell>
                                </TableRow>
                                ))
                                
                                }
                            </TableBody>
                        </Table>
                        <div className='card-table__pagination'>
                        {history_credit_pagination!==null&&
                        <TablePagination
                                className="card-pagination"
                                type="reduced"
                                page={history_credit_pagination.page}
                                pageLength={history_credit_pagination.perPage}
                                totalRecords={history_credit_pagination.total}
                                totalRecords={history_credit_pagination.total}
                                onPageChange={({ page, pageLength }) => {
                                    historyCreditTogglePagination(page,pageLength)
                                }}
                                prevPageRenderer={() => <img src={CevronLeft} style={{width:10}}/>}
                                nextPageRenderer={() => <img src={CevronRight}/>}
                            />
                        }
                    </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    )
}
