import React from 'react'
import Chevron from 'assets/icon/chevron_white.svg'

import {Collapse} from '@material-ui/core'
import {connect} from 'react-redux'
import {logout} from 'redux/actions/auth'
import {modalToggle} from 'redux/actions/general'
import Cookie from 'universal-cookie'
import ava_default from 'assets/icon/avadefault.svg'
import Dashboard1 from 'assets/icon/Dashboard1.svg'
import Dashboard2 from 'assets/icon/Dashboard2.svg'
import { useDispatch } from 'react-redux'
import {withRouter} from 'react-router-dom'

function Sidebar(props) {
    const dispatch = useDispatch()
    const adminToggleCollapse=()=>{
        props.setadmin_collapse(!props.admin_collapse)
    }
    const toggleActive=(key)=>{
        let url=props.location.pathname;
        let match=url.includes(key)
        return match
    }
    const changePassword=()=>{
        dispatch(modalToggle({
            modal_open: true,
            modal_title: `Reset Password`,
            modal_component: "reset_password",
            modal_size:350,
            modal_data:null,
            modal_action:'reset_password'
        }))
    }
    return (
        <div className={`sidebar-wrapper-oes ${props.expand&&'expand-sidebar'}`}>
            <div className='is-collapse admin-list' onClick={()=>adminToggleCollapse()}>
                <div className='' style={{display:'flex',alignItems:'center'}}>
                    <img src={ava_default} style={{width:30}}/>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <a>Admin</a>
                </div>
                <div>
                    <img src={Chevron} className={props.admin_collapse?'chevron':' active-chevron'}/>
                </div>
            </div>
            <Collapse in={props.admin_collapse}>
                <div className='content-collapsee' onClick={()=>changePassword()}>
                    <div className='list-collapse'>
                        <a>Ganti Password</a>
                    </div>
                </div>
                <div className='content-collapsee' onClick={()=>dispatch(logout('?act=oas'))}>
                    <div className='list-collapse'>
                        <a>Logout</a>
                    </div>
                </div>
                
            </Collapse>
            <div className='hr'/>
            <div onClick={()=>window.location.assign('/beranda')} className={`list-sidebar ${toggleActive('/beranda')?'is-active-menu':''}`}>
                <img src={toggleActive('/beranda')?Dashboard2:Dashboard1} className='icon-size'/>
                <a >Beranda</a>
            </div>
            
            
        </div>
    )
}
export default withRouter(Sidebar)