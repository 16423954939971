import React from 'react'
import Check from 'assets/icon/Check.svg'
import Correct from 'assets/image/Correct.png'
export default function PapiIntruksi(props) {
    let {papi}=props.modal_data
    console.log('papi', papi)
    return (
        <div>
             <div className='intruksi-modal'>
            <div className='welcome-mobile'>
                <h3>Bagaimana menjalani proses Work Preference Test?</h3>
                <div dangerouslySetInnerHTML={{__html:papi.desc}}></div>
                <div className=''>
                    <div className='div-flex div-space-between'>
                        <p>Contoh:</p>
                    </div>
                
                    <div className='papi-example-wrapper'>
                        <div className='div-flex' style={{gap:2,marginBottom:5}}>
                            <div className='papi-example-checkbox checked-example'>
                                <img src={Check}/>
                            </div>
                            <div className='papi-example-question'>
                                <p>Saya seorang pekerja “keras” </p>
                            </div>
                        </div>
                        <div className='div-flex' style={{gap:2,marginBottom:5}}>
                            <div className='papi-example-checkbox'>
                                <img src={Check}/>
                            </div>
                            <div className='papi-example-question'>
                                <p>Saya seorang pekerja “keras” </p>
                            </div>
                        </div>
                        <div style={{textAlign:'center',marginTop:10}}>
                            <img src={Correct} style={{width:50}}/>
                        </div>
                    </div>
                    <p>Bekerjalah dengan cepat, tetapi jangan sampai ada nomor pernyataan yang terlewatkan.</p>
                </div>
            </div>
            </div>
        </div>
    )
}
