import React, { Component } from 'react'
import './style.css'
// import Logo from 'assets/image/logo_topbar.svg'
import {connect} from 'react-redux'
import {modalToggle} from 'redux/actions/general'
import Logo from 'assets/image/ACS.png'
class index extends Component {
    profileClick=()=>{
        this.props.modalToggle({ 
            modal_open: true,
            modal_title: ``,
            modal_component: "",
            modal_size:300,
            modal_type:'profile',
            modal_data:null,
            modal_action:'profile'
        })
    }
    render() {

        return (
            <nav>
               {/* <div className='hamburger' onClick={this.props.sidebarToggle}>
                   <div className='hamburger__list'/>
                   <div className='hamburger__list'/>
                   <div className='hamburger__list'/>
               </div> */}
               <div className='logo' onClick={()=>window.location.assign('/')}>
                    <img src={Logo} style={{width:150}}/>
                    {/* <div className='vertical-line'></div> */}
                    {/* <h4>Knowledge Center</h4> */}
               </div>
               
              
           </nav>
        )
    }
}
export default connect(null,{modalToggle})(index)