import React from 'react'
import SurveyContainer from 'components/oascomponent/SurveyContainer'
import { RadioGroup,FormControlLabel,Radio } from '@material-ui/core'
import { useDispatch,useSelector } from 'react-redux'
import { modalToggle } from 'redux/actions/general'
import { setOas,submitSurvey } from 'redux/actions/oas'
export default function Survey(props) {
    const dispatch = useDispatch()
    const oas = useSelector(state => state.oas)

    let {jawaban,detail_survey}=oas
    const showHelp=()=>{
        dispatch(modalToggle({
            modal_open: true,
            modal_title: `Cara pengisian`,
            modal_component: "oas_tutorial_dtra",
            modal_size:500,
            modal_type:'',
            modal_data:null,
            modal_action:'oas_tutorial_dtra'
        }))
    }
    const chooseAnswer=(id)=>{
        jawaban[props.active_tab].option=id
        dispatch(setOas({jawaban:jawaban}))
    }
    const activeAnswer=(idOption)=>{
        if(jawaban[props.active_tab].option===idOption){
            return true
        }else{
            return false
        }
    }
    const handleDisabled=()=>{
        if(jawaban[props.active_tab].option===null){
            return true
        }else{
            return false
        }
    }
    const onClickNext=async ()=>{
        if(props.active_tab===detail_survey.pages.length-1){
            let new_jawaban=[]
            jawaban.map((d,i)=>{
                if(i>2){
                    new_jawaban.push({id:0,itemId:d.itemId,ratingId:parseInt(d.option),answerText:""})
                }
                if(i===2){
                    let new_demo=[]
                    d.map((dm)=>{
                        new_demo.push({...dm,ratingId:parseInt(dm.ratingId)})
                    })
                    new_jawaban.push(...new_demo)
                }
            })
            let data={
                surveyId:detail_survey.surveyId,
                uuid:detail_survey.groupUuid,
                responses:new_jawaban
            }
            let res=await dispatch(submitSurvey(data))
            if(res){
                props.statusToggle('result')
            }
            console.log(`data`, data)
            // alert('hello')
        }else{
            props.tabToggle(props.active_tab+1)

        }
    }
    // console.log(`props.content`, props.totalQuestion,props.active_tab)
    return (
        <SurveyContainer showHelp={showHelp} withHelp={true} handleDisabled={handleDisabled} totalQuestion={props.totalQuestion} current_question={props.active_tab-2} nameSurvey={props.nameSurvey} renderTextQuestionNumber={props.renderTextQuestionNumber} ln="id" btnText="Selanjutnya" clickNext={()=>onClickNext()} clickBack={()=>props.tabToggle(props.active_tab-1)} welcomeMessage="Anda berikut merupakan halaman survey untuk Strategy and Performance Execution Excellence® Audit">
            <p>{props.content.items[0].title.id}</p>
            <div className="div-flex div-space-between div-align-center hide-mobile">
                <div className="box">
                    <p>{props.content.items[0].text.en}</p>
                </div>
                <RadioGroup onChange={(e)=>chooseAnswer(e.target.value)} value={parseInt(jawaban[props.active_tab].option)} row aria-label="position" name="position" defaultValue="1">
                        {props.content.items[0].options.map((d,i)=>(
                            <FormControlLabel
                                value={d.id}
                                control={<Radio color="primary" />}
                                label={d.text.id}
                                labelPlacement="top"
                                key={i}
                                style={{margin:3,height:60,fontWeight:'bold'}}
                            />
                        ))}
                </RadioGroup>
                <div className="box">
                    <p>{props.content.items[0].text.id}</p>
                </div>
            </div>
            <div className="show-mobile">
                <div className="box">
                    <div style={{padding:20}}>
                    <p>{props.content.items[0].text.en}</p>

                    </div>
                </div>
                <br/>
                {props.content.items[0].options.map((d,i)=>(
                    <div key={i} onClick={()=>chooseAnswer(d.id)} className={`spex2-option-item ${activeAnswer(d.id)&&'selected'}`}>
                        <div style={{padding:10,textAlign:'center'}}>
                            <p>{d.text.id}</p>
                        </div>
                    </div>
                ))}
                <br/>
                <div className="box">
                    <div style={{padding:20}}>
                        <p>{props.content.items[0].text.id}</p>
                    </div>
                </div>
            </div>
            <br/>
        </SurveyContainer>
    )
}
