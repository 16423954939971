import * as actionTypes from '../constants/master'

const getTabByUrl=(url)=>{
    // let path=window.location.pathname
    // let match=path.includes(url)
    // if(match){
    //     url='/assestment'
    // }
    switch (url) {
        case '/assestment':
            return 'welcome'
            break;
        case '/dashboard':
            return 'beranda'
        case '/proyek':
            return 'list_proyek'
        case '/peserta':
            return 'list_peserta'
        default:
            break;
    }
}
const initialState={
    rm:[],
    rm_full:[],
    rm_text:[],
    segments:[],
    tribes:[],
    branches:[],
    stages:[],
    proposalTypes:[],
    client:[],
    contact:[],
    employee:[],
    proposalTypes:[],
    detail_client:null,
    tab_active:getTabByUrl(window.location.pathname),
    tab_back:getTabByUrl(window.location.pathname),

    bread_crum:[],
    platform:[],
    toast_msg:'',

    search:'',
    pagination:{
        page:1,
        perPage:10,
        total:0
    }
}
export default (state=initialState,action)=>{
    switch (action.type) {
        
        case actionTypes.GET_CLIENT_SEARCH:
            return{
                ...state,
                client:action.payload,
               
            }
        case actionTypes.SET_TAB:
            return{
                ...state,
                tab_active:action.payload.tab_active,
                tab_back:action.payload.tab_back,
            }
        case actionTypes.SET_TOAST_MSG:
            return{
                ...state,
                toast_msg:action.payload
            }
        case actionTypes.SET_SEARCH:
            return{
                ...state,
                search:action.payload
            }
        case actionTypes.SET_PAGINATION:
            return{
                ...state,
                pagination:action.payload
            }
        default:
            return state;
    }
}