import React,{useState} from 'react'
import TextField from 'components/TextField'
import { Button } from '@material-ui/core'
import { postGroup } from 'redux/actions/oas'
import { useDispatch,useSelector } from 'react-redux'
export default function Oas_add_group(props) {
    const dispatch = useDispatch()
    const oas = useSelector(state => state.oas)
    const [name, setname] = useState('')
    const onClickSave=async ()=>{
        let data={
            id:0,
            activityId:oas.assestment_detail.id,
            name,
            canDownloadReport:true,
            userId:props.profile.id
        }
        let res=await dispatch(postGroup(data))
        if(res){
            props.modal_data.afterPost()
        }
    }
    return (
        <div>
            {/* <p>Badan Pertanahan Nasional (BPN)  Memiliki sisa kredit untuk Digital Transformation Readiness Assessment 1200</p> */}
            <TextField
                label="Group Name"
                variant='outlined'
                value={name}
                size='small'
                style={{marginBottom:20}}
                onChange={(e)=>setname(e.target.value)}
            />
            <Button onClick={onClickSave} style={{width:'100%',height:35}} className='btn-remove-capital btn-rounded' variant='contained'  color="primary">Create Group</Button>
        <br/>
        </div>
    )
}
