import React from 'react'
import {withRouter,useLocation } from 'react-router-dom'
import {Helmet} from "react-helmet";

function Index(props) {
    function useQuery() {
        return new URLSearchParams(useLocation().search);
      }
    const query = useQuery();
    React.useEffect(() => {
        let act=query.get('act')
        console.log(act)
        if(act==='oas'){
            window.location.assign('/oas/login')
            
        }else{
            window.location.assign('/login')
        }
    }, [])
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Assestment ONE GML</title>
                <meta
                    name="description"
                    content="Powered by ONE GML"
                /> 
                <link rel="canonical" href="https://assess.onegml.com/" />
            </Helmet>
        </div>
    )
}

export default withRouter(Index)
