import React,{useState} from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {Button,TextField,FormControl,InputLabel,OutlinedInput,Select,MenuItem,
    InputAdornment,Table,TableHead,TableCell,TableRow,TableBody} from '@material-ui/core'
import {
    DatePicker,
    MuiPickersUtilsProvider,
    } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import CevronRight from 'assets/icon/chevron-right.svg'
import CevronLeft from 'assets/icon/chevron-left.svg'
import SearchImg from 'assets/icon/Search.png'
import {useSelector,useDispatch} from 'react-redux'
import { TablePagination } from '@trendmicro/react-paginations';
import '@trendmicro/react-paginations/dist/react-paginations.css';
import {debounce} from 'lodash'
import Eye from 'assets/icon/eye.svg'
import status_ilustrasi from 'assets/image/status_ilustrasi.png'
import Skeleton from 'components/Skeleton'
import {handleFile} from 'components/handleFile'
import {setPeserta,putPeserta,getLog,uploadPeserta,} from 'redux/actions/peserta'
import {getReport} from 'redux/actions/proyek'
import InputMask from 'react-input-mask'
import moment from 'moment'
import {modalToggle} from 'redux/actions/general'
import { toast } from 'react-toastify';

const useStyles = makeStyles(theme => ({
    textField: {
        [`& fieldset`]: {
            borderRadius: 8,
            },
            width:'100%',
            marginBottom:15
    },
    search: {
        [`& fieldset`]: {
            borderRadius: 8,
            height:30,
            margin: theme.spacing(1),
          },
          width:200,
          height:10,
          marginBottom:40
  },
  input:{
    fontSize:14,
    margin:3      
  }
}));
export default function Form_peserta(props) {
    const classes=useStyles()
    const dispatch=useDispatch()
    const peserta = useSelector(state => state.peserta)
    const general = useSelector(state => state.general)
    const [file_error,setFileError]=useState('')
    const [search1,setSearch1]=useState('')
    const {detail_log_list,detail_log_pagination,detail_peserta}=peserta

    const togglePagination=async (page,pageLength)=>{
        await dispatch(getLog(`/${detail_peserta.userId}/${page}/${pageLength}/${search1!==''?search1:'*'}`))
    }
    const refreshData=async ()=>{
        let res=await dispatch(getLog(`/${detail_peserta.userId}/${detail_log_pagination.page}/${detail_log_pagination.perPage}/${search1!==''?search1:'*'}`))
        if(res){
            toast("Data berhasil diperbarui")
        }
        
    }
    const searchToggle=debounce(async (value)=>{
        setSearch1(value)
        if(value!==search1&&value.length>0){
            await dispatch(getLog(`/${detail_peserta.userId}/1/10/${value}`))
            
        }else{
            await dispatch(getLog(`/${detail_peserta.userId}/1/10/${search1!==''?search1:'*'}`))
        }
        
    },1000)
    const back=()=>{
        props.tabsToggle('form_proyek','form_peserta')
        props.setTabAction('detail_proyek')

    }
    const handleFiles=async (evt)=>{
        let handle=await handleFile(evt)
        if(handle.file_error===null){
            let fd=new FormData()
            fd.append('userId',detail_peserta.userId)
            fd.append('files',handle.file)
            let res=await dispatch(uploadPeserta(fd))
            if(res){
                dispatch(setPeserta({file:handle.file}))
                dispatch(setPeserta({file_name:handle.file_name}))
                dispatch(setPeserta({file_url:URL.createObjectURL(handle.file)}))
                setFileError('')
            }
            
            
        }else{
            setFileError(handle.file_error)
        }
    }
    const handleDisable=()=>{
        if(props.tab_action==='detail_peserta'){
            return true
        }else{
            return false
        }
    }
    const onSave=()=>{
        // console.log('detail_peserta', detail_peserta)
        let data={
            userId:detail_peserta.userId,
            name:detail_peserta.nama,
            email:detail_peserta.email,
            phone:detail_peserta.telepon,
            idNumber:detail_peserta.no_ktp,
            birthDate:moment(detail_peserta.tgl_lahir).format('DD-MMM-YYYY'),
            city:detail_peserta.kota_asal,
            gender:detail_peserta.jk,
            education:detail_peserta.institusi,
            eduLevel:detail_peserta.jenjang
        }
        if(props.tab_action==='edit_peserta'){
            dispatch(putPeserta(data))
        }
        if(props.tab_action==='detail_peserta'){
            props.setTabAction('edit_peserta')
        }
    }
    const onDelete=()=>{
        dispatch(modalToggle({ 
            modal_open: true,
            modal_title: `detail`,
            modal_component: "confirm_delete",
            modal_size:400,
            modal_type:'confirm',
            modal_data:{
                title:'Peserta',
                id:detail_peserta.userId,
                msg:`<p>Apakah anda yakin menghapus ${detail_peserta.nama}</p>`
            },
            modal_action:'delete_peserta'
        }))
    }
    const onReport=(id)=>{
        let data={
            projectId:0,
            userIds:[detail_peserta.userId]

        }
        dispatch(getReport(data))
    }
    return (
        <div>
            <div className='head-section'>
                <div>
                    <h4 style={{marginBottom:0,marginTop:0}}>Detail Peserta</h4>
                </div>
                <div>
                    <Button onClick={back}  className='btn-remove-capital btn-rounded btn-action' variant="outlined" color="primary">Kembali</Button>

                    <Button onClick={onDelete}   className='btn-remove-capital btn-rounded btn-action' variant="contained" color="secondary">Hapus</Button>
                    <Button  onClick={onSave} variant='contained' color='primary' className='btn-rounded btn-remove-capital btn-action' >
                        {props.tab_action==='add_peserta'&&'Simpan'}
                        {props.tab_action==='edit_peserta'&&'Simpan'}
                        {props.tab_action==='detail_peserta'&&'Edit'}
                    </Button>
                </div>
            </div>
            <div className='card-white'>
                <p className='semi-bold'>Data diri</p>
                <div className='div-flex div-space-between' >
                    <div style={{width:'45%'}}>
                        <TextField
                            label={<>Nama</>}
                            variant='outlined'
                            value={detail_peserta.nama}
                            size='small'
                            disabled={handleDisable()}
                            className={classes.textField}
                            onChange={(e)=>dispatch(setPeserta({nama:e.target.value}))}

                        />
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <DatePicker 
                                format={'DD MMM YYYY'} 
                                color='primary' 
                                disabled={handleDisable()}
                                value={detail_peserta.tgl_lahir} 
                                onChange={(data)=>dispatch(setPeserta({tgl_lahir:data}))}  
                                className={classes.textField}  label='Tanggal lahir' clearable={true} size='small' inputVariant='outlined'  />
                        </MuiPickersUtilsProvider>
                        <FormControl disabled={handleDisable()} variant="outlined" size="small" className='add-proposal__field' >
                            <InputLabel  htmlFor="category">Jenis kelamin</InputLabel>
                            <Select value={detail_peserta.jk} onChange={(e)=>dispatch(setPeserta({jk:e.target.value}))}  color='primary' name='segmentId'   labelId="label" id="select"  labelWidth={100} className='field-radius'>
                                <MenuItem value="Laki-laki">Laki-laki</MenuItem>
                                <MenuItem value="Perempuan">Perempuan</MenuItem>
                            </Select>
                        </FormControl>
                        <TextField
                            label={<>Kota asal</>}
                            variant='outlined'
                            value={detail_peserta.kota_asal}
                            size='small'
                            disabled={handleDisable()}
                            className={classes.textField}
                            onChange={(e)=>dispatch(setPeserta({kota_asal:e.target.value}))}

                        />
                        <TextField
                            label={<>No.KTP</>}
                            variant='outlined'
                            value={detail_peserta.no_ktp}
                            size='small'
                            disabled={handleDisable()}
                            className={classes.textField}
                            onChange={(e)=>dispatch(setPeserta({no_ktp:e.target.value}))}

                        />
                         <MuiPickersUtilsProvider utils={MomentUtils}>
                            <DatePicker 
                                format={'DD MMM YYYY'} 
                                color='primary' 
                                disabled={true}

                                value={detail_peserta.tgl_terdaftar} 
                                onChange={(data)=>dispatch(setPeserta({tgl_terdaftar:data}))}
                                className={classes.textField}  label='Tanggal terdaftar' clearable={true} size='small' inputVariant='outlined'  />
                        </MuiPickersUtilsProvider>
                    </div>
                    <div style={{width:'45%'}}>
                        <div className='foto-section'>
                            <div style={{position:'relative',width:'25%'}}>
                            {detail_peserta.file_url!==''?
                                <img src={detail_peserta.file_url} className='ava-peserta'/>
                            :
                                <div className='empty-circle'></div>
                            }
                            </div>
                            <div style={{width:'70%'}}>
                                <p className='semi-bold' style={{margin:0}}>Foto</p>
                                <div className='div-flex div-space-between div-align-center'>
                                    {detail_peserta.file_url!==''?
                                        <p style={{fontSize:12,color:'#777777',margin:0,fontWeight:'bold',width:160,wordBreak:'break-word'}}>{detail_peserta.file_name}</p>
                                    :
                                        <p style={{fontSize:12,color:'#777777',margin:0,fontWeight:'bold'}}>Foto belum tersedia</p>
                                    }
                                    <Button disabled={handleDisable()} component="label" size='small' className='btn-remove-capital' variant='text' color='primary'>
                                        Upload file
                                        <input
                                            type="file"
                                            style={{ display: "none" }}
                                            onChange={(e)=>handleFiles(e)}
                                        />
                                    </Button>
                                </div>
                            </div>
                        </div>
                        <br/>
                        <p className='semi-bold'>Informasi pendidikan</p>
                        <TextField
                            label={<>Institusi pendidikan terakhir</>}
                            variant='outlined'
                            value={detail_peserta.institusi}
                            size='small'
                            disabled={handleDisable()}
                            className={classes.textField}
                            onChange={(e)=>dispatch(setPeserta({institusi:e.target.value}))}

                        />
                        <TextField
                            label={<>Jenjang pendidikan terakhir</>}
                            variant='outlined'
                            value={detail_peserta.jenjang}
                            size='small'
                            disabled={handleDisable()}
                            className={classes.textField}
                            onChange={(e)=>dispatch(setPeserta({jenjang:e.target.value}))}

                        />
                        <p className='semi-bold'>Kontak</p>
                        <TextField
                            label={<>Email</>}
                            variant='outlined'
                            value={detail_peserta.email}
                            size='small'
                            disabled={handleDisable()}
                            className={classes.textField}
                            onChange={(e)=>dispatch(setPeserta({email:e.target.value}))}

                        />
                        <InputMask
                            maskChar={null}
                            mask="9999-9999-9999"
                            onChange={(e)=>dispatch(setPeserta({telepon:e.target.value}))}
                            value={detail_peserta.telepon}
                            disabled={handleDisable()}
                            // disabled={handleDisable()}
                        >
                        {() =>
                            <TextField
                                label={<>Telepon</>}
                                variant='outlined'
                                disabled={handleDisable()}
                                // value={detail_peserta.telepon}
                                size='small'
                                className={classes.textField}
                                // onChange={(e)=>dispatch(setPeserta({telepon:e.target.value}))}

                            />
                        }
                        </InputMask>
                    </div>
                </div>
                
            </div>
            
                <div className='head-section'>
                    <div className='div-flex'>
                        <h4 style={{marginBottom:0,marginTop:0}}>
                            Detail Log pada proyek  
                        </h4>
                    </div>
                    <div>
                    <Button onClick={onReport} color='primary' className='btn-rounded btn-remove-capital btn-action' variant="contained">Download Hasil</Button>
                    </div>
                </div>
                <div className='card-content'>
                <div className='card-table'>
                    <div className='card-table__head'>
                    <TextField
                            placeholder="Cari"
                            variant='outlined'
                            size='small'
                            inputProps={{
                                className:classes.input
                            }}
                            InputLabelProps={{shrink: false}}
                            className={classes.search}
                            onChange={(e)=>searchToggle(e.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                    <img alt="search" src={SearchImg} style={{width:15}}/>
                                </InputAdornment>
                                ),
                              }}
                        />
                        <Button onClick={refreshData} color="primary" className='btn-remove-capital' variant="text">Perbarui data</Button>
                    </div>
                    <div className='card-table__content'>
                    <Table  size="small" aria-label="a dense table">
                        <TableHead>
                        <TableRow>
                            <TableCell align="left" >Tanggal</TableCell>
                            <TableCell align="left" >Waktu</TableCell>
                            <TableCell align="left" >Browser</TableCell>
                            <TableCell align="left">IP Address</TableCell>
                            <TableCell align="left">Aktifitas</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                            {general.isLoadingTable?
                                <Skeleton count={5}/>
                            :
                            detail_log_list.map((data,i)=>(
                                <TableRow key={i}>
                                    <TableCell >{moment(data.createdDate).format('DD MMM YYYY')}</TableCell>
                                    <TableCell >{moment(data.createdDate).format('HH:mm')}</TableCell>
                                    <TableCell >{data.browser}</TableCell>
                                    <TableCell >{data.ipAddress}</TableCell>
                                    <TableCell >{data.action}</TableCell>
                                </TableRow>
                            ))
                            
                            }
                        </TableBody>
                    </Table>
                    <div className='card-table__pagination'>
                    {detail_log_pagination!==null&&
                    <TablePagination
                            className="card-pagination"
                            type="reduced"
                            page={detail_log_pagination.page}
                            pageLength={detail_log_pagination.perPage}
                            totalRecords={detail_log_pagination.total}
                            totalRecords={detail_log_pagination.total}
                            onPageChange={({ page, pageLength }) => {
                                togglePagination(page,pageLength)
                            }}
                            prevPageRenderer={() => <img src={CevronLeft} style={{width:10}}/>}
                            nextPageRenderer={() => <img src={CevronRight}/>}
                        />
                    }
                </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
