import React from 'react'
import './style.css'
import disc_login from 'assets/image/disc_login.png'
export default function Admin_in_mobile() {
    return (
        <div className='in-mobile-wrapper'>
            <img src={disc_login} style={{width:'100%'}}/>
            <br/>
            <p className='semi-bold'>Anda login sebagai admin saat ini system admin tidak dapat diakses melalui mobile web</p>
        </div>
    )
}
