import * as actionTypes from "../constants/proyek";
import moment from 'moment'
const initialState={
    proyek_list:[],
    proyek_pagination:{
        page:0,
        perPage:10,
        total:0
    },
    count_progress:0,
    beranda:{
        digunakan:0,
        menyelesaikan:0,
        sisa:0,
        totalProject:0
    },
    beranda_sa:{
        client:0,
        peserta:0,
        project:0
    },
    list_test:[],
    proyek_detail:{
        projectId:0,
        nama:'',
        deskripsi:'',
        tgl_pengerjaan:null,
        periode:null,
        status:'',
        jenis_test:[],
        progress:{
            selesai:'-',
            proses:'-',
            menunggu:'-'
        },
        peserta:{
            peserta_list:[],
            peserta_pagination:{
                page:0,
                perPage:10,
                total:0
            }
        }
    },
    email_template:{
        subject:'',
        email:''
    },
    email_bulk:{
        subject:'',
        cc:[],
        email:'',
        userIds:[],
        to:'[%EMAIL%]'
    },
    email_bulk_individual:{
        subject:'',
        email:'',
        cc:[],
        to:''
    },

    filter_sa_project:{
        textPeriode:'All periode',
        rangeValue:{from: {year: parseInt(moment().format('YYYY')), month:parseInt(moment().format('M')) }, to: {year: parseInt(moment().format('YYYY')), month: parseInt(moment().format('M'))}}
    },
    use_filter:false,

    list_credit:[],
    history_credit:[],
    history_credit_pagination:{
        page:0,
        perPage:10,
        total:0
    },
    filter_history_credit:{
        assestmentId:[],
        textPeriode:'All periode',
        rangeValue:{from: {year: parseInt(moment().format('YYYY')), month:parseInt(moment().format('M')) }, to: {year: parseInt(moment().format('YYYY')), month: parseInt(moment().format('M'))}}
    },
}
export default (state=initialState,action)=>{
    switch (action.type) {
        case actionTypes.GET_LIST_CREDIT:
            return{
                ...state,
                list_credit:action.payload
            }
        case actionTypes.RESET_HISTORY_CREDIT:
            return{
                ...state,
                history_credit:[],
                history_credit_pagination:{
                    page:0,
                    perPage:10,
                    total:0
                },
                filter_history_credit:{
                    assestmentId:[],
                    textPeriode:'All periode',
                    rangeValue:{from: {year: parseInt(moment().format('YYYY')), month:parseInt(moment().format('M')) }, to: {year: parseInt(moment().format('YYYY')), month: parseInt(moment().format('M'))}}
                },
            }
        case actionTypes.GET_HISTORY_CREDIT:
            return{
                ...state,
                history_credit:action.payload.history_credit,
                history_credit_pagination:action.payload.history_credit_pagination
            }
        case actionTypes.SET_FILTER_HISTORY_CREDIT:
            return{
                ...state,
                filter_history_credit:{...state.filter_history_credit,[Object.keys(action.payload)]:Object.values(action.payload)[0]}
            }
        case actionTypes.SET_FILTER_SA_PROJECT:
            return{
                ...state,
                filter_sa_project:{...state.filter_sa_project,[Object.keys(action.payload)]:Object.values(action.payload)[0]}
            }
        case actionTypes.SET_USE_FILTER:
            return{
                ...state,
                use_filter:action.payload
            }
        case actionTypes.GET_LIST_TEST:
            return {
                ...state,
                list_test:action.payload
            }
        case actionTypes.GET_PROYEK:
            return{
                ...state,
                proyek_list:action.payload.proyek,
                proyek_pagination:action.payload.proyek_pagination,
                
            }
        case actionTypes.SET_PROYEK:
            return{
                ...state,
                proyek_detail:{...state.proyek_detail,[Object.keys(action.payload)]:Object.values(action.payload)[0]}
            }
        case actionTypes.SET_EMAIL_TEMPLATE:
            return{
                ...state,
                email_template:{...state.email_template,[Object.keys(action.payload)]:Object.values(action.payload)[0]}
            }
        case actionTypes.SET_EMAIL_BULK:
            return{
                ...state,
                email_bulk:{...state.email_bulk,[Object.keys(action.payload)]:Object.values(action.payload)[0]}
            }
        case actionTypes.SET_EMAIL_BULK_INDIVIDUAL:
            return{
                ...state,
                email_bulk_individual:{...state.email_bulk_individual,[Object.keys(action.payload)]:Object.values(action.payload)[0]}
            }
        case actionTypes.SET_COUNT:
            return{
                ...state,
                count_progress:action.payload
            }
        case actionTypes.SET_BERANDA:
            return{
                ...state,
                beranda:action.payload
            }
        case actionTypes.SET_BERANDA_SA:
            return{
                ...state,
                beranda_sa:action.payload
            }
        case actionTypes.RESET_PROYEK:
            return{
                ...state,
                proyek_detail:{
                    projectId:0,
                    nama:'',
                    deskripsi:'',
                    tgl_pengerjaan:null,
                    periode:null,
                    status:'',
                    jenis_test:[],
                    progress:{
                        selesai:'-',
                        proses:'-',
                        menunggu:'-'
                    },
                    peserta:{
                        peserta_list:[],
                        peserta_pagination:{
                            page:0,
                            perPage:10,
                            total:0
                        }
                    }
                }
            }
        default:
            return state;
    }
}

