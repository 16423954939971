import React from 'react'
import ResultContainer from 'components/oascomponent/ResultContainer'
import Baik from 'assets/image/oas/Baik.jpg'
export default function Survey(props) {
    return (
        <ResultContainer ln="id" btnText="Report" clickAgree={()=>{window.open(`/oas/dtra/report/${props.result_survey?.uuid}`,'_blank')}} welcomeMessage="Anda berikut merupakan halaman survey untuk Strategy and Performance Execution Excellence® Audit">
              <div style={{textAlign:'center'}}>
                <h2 style={{margin:0,marginBottom:20,color:'#333333'}}>{props.result_survey?.text}</h2>
                <img src={Baik} style={{width:200}}/>

              </div>
              <p>Terimasih sudah berpartisipasi dalam survey HR Diagnostics. Kami akan menghubungi anda sesegera mungkin untuk menjabarkan informasi lebih rinci mengenai organisasi anda.</p>
        </ResultContainer>
    )
}
